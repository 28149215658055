// React Import
import React, { Fragment, useEffect, useState } from 'react'
import { FiCheck } from 'react-icons/fi'

// Third Party Imports
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap'

const blogs = [
    {
        id: 1,
        title: "The Mango Marvel: A Delectable Journey Through India's Mango Varieties",
        introduction: {
            points: [
                "The mention of mangoes brings a smile to the faces of millions of Indians and conjures images of sunny orchards, lazy summer afternoons, and the rich, sweet aroma of the 'King of Fruits.'",
                "In this blog post, we embark on a delectable journey through the diverse and flavorful world of Indian mangoes. From the iconic Alphonso to the lesser-known treasures, we'll explore the unique characteristics and cultural significance of these golden delights."
            ]
        },
        sections: [
            {
                title: "The Allure of Alphonso",
                content: [
                    "The Alphonso mango, often referred to as 'Hapus' in Maharashtra, is arguably the most famous mango variety in India. It's known for its vibrant saffron-colored flesh, unparalleled sweetness, and creamy texture.",
                    "Alphonso mangoes are cultivated primarily in the Konkan region of Maharashtra, where the unique combination of climate, soil, and traditional farming practices contributes to their superior taste.",
                    "The Alphonso is the crown jewel of Indian mangoes and is sought after both domestically and internationally. Its popularity has even earned it a Geographical Indication (GI) tag to protect its authenticity."
                ]
            },
            {
                title: "The Aromatic Langra",
                content: [
                    "The Langra mango, with its distinctive green and slightly wrinkled skin, is celebrated for its strong aroma and a perfect balance of sweetness and tanginess.",
                    "Originating in Varanasi, Uttar Pradesh, the Langra mango is a symbol of North Indian summers. Its unique flavor profile makes it a preferred choice for making pickles and desserts."
                ]
            },
            {
                title: "The Heavenly Chaunsa",
                content: [
                    "The Chaunsa mango is a favorite in North India, especially in Punjab. It is known for its sweet, juicy, and fiberless flesh.",
                    "With its tropical aroma and a unique combination of sweet and sour notes, Chaunsa mangoes are a delight to the senses. They are available in both the early and late summer, extending the mango season's joy."
                ]
            },
            {
                title: "The Modest Ratnagiri and Devgad Alphonso",
                content: [
                    "The Ratnagiri and Devgad regions of Maharashtra are renowned for producing some of the finest Alphonso mangoes in the world.",
                    "The microclimate, proximity to the sea, and meticulous cultivation practices result in Alphonso mangoes of exceptional quality. These regions also host annual mango festivals, where enthusiasts can savor various Alphonso cultivars."
                ]
            },
            {
                title: "Mango Festivals and Culture",
                content: [
                    "Mangoes are not just a fruit but a cultural phenomenon in India. Mango festivals, such as the Mango Festival in Delhi and the Konkan Mango Festival, celebrate the diversity of mango varieties.",
                    "Mangoes have also inspired art, literature, and music, with references in classical Indian poetry and folk songs. They are an integral part of Indian traditions, from welcoming guests with a plate of sliced mangoes to using leaves for auspicious ceremonies."
                ]
            }
        ],
        conclusion: {
            content: [
                "Indian mangoes are more than a seasonal treat; they are a reflection of the country's agricultural diversity, cultural richness, and culinary artistry. Each variety tells a story of a specific region, its climate, and the dedication of farmers who nurture these gems.",
                "As you relish the next bite of a ripe, juicy mango, remember that you're savoring a piece of India's heritage and the essence of summer in the subcontinent."
            ]
        }
    },
    {
        id: 2,
        title: "The Bounty of the Land: A Journey Through India's Diverse Vegetables",
        introduction: {
            points: [
                "India, with its rich and diverse culinary heritage, is a land of vegetables. The country's vast agricultural landscape offers an incredible array of vegetables that play a central role in Indian cuisine.",
                "In this blog post, we embark on a flavorful journey through India's vegetable diversity. From the iconic potato to lesser-known regional gems, we'll explore the unique characteristics and cultural significance of these essential ingredients."
            ]
        },
        sections: [
            {
                title: "The Versatile Potato",
                content: [
                    "The potato, known as 'aloo' in Hindi, is one of the most versatile and widely used vegetables in India. It's a staple in numerous dishes, from spicy curries to comforting aloo parathas.",
                    "Potatoes are grown across the country, and they serve as a valuable source of carbohydrates in Indian diets. Their adaptability in various cuisines makes them indispensable."
                ]
            },
            {
                title: "The Queen of Greens: Spinach",
                content: [
                    "Spinach, or 'palak' in Hindi, is revered for its nutritional value and versatility. It's a key ingredient in dishes like saag and palak paneer.",
                    "Spinach is a rich source of iron and other essential nutrients, making it a vital component of vegetarian diets. It's celebrated for its health benefits and delightful taste."
                ]
            },
            {
                title: "The Fiery Green Chili",
                content: [
                    "Green chilies are the fiery flavor enhancers of Indian cuisine. They add heat and depth to various dishes, making them a favorite of spice lovers.",
                    "Different regions of India are known for their specific types of green chilies, each with its unique level of spiciness and flavor."
                ]
            },
            {
                title: "The All-Purpose Onion",
                content: [
                    "Onions are the aromatic foundation of Indian cooking. They serve as the base for countless curries, gravies, and sauces.",
                    "India produces a wide variety of onions, each with distinct flavors and pungency levels. They're not only essential for taste but also for texture and thickness in gravies."
                ]
            },
            {
                title: "Regional Gems: Bhindi and Karela",
                content: [
                    "Bhindi, also known as okra, is a beloved vegetable in North India. Its unique texture and ability to absorb flavors make it a popular choice for curries and stir-fries.",
                    "Karela, or bitter gourd, is celebrated for its health benefits. Despite its bitterness, it's used in various regional cuisines and prepared in ways that make it a delicacy for some."
                ]
            }
        ],
        conclusion: {
            content: [
                "Indian vegetables are not just ingredients; they are essential elements of India's culinary traditions and regional diversity. From the Himalayan north to the coastal south, the variety of vegetables reflects the country's rich agricultural heritage.",
                "As you savor the next Indian dish, take a moment to appreciate the vegetables that make it so flavorful and diverse. They are the heart and soul of Indian cuisine."
            ]
        }
    },
    {
        id: 3,
        title: "Bananas in India: A Sweet Journey Through Varieties and Culture",
        introduction: {
            points: [
                "Bananas, one of the most beloved fruits globally, hold a special place in India's culture and cuisine. They are not just a snack but an integral part of the country's culinary heritage.",
                "In this blog post, we'll embark on a delightful journey through the world of Indian bananas. From the popular Cavendish to indigenous varieties, we'll explore the unique characteristics and cultural significance of these sweet treasures."
            ]
        },
        sections: [
            {
                title: "The Beloved Cavendish Banana",
                content: [
                    "The Cavendish banana, with its bright yellow skin and creamy flesh, is one of the most common banana varieties found in India. It's widely enjoyed as a quick snack and is often used in smoothies and desserts.",
                    "Cavendish bananas are grown across the country and are available year-round, making them a convenient and readily accessible fruit."
                ]
            },
            {
                title: "The Aromatic Robusta Banana",
                content: [
                    "Robusta bananas, known for their distinctive red skin and rich aroma, are popular in Southern India. They are smaller in size but pack a robust flavor.",
                    "These bananas are often used in traditional South Indian dishes, and their natural sweetness makes them perfect for desserts and sweets."
                ]
            },
            {
                title: "The Cooking Banana: Plantains",
                content: [
                    "Plantains, or 'kachha kela' in Hindi, are a versatile variety of bananas used for cooking. They are starchy and less sweet than dessert bananas.",
                    "Plantains are a staple in many regional cuisines and are used to make dishes like banana chips, curries, and fritters. They are known for their ability to absorb flavors and spices."
                ]
            },
            {
                title: "Bananas in Rituals and Festivals",
                content: [
                    "Bananas play a significant role in Indian rituals and festivals. They are offered to deities as a symbol of auspiciousness and are an essential element of many religious ceremonies.",
                    "During festivals like Ganesh Chaturthi and Navratri, bananas are used in special offerings and prasad (blessed food) distributed to devotees."
                ]
            },
            {
                title: "A Symbol of Hospitality",
                content: [
                    "In India, offering a banana to guests is a traditional gesture of hospitality. It is considered a symbol of respect and warmth.",
                    "Guests are often welcomed with a plate of bananas, and the practice reflects the culture's emphasis on graciousness and friendliness."
                ]
            }
        ],
        conclusion: {
            content: [
                "Indian bananas are more than a fruit; they are an embodiment of India's culinary diversity, culture, and traditions. Whether enjoyed as a snack, incorporated into delicious dishes, or offered in rituals, bananas hold a special place in the hearts of Indians.",
                "Next time you savor a banana in India, remember that you're tasting not just a fruit but a piece of the country's rich cultural tapestry and the sweet simplicity that binds communities together."
            ]
        }
    },
    {
        id: 4,
        title: "India's Spice Story: Aromatic Odyssey Through Flavors",
        introduction: {
            points: [
                "Indian cuisine is renowned for its vibrant flavors, and at the heart of these flavors are the aromatic spices that have enchanted taste buds for centuries.",
                "In this blog post, we embark on an aromatic journey through India's rich spice heritage. From the fiery red chili to the warm embrace of cinnamon, we'll explore the diverse spices that define Indian cooking."
            ]
        },
        sections: [
            {
                title: "The Fiery Red Chili",
                content: [
                    "Red chili, or 'mirch' in Hindi, is a symbol of India's love for spice. It adds heat and depth to countless dishes, from spicy curries to fiery chutneys.",
                    "India boasts a wide variety of chili peppers, each with its unique level of spiciness and flavor. The spice level can range from mildly hot to tongue-tingling hot."
                ]
            },
            {
                title: "King of Spices: Black Pepper",
                content: [
                    "Black pepper, or 'kali mirch,' is one of the oldest and most commonly used spices in India. It offers a pungent and slightly sweet flavor with a hint of heat.",
                    "Black pepper is not only used to season dishes but also as a prominent ingredient in many spice blends. It's known for its warming properties."
                ]
            },
            {
                title: "A Touch of Warmth: Cinnamon",
                content: [
                    "Cinnamon, or 'dalchini,' adds a warm and sweet-spicy flavor to both sweet and savory dishes. It is a key component of Indian spice blends like garam masala.",
                    "Cinnamon is prized for its aroma and the comforting warmth it imparts to various curries, desserts, and masalas."
                ]
            },
            {
                title: "The Aromatic Cardamom",
                content: [
                    "Cardamom, or 'elaichi,' is known for its intense aroma and sweet-spicy flavor. It is used in desserts, teas, and savory dishes, adding a touch of sophistication to the flavors.",
                    "Cardamom is also a symbol of hospitality and is often offered to guests as a gesture of warm welcome."
                ]
            },
            {
                title: "Earth's Gold: Turmeric",
                content: [
                    "Turmeric, or 'haldi,' is a bright yellow spice with earthy, slightly bitter notes. It is an integral part of Indian cuisine and is renowned for its medicinal properties.",
                    "Turmeric lends its vibrant color to dishes like curry and is used as a natural dye and healing remedy."
                ]
            }
        ],
        conclusion: {
            content: [
                "Indian spices are the soul of the country's cuisine, offering a symphony of flavors that dance on the palate. Whether you're savoring a fiery curry or a fragrant biryani, the magic of Indian spices is what makes each bite unforgettable.",
                "As you explore the rich tapestry of Indian cuisine, remember that the essence of these dishes lies in the skillful use of spices. Each spice tells a story of India's culinary heritage and the art of creating unforgettable flavors."
            ]
        }
    },
    {
        id: 5,
        title: "The Potato Chronicles: Exploring India's Love Affair with Aloo",
        introduction: {
            points: [
                "In the grand tapestry of Indian cuisine, one ingredient stands out as a beloved staple – the humble potato, known as 'aloo' in Hindi. It's a versatile and much-adored vegetable that has found its way into countless dishes, from street food to elaborate curries.",
                "In this blog post, we embark on a delightful journey through India's love affair with the potato. From classic aloo parathas to spicy samosas, we'll explore the potato's diverse roles in Indian culinary traditions."
            ]
        },
        sections: [
            {
                title: "Aloo: The Culinary Chameleon",
                content: [
                    "Potatoes are culinary chameleons, adapting to various cuisines and flavors. In India, they are used in endless forms, from being mashed in aloo tikkis to diced in curries and stuffed in parathas.",
                    "The potato's neutral flavor and ability to absorb spices make it an ideal canvas for a wide range of Indian dishes."
                ]
            },
            {
                title: "Aloo Paratha: Breakfast Delight",
                content: [
                    "Aloo paratha, or stuffed potato flatbread, is a cherished breakfast dish in North India. The warm, soft bread encases a flavorful potato filling.",
                    "Served with a dollop of ghee or yogurt and a side of pickles, aloo paratha is a comfort food that resonates with people of all ages."
                ]
            },
            {
                title: "Street Food Sensation: Aloo Chaat",
                content: [
                    "Aloo chaat is a popular street food that combines boiled and spiced potatoes with tangy chutneys, onions, and spices.",
                    "It's a burst of flavors and textures, offering a delightful and quick snack for those exploring India's bustling streets."
                ]
            },
            {
                title: "Aloo in Curries and Gravies",
                content: [
                    "Potatoes play a significant role in Indian curries and gravies. Whether in aloo gobi (potato and cauliflower curry) or dum aloo (slow-cooked potato curry), they add heartiness and absorb the rich, aromatic flavors of the spices.",
                    "The potato's creamy texture complements the robustness of Indian curry sauces, creating a harmonious and satisfying dish."
                ]
            },
            {
                title: "Spicy Samosas: A Teatime Favorite",
                content: [
                    "Samosas are a popular teatime snack and street food, and aloo samosas, filled with spiced potato filling, are a classic choice.",
                    "Samosas, with their crispy, flaky pastry and savory potato interior, are a delightful combination of textures and flavors."
                ]
            }
        ],
        conclusion: {
            content: [
                "The potato, with its versatility and comforting appeal, is an integral part of Indian cuisine. It's not just a vegetable; it's a symbol of warmth and hospitality, serving as a unifying ingredient in a diverse land of flavors.",
                "As you savor the next potato dish, whether it's aloo dum or aloo jeera, take a moment to appreciate the magic of the humble potato, a true culinary hero in India's culinary legacy."
            ]
        }
    },
    {
        id: 6,
        title: "The Spice of Kings: Cloves in Indian Cuisine and Culture",
        introduction: {
            points: [
                "Cloves, the aromatic flower buds of the clove tree, are among the most prized and fragrant spices in Indian cuisine. They are known for their rich, warm flavor and are a vital component of countless Indian dishes.",
                "In this blog post, we'll embark on a flavorful journey through India's appreciation for cloves. From spiced biryanis to fragrant masalas, we'll explore the unique role of cloves in Indian culinary traditions and culture."
            ]
        },
        sections: [
            {
                title: "Aromatic Cloves: Nature's Perfume",
                content: [
                    "Cloves are treasured for their intense aroma and sweet, pungent taste. They are a key ingredient in Indian spice blends like garam masala, contributing depth and warmth to dishes.",
                    "In addition to their culinary use, cloves are also known for their medicinal properties and are used in traditional Ayurvedic remedies."
                ]
            },
            {
                title: "The Heart of Biryani: Cloves and Rice",
                content: [
                    "Biryani, a beloved Indian dish, relies on cloves for their distinctive flavor and aroma. The spice is an essential component in the biryani masala that infuses the rice and meat with a fragrant blend of spices.",
                    "The presence of cloves in biryani symbolizes the rich history of Indian culinary traditions, where spices were used not just for taste but also for their preserving and medicinal qualities."
                ]
            },
            {
                title: "Cloves in Masalas and Curries",
                content: [
                    "Cloves are a crucial component of masalas, the spice blends that form the backbone of Indian curries. Their warm and sweet notes complement the flavors of other spices, creating a harmonious symphony of tastes.",
                    "In curries like Rogan Josh or Chana Masala, cloves add depth and complexity, enhancing the overall taste experience."
                ]
            },
            {
                title: "Cloves in Sweets and Desserts",
                content: [
                    "Cloves find their way into Indian sweets and desserts as well. In dishes like gajar ka halwa and spiced cakes, they provide a delightful contrast to the sweetness and add a touch of warmth.",
                    "Their presence in desserts reflects the Indian love for balancing flavors and creating a sensory journey in every bite."
                ]
            },
            {
                title: "Cultural Significance of Cloves",
                content: [
                    "Cloves have cultural significance in India beyond the kitchen. They are used in rituals, ceremonies, and Ayurvedic treatments for their purifying and healing properties.",
                    "In Indian culture, cloves are often associated with warmth, hospitality, and tradition. Their presence in a spice box, or 'masala dabba,' symbolizes the heart of the Indian kitchen."
                ]
            }
        ],
        conclusion: {
            content: [
                "Cloves, with their rich and versatile character, hold a special place in Indian cuisine and culture. They represent the essence of India's culinary diversity, from the flavorful biryanis to the spiced sweets that delight the taste buds.",
                "As you savor your next clove-infused dish or experience the comforting aroma of garam masala, remember that cloves are not just spices; they are the 'spice of kings' that have woven themselves into India's history, traditions, and everyday life."
            ]
        }
    },
    {
        id: 7,
        title: "Sipping the Flavors of India: A Journey Through Diverse Indian Beverages",
        introduction: {
            points: [
                "India, a land of rich traditions and flavors, offers a treasure trove of diverse and delightful beverages. From aromatic teas to cooling lassis, these beverages play a crucial role in Indian culture and cuisine.",
                "In this blog post, we embark on a flavorful journey through India's unique beverages. From the world-famous chai to regional specialties, we'll explore the captivating flavors and cultural significance of these liquid delights."
            ]
        },
        sections: [
            {
                title: "Chai: The National Elixir",
                content: [
                    "Chai, or spiced tea, is the lifeblood of India. It's a ubiquitous beverage enjoyed throughout the day, from the bustling streets to the serene countryside.",
                    "A flavorful combination of black tea leaves, milk, spices, and sweeteners, chai is both a daily ritual and a social tradition. It's a symbol of hospitality, and there are countless regional variations."
                ]
            },
            {
                title: "Filter Coffee: South India's Pride",
                content: [
                    "South India boasts a strong coffee culture, and filter coffee, known as 'kaapi,' is a source of pride. It's a unique blend of dark roasted coffee beans and chicory, brewed in a special metal filter.",
                    "Filter coffee is served in a 'tumbler and dabara' set, and the ritual of frothing the coffee is as important as its taste. It's a taste of tradition and warmth in every cup."
                ]
            },
            {
                title: "Lassi: The Cooling Elixir",
                content: [
                    "Lassi is a cooling yogurt-based drink that offers respite from India's scorching summers. It comes in sweet and savory varieties, each with its unique twist.",
                    "From sweet mango lassi to spiced and salty masala lassi, these beverages are not only refreshing but also rich in probiotics and nutrients."
                ]
            },
            {
                title: "Nimbu Pani: The Lemon Quencher",
                content: [
                    "Nimbu pani, or lemonade, is a simple and rejuvenating drink. It's made from freshly squeezed lemons, water, sugar, and a dash of salt and spices.",
                    "Nimbu pani is the ideal way to beat the heat and stay hydrated, and it's a street vendor's specialty during the hot summer months."
                ]
            },
            {
                title: "Sugarcane Juice: Sweet Nectar",
                content: [
                    "Sugarcane juice is a sweet and natural refreshment found in every corner of India. It's extracted by crushing fresh sugarcane stalks and is a favorite among health-conscious individuals.",
                    "Sugarcane juice stalls offer a range of variations, from plain juice to spiced and flavored concoctions."
                ]
            }
        ],
        conclusion: {
            content: [
                "Indian beverages are more than just thirst-quenchers; they are an expression of the country's diversity and a reflection of its cultural and regional richness. These drinks are not just about flavors but also about the warmth and hospitality that Indians extend to their guests.",
                "As you explore the rich tapestry of Indian beverages, remember that each sip is an opportunity to experience the heart and soul of India's culinary traditions and warm hospitality."
            ]
        }
    },
    {
        id: 8,
        title: "The Elixir of India: Exploring the Diversity of Indian Teas",
        introduction: {
            points: [
                "Tea, often referred to as 'chai' in India, is more than just a beverage; it's an integral part of the country's culture and daily life. India is renowned for its diverse and aromatic teas that have delighted tea enthusiasts worldwide.",
                "In this blog post, we'll embark on a delightful journey through India's tea heritage. From the world-famous Masala Chai to the high-grown Darjeeling and everything in between, we'll explore the captivating flavors and cultural significance of Indian teas."
            ]
        },
        sections: [
            {
                title: "Masala Chai: The Spiced Elixir",
                content: [
                    "Masala Chai is perhaps India's most iconic tea. It's a fragrant blend of black tea leaves, milk, spices, and sweeteners, including ginger, cardamom, and cinnamon.",
                    "Masala Chai is not just a drink; it's a daily ritual and a symbol of hospitality. It's served in every corner of India, from street stalls to homes."
                ]
            },
            {
                title: "Assam Tea: Bold and Brisk",
                content: [
                    "Assam, in the northeastern part of India, is famous for its robust and brisk black tea. Assam tea leaves, known for their bold flavor and strong aroma, are used in many popular blends.",
                    "Assam tea is an essential component of English Breakfast tea and is celebrated for its strong, malty character."
                ]
            },
            {
                title: "Darjeeling Tea: The Champagne of Teas",
                content: [
                    "Darjeeling, nestled in the Himalayas, produces some of the finest and most sought-after teas in the world. Known as the 'Champagne of Teas,' Darjeeling tea offers delicate, floral notes.",
                    "With distinct flushes (harvest seasons), Darjeeling teas vary from first flush with a light and floral character to the second flush, which is deeper and muscatel in flavor."
                ]
            },
            {
                title: "Green Tea: Health and Elegance",
                content: [
                    "Green tea, appreciated for its health benefits, is a growing trend in India. It's known for its fresh, grassy notes and is enjoyed both plain and flavored.",
                    "India's green teas, such as the famous Nilgiri green tea, offer a balance of antioxidants and soothing flavors."
                ]
            },
            {
                title: "Specialty Teas and Blends",
                content: [
                    "India is home to a wide variety of specialty teas and unique blends. From the delicate White tea to the smoky Lapsang Souchong, there's a tea for every palate.",
                    "Innovative tea blends like Rose Chai and Turmeric-infused tea are emerging, offering a modern twist to traditional Indian teas."
                ]
            }
        ],
        conclusion: {
            content: [
                "Indian teas are more than just beverages; they are a journey through the country's diverse landscapes, cultures, and flavors. Each cup of tea tells a story, whether it's the warmth of a Masala Chai shared with friends or the elegance of a Darjeeling tea savored in solitude.",
                "As you explore the rich tapestry of Indian teas, remember that each sip is an opportunity to experience the depth and diversity of India's tea traditions and the warm hospitality that accompanies every brew."
            ]
        }
    },
    {
        id: 9,
        title: "Zesty Zing: The Versatile and Flavorful World of Indian Ginger",
        introduction: {
            points: [
                "Ginger, known as 'adrak' in Hindi, is a spice that's deeply woven into the tapestry of Indian cuisine and culture. Its distinct zesty flavor and aromatic warmth make it a cherished ingredient in countless Indian dishes.",
                "In this blog post, we'll take a flavorful journey through India's love for ginger. From spiced masalas to soothing teas, we'll explore the versatility and cultural significance of this knobby root."
            ]
        },
        sections: [
            {
                title: "Ginger in Indian Cooking",
                content: [
                    "Ginger is a staple in Indian kitchens. Whether it's used fresh, dried as ginger powder, or as ginger paste, it imparts a spicy, pungent flavor and adds depth to dishes.",
                    "In Indian curries, gravies, and stir-fries, ginger is often paired with garlic and other spices to create a harmonious blend of flavors."
                ]
            },
            {
                title: "Ginger Masala: The Heart of Indian Cuisine",
                content: [
                    "Ginger, along with garlic, forms the base for many Indian masalas, the spice blends that define the essence of Indian cooking. The combination of these ingredients creates a flavorful foundation for curries and gravies.",
                    "Ginger-based masalas, such as ginger-garlic paste and ginger-green chili paste, add a spicy kick and a fragrant aroma to many dishes."
                ]
            },
            {
                title: "Ginger in Ayurveda and Health",
                content: [
                    "In Ayurveda, the ancient Indian system of medicine, ginger is celebrated for its medicinal properties. It's used to aid digestion, relieve nausea, and reduce inflammation.",
                    "Ginger tea, made from fresh ginger root, is a popular home remedy for colds and coughs, as it soothes the throat and provides warmth."
                ]
            },
            {
                title: "Ginger-Infused Teas and Drinks",
                content: [
                    "Ginger tea, often called 'adrak wali chai,' is a beloved beverage in India. It's made by steeping fresh ginger slices in hot water and can be enjoyed plain or with added spices and honey.",
                    "Ginger also finds its way into refreshing drinks like 'shikanji' and 'masala chai,' providing a zesty kick to quench your thirst."
                ]
            },
            {
                title: "Cultural Significance of Ginger",
                content: [
                    "Ginger is more than just an ingredient; it's a symbol of warmth, hospitality, and tradition. It's offered to guests as a gesture of welcome, and ginger-based drinks are served to refresh visitors.",
                    "Ginger is also used in religious rituals, symbolizing purity and auspicious beginnings."
                ]
            }
        ],
        conclusion: {
            content: [
                "Ginger, with its versatile and vibrant character, is an essential part of Indian cuisine and culture. It's not just a spice; it's an embodiment of the flavors, traditions, and hospitality that define India's culinary heritage.",
                "As you savor your next ginger-infused dish or sip a cup of ginger tea, remember that you're experiencing the zest of India, where the knobby root adds not just flavor but also a touch of tradition to every meal."
            ]
        }
    },
    {
        id: 10,
        title: "The Aromatic Wonder: Bay Leaves in Indian Cuisine and Culture",
        introduction: {
            points: [
                "Bay leaves, known as 'tej patta' in Hindi, are a fragrant and essential spice in Indian cooking. Their aromatic qualities and unique flavor have earned them a special place in the heart of Indian cuisine.",
                "In this blog post, we'll embark on an aromatic journey through India's love for bay leaves. From biryanis to flavorful curries, we'll explore the versatility and cultural significance of these aromatic leaves."
            ]
        },
        sections: [
            {
                title: "The Aroma of Bay Leaves",
                content: [
                    "Bay leaves are known for their aromatic and slightly sweet scent. They are often used to add depth and complexity to Indian dishes.",
                    "These dried leaves are prized for their ability to infuse dishes with a subtle fragrance and a mild, earthy flavor."
                ]
            },
            {
                title: "Bay Leaves in Indian Curries",
                content: [
                    "Bay leaves are a common addition to Indian curries and gravies. They are used to season the cooking oil or ghee at the beginning of the recipe, infusing the base with their aroma.",
                    "In dishes like biryani and rich tomato-based gravies, bay leaves are a key component that enhances the overall flavor profile."
                ]
            },
            {
                title: "Aromatic Spice Blends",
                content: [
                    "Bay leaves find their way into many Indian spice blends. They are a part of 'garam masala,' the popular spice blend used in a variety of Indian dishes.",
                    "These spice blends, featuring bay leaves and other aromatic spices, are a testament to the intricate layering of flavors in Indian cuisine."
                ]
            },
            {
                title: "Culinary and Medicinal Uses",
                content: [
                    "Beyond their culinary uses, bay leaves have been valued for their medicinal properties in traditional Indian medicine systems like Ayurveda.",
                    "They are believed to aid digestion, improve respiratory health, and have anti-inflammatory properties. Bay leaf tea is a soothing drink often used for these purposes."
                ]
            },
            {
                title: "Cultural Significance",
                content: [
                    "Bay leaves hold cultural significance in India, where they are often associated with auspicious beginnings and ceremonies.",
                    "They are used in religious rituals and offerings, symbolizing purity, blessings, and the aromatic essence of India's culinary traditions."
                ]
            }
        ],
        conclusion: {
            content: [
                "Bay leaves, with their subtle and fragrant presence, are an integral part of Indian cuisine and culture. They are not just leaves; they are the aromatic thread that weaves through the tapestry of India's flavorful dishes and traditions.",
                "As you savor your next biryani or rich curry, take a moment to appreciate the bay leaves that contribute to the depth and authenticity of Indian flavors, infusing every bite with the spirit of India's culinary heritage."
            ]
        }
    },
    {
        id: 11,
        title: "Onion: The Flavorful Foundation of Indian Cooking",
        introduction: {
            points: [
                "Onions, known as 'pyaaz' in Hindi, are a fundamental ingredient in Indian cuisine. Their versatility, distinct flavor, and ability to create rich, aromatic bases have made them a kitchen essential in India.",
                "In this blog post, we'll embark on a flavorful journey through India's love for onions. From savory curries to spicy biryanis, we'll explore the essential role of onions in Indian culinary traditions."
            ]
        },
        sections: [
            {
                title: "The Aromatic Allium",
                content: [
                    "Onions belong to the allium family, known for their strong and aromatic flavors. They come in various types, from pungent red onions to milder yellow and white onions.",
                    "Onions are not only used for their flavor but also for the texture and thickness they lend to gravies and sauces."
                ]
            },
            {
                title: "Onions in Indian Curries",
                content: [
                    "Onions serve as the foundation for many Indian curries and gravies. They are finely chopped or blended into a paste to create a rich and aromatic base.",
                    "In dishes like butter chicken, onion masala forms the heart of the curry, imparting a sweet and savory profile to the dish."
                ]
            },
            {
                title: "Biryani and Pulao Perfection",
                content: [
                    "Onions play a crucial role in biryanis and pulaos, two of India's beloved rice dishes. Sliced and caramelized onions, known as 'birista,' are used to add depth and sweetness to these dishes.",
                    "The process of frying onions to a golden brown in ghee or oil, known as 'bhuna pyaaz,' is an art that elevates the flavor of these dishes."
                ]
            },
            {
                title: "Onions in Street Food",
                content: [
                    "Onions are a common accompaniment in Indian street food. They are finely chopped and served as a topping for snacks like chaats, pav bhaji, and kebabs.",
                    "The sharpness of raw onions adds a refreshing and crunchy contrast to the spiciness of these street food delights."
                ]
            },
            {
                title: "Cultural Significance",
                content: [
                    "Onions have cultural significance in India beyond their culinary use. They are often associated with auspicious beginnings and ceremonies.",
                    "In some Indian traditions, onions are offered in religious rituals, symbolizing purity, blessings, and the aromatic essence of India's culinary traditions."
                ]
            }
        ],
        conclusion: {
            content: [
                "Onions, with their distinctive flavor and versatility, are an integral part of Indian cuisine. They are not just vegetables; they are the flavorful foundation on which many of India's iconic dishes are built.",
                "As you savor your next curry or biryani, take a moment to appreciate the humble onion, an ingredient that has woven itself into the rich tapestry of India's culinary heritage."
            ]
        }
    },
    {
        id: 12,
        title: "Zesty Sunshine: Exploring the Versatile Indian Lemon",
        introduction: {
            points: [
                "Lemons, known as 'nimbu' in Hindi, are a vibrant and versatile citrus fruit that adds a refreshing burst of flavor to Indian cuisine. They are cherished for their zesty and tangy profile, making them a beloved ingredient in Indian kitchens.",
                "In this blog post, we'll embark on a zesty journey through India's appreciation for lemons. From tangy chaats to cooling nimbu pani, we'll explore the versatility and cultural significance of this sunny fruit in Indian culinary traditions."
            ]
        },
        sections: [
            {
                title: "The Tangy All-Rounder",
                content: [
                    "Lemons are cherished for their unique combination of sour and refreshing flavors. They're used both for their juice and zest in a wide variety of dishes.",
                    "Whether it's to enhance the taste of a dish, preserve fruits and pickles, or simply to add a dash of freshness, lemons play a central role in Indian cuisine."
                ]
            },
            {
                title: "Lemons in Chaats and Street Food",
                content: [
                    "Lemons are a common sight at Indian street food stalls. They are used to squeeze fresh lemon juice over dishes like pani puri, sev puri, and bhel puri.",
                    "The tangy kick of lemon juice enhances the flavors and adds a zesty element to these popular street snacks."
                ]
            },
            {
                title: "Lemon Pickles: A Flavorful Tradition",
                content: [
                    "Lemon pickles, or 'nimbu ka achar,' are a cherished part of Indian cuisine. Lemons are preserved in a mixture of spices, salt, and oil to create a tangy and spicy condiment.",
                    "These pickles are a delightful accompaniment to Indian meals, providing a burst of flavor and complexity to each bite."
                ]
            },
            {
                title: "Nimbu Pani: The Lemon Quencher",
                content: [
                    "Nimbu pani, or lemonade, is a classic Indian drink made from fresh lemon juice, water, sugar, salt, and a touch of spices. It's the ultimate thirst quencher on hot summer days.",
                    "Nimbu pani stalls are a common sight across India, offering this refreshing beverage to beat the scorching heat."
                ]
            },
            {
                title: "Cultural Significance of Lemons",
                content: [
                    "Lemons hold cultural significance in India beyond the kitchen. They are often associated with purity and used in religious rituals and ceremonies.",
                    "In Indian traditions, lemons are offered in prayers and auspicious beginnings, symbolizing blessings and the zest of life."
                ]
            }
        ],
        conclusion: {
            content: [
                "Lemons, with their tangy and versatile character, are an integral part of Indian cuisine and culture. They are not just fruits; they are the zesty sunshine that brightens up a wide range of Indian dishes and traditions.",
                "As you savor your next chaat or sip on a glass of nimbu pani, remember that the humble lemon is more than just an ingredient; it's a symbol of freshness, vitality, and the rich tapestry of India's culinary heritage."
            ]
        }
    },
    {
        id: 13,
        title: "The Nut of Life: The Versatility of Coconuts in Indian Cuisine and Culture",
        introduction: {
            points: [
                "Coconuts, known as 'nariyal' in Hindi, are often referred to as the 'nut of life' in India. These versatile drupes play a crucial role in Indian cuisine, offering a range of flavors and culinary uses.",
                "In this blog post, we'll explore the myriad ways in which coconuts are celebrated in India. From creamy coconut milk to coconut chutney, we'll dive into their diverse roles in Indian culinary traditions and culture."
            ]
        },
        sections: [
            {
                title: "The All-Purpose Coconut",
                content: [
                    "Coconuts are known for their multifaceted utility. They offer not just the flesh but also coconut water, milk, and oil, each with its unique culinary application.",
                    "In Indian cuisine, coconuts are revered for their refreshing, sweet flavor and their ability to provide rich, creamy textures to dishes."
                ]
            },
            {
                title: "Coconut Milk: The Creamy Elixir",
                content: [
                    "Coconut milk, extracted from grated coconut flesh, is a fundamental ingredient in South Indian and coastal Indian cuisine. It adds a creamy and tropical richness to curries and stews.",
                    "In dishes like Kerala's coconut milk-based 'Ishtu' or Goan 'Fish Curry,' coconut milk serves as the heart of the recipe."
                ]
            },
            {
                title: "Coconut Chutney: The Flavorful Accompaniment",
                content: [
                    "Coconut chutney is a beloved condiment in South Indian cuisine. It's made by blending grated coconut with ingredients like green chilies, curry leaves, and yogurt.",
                    "This chutney is served as a side with dosas, idlis, and vadas, offering a refreshing and spicy contrast to the main dishes."
                ]
            },
            {
                title: "Coconut Oil: The Cooking Essential",
                content: [
                    "Coconut oil is a staple cooking oil in South India and coastal regions. It's used not only for cooking but also in hair care and traditional Ayurvedic treatments.",
                    "In Kerala, 'coconut oil tarka' is a common technique, where spices and curry leaves are tempered in coconut oil to infuse a unique flavor into the dishes."
                ]
            },
            {
                title: "Cultural Significance of Coconuts",
                content: [
                    "Coconuts hold cultural significance in India beyond the kitchen. They are often used in rituals, ceremonies, and religious offerings.",
                    "In some Indian traditions, coconuts are broken open as a symbol of purity and are offered to deities in prayers and auspicious beginnings."
                ]
            }
        ],
        conclusion: {
            content: [
                "Coconuts, with their versatile and tropical character, are an integral part of Indian cuisine and culture. They are not just nuts; they are the 'nut of life' that enriches Indian dishes with their unique flavors and textures.",
                "As you savor your next coconut-infused curry or enjoy a fresh sip of coconut water, remember that the humble coconut is not just an ingredient; it's a symbol of refreshment, tradition, and the rich diversity of India's culinary heritage."
            ]
        }
    },
    {
        id: 14,
        title: "Soya Bean: The Protein Powerhouse Transforming Indian Cuisine",
        introduction: {
            points: [
                "Soya beans, often referred to as 'soyabean' in India, have become a nutritional powerhouse and a game-changer in the country's cuisine. They are celebrated for their high protein content and versatility in various dishes.",
                "In this blog post, we'll explore the rise of soya beans in Indian culinary traditions. From delectable soy-based recipes to their impact on vegetarian diets, we'll delve into their significant role in India's evolving food culture."
            ]
        },
        sections: [
            {
                title: "The Soya Revolution",
                content: [
                    "Soya beans have gained immense popularity in India due to their high protein content, making them a sought-after source of plant-based protein.",
                    "From soya chunks to tofu and soya milk, this versatile legume has found its way into a wide range of vegetarian and vegan dishes."
                ]
            },
            {
                title: "Soya Chunks: The Protein Substitute",
                content: [
                    "Soya chunks, also known as 'nutrela' or 'meal maker,' have become a staple in vegetarian Indian cooking. These textured protein nuggets are used in a variety of dishes.",
                    "They are often used as a meat substitute in Indian curries, biryanis, and stir-fries, providing a hearty and protein-rich option."
                ]
            },
            {
                title: "Tofu: The Plant-Based Paneer",
                content: [
                    "Tofu, made from soy milk, is the Indian counterpart of cottage cheese or 'paneer' for vegetarians. It's a versatile ingredient used in both savory and sweet dishes.",
                    "Tofu is used in dishes like tofu butter masala and desserts like tofu cheesecakes, offering a creamy and protein-packed alternative."
                ]
            },
            {
                title: "Soya Milk: The Dairy-Free Option",
                content: [
                    "Soya milk has become a popular dairy-free option for lactose-intolerant individuals and vegans. It's used in beverages like soy chai and soy lassi.",
                    "Soya milk is also the base for making dairy-free versions of traditional Indian sweets and puddings."
                ]
            },
            {
                title: "Health and Sustainability",
                content: [
                    "Soya beans are celebrated not only for their nutritional value but also for their sustainability. They require less water and land compared to meat production, making them an eco-friendly protein source.",
                    "The incorporation of soya beans into Indian cuisine is not just about nutrition but also about sustainable food choices."
                ]
            }
        ],
        conclusion: {
            content: [
                "Soya beans, with their protein-packed potential, have brought about a significant change in Indian cuisine and dietary preferences. They are not just legumes; they are the 'protein powerhouse' that is reshaping India's culinary landscape.",
                "As you enjoy your next soy-based dish or savor a sip of soya milk, remember that soya beans represent a shift towards healthier and more sustainable food choices in India, catering to the evolving tastes and dietary needs of a diverse population."
            ]
        }
    },
    {
        id: 15,
        title: "Nourishing Delights: Exploring the World of Indian Dry Fruits",
        introduction: {
            points: [
                "Dry fruits, also known as 'dry fruits and nuts,' are a treasure trove of nutritional goodness in Indian cuisine. They have been cherished for centuries for their wholesome qualities and indulgent flavors.",
                "In this blog post, we'll delve into the captivating world of Indian dry fruits. From almonds to pistachios, we'll explore their diverse flavors and their significant place in Indian culinary traditions and culture."
            ]
        },
        sections: [
            {
                title: "A Nutrient-Rich Medley",
                content: [
                    "Dry fruits are a rich source of essential nutrients like vitamins, minerals, and antioxidants. They are packed with energy and are often consumed as a nutritious snack or added to various dishes.",
                    "Popular dry fruits in India include almonds, cashews, pistachios, walnuts, and dried apricots, each offering a unique blend of flavors and health benefits."
                ]
            },
            {
                title: "Dry Fruits in Indian Sweets",
                content: [
                    "Dry fruits play a significant role in Indian sweets and desserts. They are often finely chopped or used as whole pieces to garnish and enhance the flavors of traditional sweets like barfis, ladoos, and halwas.",
                    "These sweet treats often incorporate a generous amount of dry fruits to provide a delightful contrast to the sweetness."
                ]
            },
            {
                title: "Dry Fruits in Festive Celebrations",
                content: [
                    "Dry fruits are an integral part of Indian festive celebrations and special occasions. They are often exchanged as gifts during festivals like Diwali and weddings as a symbol of prosperity and good fortune.",
                    "The gifting of ornate boxes filled with assorted dry fruits is a time-honored tradition in India."
                ]
            },
            {
                title: "Dry Fruits in Healthy Snacking",
                content: [
                    "Dry fruits are a popular choice for healthy snacking in India. They are not only nutritious but also convenient to carry and consume.",
                    "From roasted almonds to salted pistachios, these snacks provide an energy boost and a satisfying crunch for those on the go."
                ]
            },
            {
                title: "Cultural Significance",
                content: [
                    "Dry fruits hold cultural significance in India beyond their culinary use. They are often used in religious rituals and offerings.",
                    "In many Indian traditions, dry fruits symbolize good luck, blessings, and abundance, making them an integral part of auspicious occasions."
                ]
            }
        ],
        conclusion: {
            content: [
                "Dry fruits, with their rich flavors and nutritional benefits, are an essential component of Indian cuisine and culture. They are not just snacks; they are the nourishing delights that add depth and sweetness to Indian dishes and celebrations.",
                "As you enjoy your next helping of dry fruits or savor a piece of dry fruit-laden sweet, remember that these delectable treats represent not only a celebration of flavors but also a tradition of abundance and well-being in India."
            ]
        }
    },
    {
        id: 16,
        title: "Cashew: The Creamy Delight of Indian Cuisine",
        introduction: {
            points: [
                "Cashew nuts, known as 'kaju' in Hindi, are one of the most sought-after dry fruits in Indian cuisine. They are celebrated for their rich, creamy texture and subtle sweetness, making them a cherished ingredient in countless Indian dishes.",
                "In this blog post, we'll explore the world of Indian cashews. From the iconic kaju barfi to savory cashew-based gravies, we'll dive into their versatile roles in Indian culinary traditions and culture."
            ]
        },
        sections: [
            {
                title: "The Creamy Delight",
                content: [
                    "Cashews are known for their rich and creamy texture, making them a delightful addition to both sweet and savory dishes.",
                    "Their mild sweetness and delicate flavor allow them to complement a wide range of Indian spices and ingredients."
                ]
            },
            {
                title: "Kaju Barfi: A Sweet Sensation",
                content: [
                    "Kaju barfi, a popular Indian sweet, is made from ground cashews, sugar, and ghee. It's known for its melt-in-the-mouth texture and subtle nutty flavor.",
                    "Kaju barfi is often presented as a gift during festivals and celebrations, symbolizing joy and prosperity."
                ]
            },
            {
                title: "Cashews in Indian Sweets",
                content: [
                    "Cashews are a common ingredient in various Indian sweets and desserts. They are used to garnish and enhance the flavors of sweets like gulab jamun, jalebi, and rasgulla.",
                    "The addition of cashews provides a delightful contrast to the sweetness of these traditional Indian treats."
                ]
            },
            {
                title: "Cashews in Savory Dishes",
                content: [
                    "Cashews are used in a variety of savory Indian dishes, often adding a creamy and rich texture. They are ground and used as a thickening agent in gravies and curries.",
                    "In dishes like kaju masala and cashew rice, cashews are the key ingredients that lend their nutty goodness to the recipe."
                ]
            },
            {
                title: "Cultural Significance",
                content: [
                    "Cashews have cultural significance in India beyond their culinary use. They are often used in religious rituals and ceremonies.",
                    "In some Indian traditions, cashews are associated with auspicious beginnings and symbolize good luck, making them an integral part of celebrations and offerings."
                ]
            }
        ],
        conclusion: {
            content: [
                "Cashews, with their creamy texture and subtle sweetness, are an essential part of Indian cuisine and culture. They are not just nuts; they are the creamy delights that add depth and luxury to Indian dishes and celebrations.",
                "As you savor your next piece of kaju barfi or enjoy a cashew-infused curry, remember that cashews represent not only a celebration of flavors but also a tradition of prosperity and well-being in India."
            ]
        }
    }
]

const BlogContent = () => {
    const navigate = useNavigate()
    const { id } = useParams()

    const [blog, setBlog] = useState({})

    useEffect(() => {
        setBlog(blogs.filter(element => element.id === Number(id))[0])
    }, [id])

    const onAllBlogs = () => {
        navigate('/blogs')
    }

    console.log(blog)
    return (
        <Fragment>
            <Container className='py-5'>
                <Row className='pb-5'>
                    <Col>
                        <h1 className='section-heading m-0 text-primary text-center'>{blog.title}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3 className='text-primary'>Introduction</h3>
                        <ul>
                            {
                                blog.introduction?.points?.map((item, index) => {
                                    return (
                                        <li key={index} className='text-primary'>{item}</li>
                                    )
                                })
                            }
                        </ul>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {
                            blog.sections?.map((item, index) => {
                                return (
                                    <Card className='border-0 my-1' key={index}>
                                        <CardBody>
                                            <h4 className="text-primary text-capitalize">{item.title}</h4>
                                            {
                                                item?.content?.map((point, index) => {
                                                    return (
                                                        <p key={index} className='text-primary m-0 small'><FiCheck size={25} /> {point}</p>
                                                    )
                                                })
                                            }
                                        </CardBody>
                                    </Card>
                                )
                            })
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3 className='text-primary'>Conclusion</h3>
                        <p className='text-primary'>{blog?.conclusion?.content.map((item, index) => {
                            return (
                                <span key={index}>{item}</span>
                            )
                        })}</p>
                    </Col>
                </Row>
            </Container>

            <Container fluid className='bg-warning'>
                <Container>
                    <Row className='py-5'>
                        <Col>
                            <p className='text-primary'>
                                <i>Thank you for visiting our website. We are passionate about exporting the finest produce from India, including a wide array of fruits, vegetables, spices, and dried fruits. Our commitment to quality, sustainability, and authenticity shines through in every product we offer. The rich flavors of Indian spices and the natural sweetness of our tropical fruits are a testament to our dedication to bringing you the true essence of India. We maintain strong connections with our local farmers, ensuring ethical and fair trade practices that benefit communities. As you explore our website, you'll embark on a flavorful journey through the heart of India's agricultural traditions and cultural heritage. We are honored to be your trusted source for these exquisite tastes and thank you for choosing us as your gateway to Indian flavors.</i>
                            </p>
                            <Button type='button' color='secondary' outline className='px-5 rounded-5 shadow-none' onClick={onAllBlogs}>Show All Blogs</Button>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Fragment>
    )
}

export default BlogContent