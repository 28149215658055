// React Imports
import React, { Fragment, useEffect, useState } from 'react'

// Third Party Imports
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { FiCheck } from "react-icons/fi"

// CSS Imports
import '../../../../styles/product/product.css'

// Images and Icon Imports
import almond from '../../../../assets/product-images/almond.jpg'
import pistachio from '../../../../assets/product-images/pistachio.jpg'
import cashew from '../../../../assets/product-images/cashew.jpg'
import dryFig from '../../../../assets/product-images/dry-fig.jpg'
import walnut from '../../../../assets/product-images/walnut.jpg'
import kismis from '../../../../assets/product-images/kismis.jpg'
import lotusSeed from '../../../../assets/product-images/lotus-seed.jpg'

const productDetails = [
    {
        id: "almond",
        heading: "Almond",
        about: "Almonds, known as 'badam' in India, are a highly nutritious and popular tree nut. They are prized for their mild, nutty flavor and crunchy texture.",
        description: "Indian almonds are consumed in various forms, including whole, sliced, chopped, or ground into almond flour. They are used in both sweet and savory Indian dishes and are also enjoyed as a healthy snack.",
        ingredients: {
            nutrients: ["Vitamin E", "Dietary Fiber", "Protein"],
            minerals: ["Calcium", "Magnesium"],
            vitamins: ["Vitamin B2 (Riboflavin)", "Vitamin B3 (Niacin)", "Vitamin B6"],
            protein: "21.15 gm / 100gm",
            fats: "49.42 gm / 100gm"
        },
        varieties: [
            {
                heading: "Whole Almonds",
                about: "Whole almonds are the most common variety and are often eaten as a snack, added to Indian desserts, or used as a garnish for savory dishes."
            },
            {
                heading: "Almond Slices",
                about: "Almond slices are thin, flat pieces of almonds used for decorating and enhancing the texture of various Indian sweets, desserts, and baked goods."
            },
            {
                heading: "Almond Powder (Almond Flour)",
                about: "Almond powder, also known as almond flour, is made by grinding almonds into a fine powder. It is a gluten-free alternative to wheat flour and is used in baking and cooking."
            },
            {
                heading: "Blanched Almonds",
                about: "Blanched almonds are almonds with their skins removed. They are often used in Indian sweets and desserts, as the absence of skins provides a smooth texture."
            },
            {
                heading: "Almond Milk",
                about: "Almond milk is a dairy-free milk substitute made from ground almonds. It is used in vegan and lactose-free Indian recipes, including curries and beverages."
            }
        ]
    },
    {
        id: "pistachio",
        heading: "Pistachio",
        about: "Pistachios, known as 'pista' in India, are a popular and flavorful nut with a slightly sweet and nutty taste. They are widely enjoyed as a snack and used in various culinary applications.",
        description: "Indian pistachios are often consumed in their shell, which needs to be cracked open to access the nut inside. They are used in both sweet and savory Indian dishes, and their vibrant green color makes them a popular ingredient in desserts.",
        ingredients: {
            nutrients: ["Protein", "Dietary Fiber", "Vitamin B6"],
            minerals: ["Phosphorus", "Potassium"],
            vitamins: ["Vitamin E"],
            protein: "20.27 gm / 100gm",
            fats: "45.97 gm / 100gm"
        },
        varieties: [
            {
                heading: "Whole Pistachios",
                about: "Whole pistachios are the most common variety, and they are often eaten as a standalone snack, added to Indian sweets, or used as a garnish for dishes."
            },
            {
                heading: "Shelled Pistachios",
                about: "Shelled pistachios have had their shells removed, making them convenient for use in cooking, baking, and as an ingredient in various Indian recipes."
            },
            {
                heading: "Pistachio Powder",
                about: "Pistachio powder is made by finely grinding pistachios into a powder. It is used as a flavoring agent and garnish for Indian sweets, ice creams, and desserts."
            },
            {
                heading: "Roasted Pistachios",
                about: "Roasted pistachios are whole pistachios that have been roasted for added flavor and crunch. They are a popular choice for snacking and as a topping for dishes."
            },
            {
                heading: "Pistachio Paste",
                about: "Pistachio paste is made by blending pistachios into a smooth, creamy consistency. It is used in making pistachio-flavored desserts and sweets."
            }
        ]
    },
    {
        id: "cashew",
        heading: "Cashew",
        about: "Cashews, known as 'kaju' in India, are a popular and creamy nut with a mild, buttery flavor. They are widely enjoyed as a snack and used in various culinary applications.",
        description: "Indian cashews are often eaten roasted and salted as a standalone snack. They are also used in both sweet and savory Indian dishes and are a common ingredient in traditional Indian sweets.",
        ingredients: {
            nutrients: ["Protein", "Dietary Fiber", "Vitamin B6"],
            minerals: ["Magnesium", "Phosphorus"],
            vitamins: ["Vitamin K"],
            protein: "18.22 gm / 100gm",
            fats: "43.85 gm / 100gm"
        },
        varieties: [
            {
                heading: "Whole Cashews",
                about: "Whole cashews are the most common variety, and they are often eaten as a standalone snack or used in various Indian dishes, including curries, biryanis, and sweets."
            },
            {
                heading: "Cashew Pieces",
                about: "Cashew pieces are smaller, broken cashews, often used as a topping for desserts, ice creams, and savory dishes or incorporated into Indian sweets and snacks."
            },
            {
                heading: "Cashew Powder",
                about: "Cashew powder is made by grinding cashews into a fine powder. It is used as a thickening agent and flavoring agent in Indian gravies and sweets."
            },
            {
                heading: "Cashew Butter",
                about: "Cashew butter is a creamy spread made from ground cashews. It is used as a dairy-free alternative to butter and as a base for making cashew-based desserts."
            },
            {
                heading: "Roasted and Salted Cashews",
                about: "Roasted and salted cashews are whole cashews that have been roasted to enhance their flavor and then lightly salted. They are a popular snack choice."
            }
        ]
    },
    {
        id: "dry-fig",
        heading: "Dry Fig",
        about: "Dry figs, known as 'anjeer' in India, are a dried fruit made from fresh figs. They are known for their natural sweetness and chewy texture, making them a popular and nutritious snack.",
        description: "Indian dry figs are often consumed on their own as a snack, but they are also used in various culinary preparations, including Indian sweets, desserts, and as a topping for yogurts and cereals.",
        ingredients: {
            nutrients: ["Dietary Fiber", "Calcium", "Iron"],
            minerals: ["Potassium"],
            vitamins: ["Vitamin B6"],
            protein: "3.3 gm / 100gm",
            fats: "0.9 gm / 100gm"
        },
        varieties: [
            {
                heading: "Whole Dry Figs",
                about: "Whole dry figs are the most common variety and are often enjoyed as a sweet and nutritious snack. They can also be used in cooking and baking."
            },
            {
                heading: "Chopped Dry Figs",
                about: "Chopped dry figs are dried figs that have been cut into smaller pieces. They are convenient for adding to recipes, such as cakes, cookies, and energy bars."
            },
            {
                heading: "Fig Paste",
                about: "Fig paste is made by blending dried figs into a smooth consistency. It is used as a natural sweetener in Indian sweets and desserts, as well as in energy bars and snacks."
            },
            {
                heading: "Fig Powder",
                about: "Fig powder is created by grinding dried figs into a fine powder. It can be used as a flavoring agent and sweetener in a variety of Indian dishes and desserts."
            },
            {
                heading: "Stuffed Dry Figs",
                about: "Stuffed dry figs are often filled with nuts or other dried fruits, enhancing their flavor and texture. They are a popular choice for gifting during festivals and special occasions."
            }
        ]
    },
    {
        id: "walnut",
        heading: "Walnut",
        about: "Walnuts, known as 'akhrot' in India, are a nutritious tree nut with a rich, earthy flavor. They are popular for their crunchy texture and are often enjoyed as a snack or used in cooking and baking.",
        description: "Indian walnuts are versatile and used in various culinary preparations, including Indian sweets, desserts, salads, and as a topping for yogurt. They are prized for their heart-healthy fats and antioxidants.",
        ingredients: {
            nutrients: ["Omega-3 Fatty Acids", "Protein", "Dietary Fiber"],
            minerals: ["Copper", "Manganese"],
            vitamins: ["Vitamin B6"],
            protein: "15.23 gm / 100gm",
            fats: "65.21 gm / 100gm"
        },
        varieties: [
            {
                heading: "Whole Walnuts",
                about: "Whole walnuts are the most common variety, and they are often eaten as a standalone snack or used in baking, cooking, and as a topping for desserts and salads."
            },
            {
                heading: "Walnut Halves",
                about: "Walnut halves are whole walnuts that have been split into two equal parts. They are convenient for use in recipes, such as walnut-crusted dishes and desserts."
            },
            {
                heading: "Walnut Pieces",
                about: "Walnut pieces are smaller, broken walnut kernels. They are used as a topping for various dishes, including oatmeal, yogurt, and ice cream, and in baking recipes."
            },
            {
                heading: "Walnut Oil",
                about: "Walnut oil is extracted from walnuts and is used as a flavorful and healthy cooking oil. It is also used as a dressing for salads and drizzling on dishes."
            },
            {
                heading: "Candied Walnuts",
                about: "Candied walnuts are whole or chopped walnuts that have been coated in sugar and often used as a sweet and crunchy topping for desserts and salads."
            }
        ]
    },
    {
        id: "kismis",
        heading: "Kismis (Raisins)",
        about: "Kismis, known as 'kismis' or 'kishmish' in India, are dried grapes and a popular dried fruit with a natural sweetness. They are widely used as a snack and an ingredient in various Indian dishes and desserts.",
        description: "Indian kismis are often consumed as a sweet and nutritious snack. They are also used in Indian sweets, rice dishes, desserts like kheer, and as a garnish for various savory and sweet dishes.",
        ingredients: {
            nutrients: ["Dietary Fiber", "Potassium", "Iron"],
            minerals: ["Calcium"],
            vitamins: ["Vitamin B6"],
            protein: "3.07 gm / 100gm",
            fats: "0.46 gm / 100gm"
        },
        varieties: [
            {
                heading: "Dark Raisins",
                about: "Dark raisins are the most common variety in India. They have a rich, sweet flavor and a dark brown to purple-black color. They are used in a wide range of Indian recipes and as a snack."
            },
            {
                heading: "Golden Raisins",
                about: "Golden raisins, also known as 'kishmish,' are made from green grapes and have a lighter color and slightly different flavor compared to dark raisins. They are often used in Indian sweets and desserts."
            },
            {
                heading: "Sultanas",
                about: "Sultanas are a variety of raisins made from seedless white grapes. They are smaller and sweeter than dark raisins and are commonly used in Indian desserts and bakery items."
            },
            {
                heading: "Thompson Seedless Raisins",
                about: "Thompson Seedless raisins, known for their pale color and sweet taste, are often used in making Indian sweets like ladoos and halwas."
            },
            {
                heading: "Malayer Raisins",
                about: "Malayer raisins are a premium variety with a rich flavor and dark color. They are often used as a high-quality topping for desserts and snacks."
            }
        ]
    },
    {
        id: "lotus-seed",
        heading: "Lotus Seed",
        about: "Lotus seeds, known as 'makhana' in India, are the seeds of the lotus plant and are valued for their unique taste and nutritional benefits. They are a popular and healthy snack in Indian cuisine.",
        description: "Indian lotus seeds are often consumed roasted or fried as a crunchy snack. They are also used in various Indian sweets, curries, and dishes. Lotus seeds are prized for their low-calorie content and high nutritional value.",
        ingredients: {
            nutrients: ["Protein", "Dietary Fiber", "Magnesium"],
            minerals: ["Phosphorus", "Potassium"],
            vitamins: ["Vitamin B6"],
            protein: "9.7 gm / 100gm",
            fats: "0.5 gm / 100gm"
        },
        varieties: [
            {
                heading: "Roasted Lotus Seeds",
                about: "Roasted lotus seeds are a popular snack, often seasoned with various spices or salt. They are enjoyed as a crunchy, low-fat, and nutritious alternative to traditional snacks."
            },
            {
                heading: "Fried Lotus Seeds",
                about: "Fried lotus seeds are another way of preparing this snack, where they are deep-fried for a crisp texture. They are sometimes used in Indian trail mixes."
            },
            {
                heading: "Lotus Seed Powder",
                about: "Lotus seed powder is made by grinding dried lotus seeds into a fine powder. It is used as a thickening agent in Indian sweets and desserts."
            },
            {
                heading: "Lotus Seed Curry",
                about: "Lotus seed curry, known as 'makhane ki sabzi,' is a popular North Indian dish. The seeds are cooked in a rich tomato and cream-based gravy, making it a flavorful vegetarian dish."
            },
            {
                heading: "Lotus Seed Kheer",
                about: "Lotus seed kheer is a creamy Indian dessert made by simmering lotus seeds in sweetened milk. It is a popular sweet dish, especially during festivals and celebrations."
            }
        ]
    }
]

const DryFruits = () => {
    const [activeProduct, setActiveProduct] = useState({})
    const [modal, setModal] = useState(false)

    const [open, setOpen] = useState('1')

    const toggleAccordion = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    }

    const toggle = () => setModal(!modal)

    useEffect(() => {
        if (!modal) {
            setTimeout(() => {
                setActiveProduct({})
            }, 500)
        }
    }, [modal])

    const handleProduct = (product) => {
        setActiveProduct(productDetails.filter(element => element.id === product)[0])
    }

    return (
        <Fragment>
            <Container>
                <Row className='py-5'>
                    <Col>
                        <h1 className='section-heading m-0 text-primary text-center'>Indian Dry Fruits</h1>
                    </Col>
                </Row>

                <Row className='pb-5'>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('almond')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={almond} alt="almond" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Almond</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('pistachio')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={pistachio} alt="pistachio" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Pistachio</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('cashew')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={cashew} alt="cashew" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Cashew</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('dry-fig')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={dryFig} alt="dry-fig" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Dry Fig</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('walnut')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={walnut} alt="walnut" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Walnut</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('kismis')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={kismis} alt="kismis" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Kismis</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('lotus-seed')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={lotusSeed} alt="lotus-seed" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Lotus Seed</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Modal isOpen={modal} toggle={toggle} size='xl' centered scrollable className='border-0 rounded-0'>
                    <ModalHeader toggle={toggle} className='text-primary'>{activeProduct.heading}</ModalHeader>
                    <ModalBody>

                        <Row>
                            <Col>
                                <h4 className='m-0 pt-2 text-primary'>About</h4>
                                <p className='text-primary'>{activeProduct.about}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h4 className='m-0 pt-2 text-primary'>Description</h4>
                                <p className='text-primary'>{activeProduct.description}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12}><h4 className='m-0 pt-2 text-primary'>Ingredients</h4></Col>
                            <Col lg={6}>
                                <p className='m-0 text-primary'>
                                    <b>Nutrients:</b> {activeProduct.ingredients?.nutrients.join(', ')}
                                </p>
                                <p className='m-0 text-primary'>
                                    <b>Minerals:</b> {activeProduct.ingredients?.minerals.join(', ')}
                                </p>
                                <p className='m-0 text-primary'>
                                    <b>Vitamins:</b> {activeProduct.ingredients?.vitamins.join(', ')}
                                </p>
                            </Col>
                            <Col lg={6}>
                                <p className='m-0 text-primary'>
                                    <b>Protein:</b> {activeProduct.ingredients?.protein}
                                </p>
                                <p className='m-0 text-primary'>
                                    <b>Fats:</b> {activeProduct.ingredients?.fats}
                                </p>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12}>
                                <h4 className='m-0 pt-2 text-primary'>Varieties</h4>
                            </Col>
                            <Accordion open={open} toggle={toggleAccordion}>
                                {
                                    activeProduct.varieties?.map((item, index) => {
                                        return (
                                            <AccordionItem className='my-3 shadow-lg'>
                                                <AccordionHeader targetId={index + 1}>{item.heading}</AccordionHeader>
                                                <AccordionBody accordionId={index + 1} className='text-primary'>
                                                    <FiCheck size={25} /> {item.about}
                                                </AccordionBody>
                                            </AccordionItem>
                                        )
                                    })
                                }
                            </Accordion>
                        </Row>

                    </ModalBody>
                </Modal>
            </Container>
        </Fragment>
    )
}

export default DryFruits