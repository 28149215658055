// React Imports
import React, { Fragment, useEffect, useState } from 'react'

// Third Party Imports
import { Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import { FiCheck } from "react-icons/fi"

// CSS Imports
import '../../../../styles/product/product.css'

// Images and Icon Imports
import turmeric from '../../../../assets/product-images/turmeric.jpg'
import cloves from '../../../../assets/product-images/cloves.jpg'
import bayLeaf from '../../../../assets/product-images/bay-leaf.jpg'
import chilli from '../../../../assets/product-images/chilli.jpg'
import peppercorns from '../../../../assets/product-images/peppercorns.jpg'
import cardamom from '../../../../assets/product-images/cardamom.jpg'
import corianderCumin from '../../../../assets/product-images/coriander-cumin.jpg'

const productDetails = [
    {
        id: "turmeric",
        heading: "Turmeric",
        aboutProduct: "Turmeric is not only a popular spice but also a symbol of Indian culture and tradition. It has been used for generations in Indian cooking and as a remedy for various ailments. Its vibrant color and unique flavor make it a staple in many Indian dishes, adding both taste and visual appeal. Additionally, its potential health benefits and versatility make it a valuable ingredient in both the kitchen and the medicine cabinet.",
        description: "Indian Turmeric, scientifically known as Curcuma longa, is a bright yellow spice that belongs to the ginger family, Zingiberaceae. It is renowned for its vibrant color and unique flavor. Turmeric is derived from the rhizomes (underground stems) of the Curcuma longa plant and is widely used in Indian cuisine and traditional medicine.",
        history: "Turmeric has a rich history that dates back thousands of years. It is believed to have originated in India and has been used for both culinary and medicinal purposes for centuries. Turmeric holds a special place in Ayurvedic medicine, where it is known for its healing properties.",
        specification: {
            productName: "Turmeric",
            origin: "India",
            family: "Zingiberaceae",
            binomialName: "Curcuma longa"
        },
        characteristics: {
            color: "Bright yellow",
            tasteAndSmell: "Turmeric has a warm, earthy flavor with a slightly bitter undertone. Its aroma is distinctive and slightly peppery.",
            quality: "The quality of turmeric can vary depending on factors like its color, aroma, and curcumin content. High-quality turmeric is bright yellow and has a strong aroma."
        },
        ingredients: {
            nutrients: ["Dietary fiber", "Potassium", "Vitamin C"],
            minerals: ["Iron", "Manganese"],
            vitamins: ["Vitamin B6"],
            protein: "Low",
            fats: "Low"
        },
        usageAndBenefits: [
            "Culinary Use: It is a key ingredient in Indian curries, giving them a vibrant yellow color and a distinct flavor.",
            "Medicinal Use: Turmeric is used in traditional Ayurvedic and herbal medicine for its anti-inflammatory and antioxidant properties. It is believed to have various health benefits, including reducing inflammation, improving digestion, and boosting immunity.",
            "Cosmetic Use: Turmeric is sometimes used in skincare products for its potential to brighten the skin and treat acne.",
            "Dye: It has been used as a natural dye for fabrics and food items due to its bright color."
        ]
    },
    {
        id: "cloves",
        heading: "Cloves",
        aboutProduct: "Indian cloves are a spice with a rich history and a strong, sweet flavor that adds depth to various dishes. They have played a significant role in Indian and global cuisines, from flavoring curries to spicing up desserts. Beyond their culinary uses, cloves have been treasured for their potential health benefits and have been used for centuries in traditional medicine. Their versatility in both the kitchen and as a natural remedy makes them a valuable addition to any spice collection.",
        description: "Indian cloves, scientifically known as Syzygium aromaticum, are the aromatic flower buds of a tree native to Indonesia and now cultivated in various parts of India. These small, dark-brown, nail-shaped spice buds are known for their strong and sweet flavor, as well as their numerous culinary and medicinal uses.",
        history: "Cloves have a long history of use in India and around the world. They were highly valued in ancient civilizations and were used in various traditional medicines and for preserving food. Cloves were one of the spices that drove exploration and trade during the Age of Discovery.",
        specification: {
            productName: "Cloves",
            origin: "Native to Indonesia, cultivated in India",
            family: "Myrtaceae",
            binomialName: "Syzygium aromaticum"
        },
        characteristics: {
            color: "Dark brown",
            tasteAndSmell: "Cloves have a strong, sweet, and spicy flavor with a warm aroma. They are intensely aromatic and pungent.",
            quality: "High-quality cloves are plump, oily, and have a strong fragrance. They should be free from mold and other contaminants."
        },
        ingredients: {
            nutrients: ["Dietary fiber", "Vitamin K", "Vitamin C"],
            minerals: ["Calcium", "Iron", "Magnesium"],
            vitamins: ["Vitamin K", "Vitamin C"],
            protein: "Moderate",
            fats: "Low"
        },
        usageAndBenefits: [
            "Culinary Use: Cloves are used in both sweet and savory dishes, imparting a unique and rich flavor. They are commonly used in Indian cuisine to spice up rice, curries, and desserts.",
            "Medicinal Use: Cloves are known for their potential health benefits, including anti-inflammatory, analgesic, and antioxidant properties. They are often used in traditional medicine to alleviate toothaches, aid digestion, and relieve respiratory issues.",
            "Aromatherapy: Clove essential oil is used in aromatherapy for its soothing and stimulating effects. It is believed to improve mental clarity and reduce stress.",
            "Preservation: Cloves have been used historically as a natural preservative due to their antimicrobial properties. They were used to preserve food and prevent spoilage."
        ]
    },
    {
        id: "bay-leaf",
        heading: "Bay Leaf",
        aboutProduct: "Indian bay leaves, or 'Tej Patta,' are a flavorful and aromatic spice that enhances the taste of Indian dishes. They have a rich history of culinary and medicinal use, adding a unique fragrance to a variety of recipes. Beyond their culinary role, bay leaves are valued for their potential health benefits and are used in traditional medicine. Their versatility in enhancing both the flavor of food and overall well-being makes them a valuable addition to any kitchen and home.",
        description: "Indian bay leaves, also known as 'Tej Patta' in Hindi, are aromatic leaves derived from the bay laurel tree, scientifically known as Laurus nobilis. These leaves are widely used in Indian cooking and are known for their distinctive flavor and fragrance.",
        history: "Bay leaves have a long history of culinary and medicinal use. They were used in ancient Greek and Roman cuisines and were also highly regarded in traditional Indian and Ayurvedic medicine for their potential health benefits.",
        specification: {
            productName: "Bay Leaf",
            origin: "India",
            family: "Lauraceae",
            binomialName: "Laurus nobilis"
        },
        characteristics: {
            color: "Dark green, dried leaves are brown",
            tasteAndSmell: "Bay leaves have a subtle, slightly floral flavor with hints of mint and a warm, aromatic fragrance.",
            quality: "High-quality bay leaves are whole, unbroken, and have a strong aroma. They should be free from signs of mold or moisture."
        },
        ingredients: {
            nutrients: ["Dietary fiber", "Vitamin A", "Vitamin C"],
            minerals: ["Calcium", "Iron", "Potassium"],
            vitamins: ["Vitamin A", "Vitamin C"],
            protein: "Low",
            fats: "Low"
        },
        usageAndBenefits: [
            "Culinary Use: Bay leaves are a staple in Indian cuisine, often used to flavor rice dishes, curries, and stews. They add depth and aroma to dishes without being eaten.",
            "Medicinal Use: Bay leaves are believed to have various potential health benefits, including aiding digestion, reducing inflammation, and promoting respiratory health. They have been used in traditional medicine for their therapeutic properties.",
            "Aromatherapy: Bay leaf essential oil is used in aromatherapy for its calming and soothing effects. It is known to relieve stress and anxiety.",
            "Preservation: Bay leaves are used as a natural insect repellent and are often placed in food storage containers to protect against pantry pests."
        ]
    },
    {
        id: "chilli",
        heading: "Chilli",
        aboutProduct: "Indian chilli peppers are renowned for their fiery heat and are an essential ingredient in Indian cooking. They have a rich history, having been introduced to India from the Americas, and they quickly became a staple in Indian kitchens. Beyond their culinary role, chillies are valued for their potential health benefits and are a symbol of the diverse and flavorful cuisine of India.",
        description: "Indian chilli, scientifically known as Capsicum annuum, is a spice widely used for its fiery and pungent flavor. Chilli peppers come in various shapes, sizes, and levels of spiciness, and they are a fundamental ingredient in Indian cuisine, adding heat and depth to dishes.",
        history: "Chilli peppers are native to the Americas but were introduced to India and other parts of the world during the Columbian Exchange. They quickly became popular in Indian cooking and have since played a significant role in the country's culinary culture.",
        specification: {
            productName: "Chilli",
            origin: "Introduced to India, native to the Americas",
            family: "Solanaceae",
            binomialName: "Capsicum annuum"
        },
        characteristics: {
            color: "Varies (red, green, yellow, etc.)",
            tasteAndSmell: "Chillies have a fiery, spicy taste that can range from mildly hot to extremely hot. They also have a distinct and pungent aroma.",
            quality: "High-quality chillies are firm, vibrant in color, and free from signs of mold or decay."
        },
        ingredients: {
            nutrients: ["Vitamin C", "Vitamin A", "Dietary fiber"],
            minerals: ["Potassium", "Iron"],
            vitamins: ["Vitamin C", "Vitamin A"],
            protein: "Low",
            fats: "Low"
        },
        usageAndBenefits: [
            "Culinary Use: Chilli peppers are a fundamental spice in Indian cuisine, used to add spiciness and flavor to a wide range of dishes, including curries, chutneys, and snacks.",
            "Medicinal Use: Chilli peppers contain capsaicin, which is believed to have various health benefits, such as pain relief, reducing inflammation, and aiding digestion. They are also known to boost metabolism.",
            "Spice Production: India is one of the largest producers and exporters of chilli peppers globally, contributing significantly to the spice industry.",
            "Cultural Significance: Chilli peppers are deeply ingrained in Indian culinary culture and are used to create diverse regional flavors and spice levels."
        ]
    },
    {
        id: "peppercorns",
        heading: "Peppercorns",
        aboutProduct: "Indian peppercorns are a spice with a rich history and a reputation for adding spice and flavor to countless dishes. They come in various colors, each with its distinct taste and heat level. Beyond their culinary role, peppercorns are valued for their potential health benefits and historical significance in global trade.",
        description: "Indian peppercorns, scientifically known as Piper nigrum, are small, round spice berries that are renowned for their pungent and spicy flavor. They are one of the world's most popular and widely used spices, valued for their ability to add a kick of heat to a wide variety of dishes.",
        history: "Peppercorns have a long and storied history, dating back thousands of years. They were one of the earliest spices to be traded internationally and played a significant role in the spice trade routes, including the famous Silk Road.",
        specification: {
            productName: "Peppercorns",
            origin: "India",
            family: "Piperaceae",
            binomialName: "Piper nigrum"
        },
        characteristics: {
            color: "Varies (black, white, green, red)",
            tasteAndSmell: "Peppercorns have a pungent, spicy taste with varying levels of heat, depending on the type (black, white, green, or red). They also have a strong, aromatic fragrance.",
            quality: "High-quality peppercorns are whole, aromatic, and free from moisture or signs of mold."
        },
        ingredients: {
            nutrients: ["Dietary fiber", "Vitamin K"],
            minerals: ["Iron", "Manganese"],
            vitamins: ["Vitamin K"],
            protein: "Low",
            fats: "Low"
        },
        usageAndBenefits: [
            "Culinary Use: Peppercorns are a staple in Indian and global cuisines, used to season and spice up a wide range of dishes, from soups and stews to marinades and sauces.",
            "Medicinal Use: Peppercorns are believed to have potential health benefits, including aiding digestion, promoting metabolism, and acting as an antioxidant. They are also used in traditional medicine for various remedies.",
            "Variety: India is known for producing different types of peppercorns, including black, white, green, and red varieties, each with its unique flavor and spiciness.",
            "Historical Significance: Peppercorns were once highly valuable and played a crucial role in the spice trade, often referred to as 'black gold.' They were used as currency and as a symbol of wealth and power."
        ]
    },
    {
        id: "cardamom",
        heading: "Cardamom",
        aboutProduct: "Indian cardamom, often referred to as the 'Queen of Spices,' is cherished for its sweet and aromatic flavor. It has a rich history of use in Indian cuisine and is known for its potential health benefits. Whether used in savory dishes or desserts, cardamom adds a unique and delightful taste to a wide range of Indian recipes.",
        description: "Indian cardamom, scientifically known as Elettaria cardamomum, is a highly aromatic spice known for its sweet and spicy flavor. It is often referred to as the 'Queen of Spices' and is widely used in Indian cuisine, beverages, and desserts.",
        history: "Cardamom has a long history of use in India and other parts of the world. It was traded along the ancient spice routes and was highly valued in ancient civilizations for its fragrance and flavor. It has been a key spice in Indian cooking for centuries.",
        specification: {
            productName: "Cardamom",
            origin: "India",
            family: "Zingiberaceae",
            binomialName: "Elettaria cardamomum"
        },
        characteristics: {
            color: "Green (also available in black and white varieties)",
            tasteAndSmell: "Cardamom has a sweet, slightly citrusy flavor with a hint of spice. It has a strong and pleasant aroma, making it a prized spice.",
            quality: "High-quality cardamom pods are plump, green (for green cardamom), and have a strong, aromatic fragrance. They should be free from mold or moisture."
        },
        ingredients: {
            nutrients: ["Dietary fiber", "Vitamin C"],
            minerals: ["Iron", "Magnesium", "Potassium"],
            vitamins: ["Vitamin C"],
            protein: "Low",
            fats: "Low"
        },
        usageAndBenefits: [
            "Culinary Use: Cardamom is a versatile spice used in both sweet and savory Indian dishes, including curries, rice dishes, and desserts. It is also a key ingredient in masala chai.",
            "Medicinal Use: Cardamom is known for its potential health benefits, including aiding digestion, freshening breath, and promoting oral health. It is also used in traditional medicine for various remedies.",
            "Varieties: Cardamom comes in different varieties, with green cardamom being the most common in Indian cuisine. Black and white cardamom varieties are also available, each with its unique flavor profile.",
            "Cultural Significance: Cardamom is a symbol of hospitality in India and is often used to flavor traditional Indian sweets and beverages offered to guests."
        ]
    },
    {
        id: "coriander-cumin",
        heading: "Coriander-Cumin",
        aboutProduct: "Indian Coriander-Cumin is a classic spice blend in Indian cooking. Its balanced flavor profile and versatility make it a staple in many Indian households. It serves as the foundational spice mix that forms the base for numerous Indian dishes, enhancing their flavor and aroma.",
        description: "Indian Coriander-Cumin, a blend of ground coriander seeds (Coriandrum sativum) and cumin seeds (Cuminum cyminum), is a fundamental spice mixture in Indian cuisine. It is known for its earthy, citrusy, and slightly nutty flavor, and it is used as a base spice in many Indian dishes.",
        history: "Coriander and cumin have been used in Indian cooking for centuries. They are indigenous to the Mediterranean region but have become integral to Indian cuisine. The blend of ground coriander and cumin is a common seasoning mixture in many Indian recipes.",
        specification: {
            productName: "Coriander-Cumin",
            origin: "Coriander - Mediterranean region, Cumin - Mediterranean region",
            family: "Coriander - Apiaceae, Cumin - Apiaceae",
            binomialName: "Coriander - Coriandrum sativum, Cumin - Cuminum cyminum"
        },
        characteristics: {
            color: "Light brown",
            tasteAndSmell: "Coriander-cumin blend has a balanced flavor profile with earthy, citrusy, and slightly nutty notes. It has a mild, pleasant aroma.",
            quality: "High-quality coriander-cumin should be freshly ground and free from any off-putting odors or flavors."
        },
        ingredients: {
            nutrients: ["Dietary fiber", "Vitamin C", "Vitamin K"],
            minerals: ["Calcium", "Iron", "Magnesium"],
            vitamins: ["Vitamin C", "Vitamin K"],
            protein: "Low",
            fats: "Low"
        },
        usageAndBenefits: [
            "Culinary Use: Coriander-cumin is a versatile spice blend used as a base in many Indian dishes, including curries, stews, and spice rubs. It adds depth and flavor to a wide range of recipes.",
            "Medicinal Use: Both coriander and cumin are known for their potential health benefits, including aiding digestion, reducing inflammation, and providing essential nutrients.",
            "Flavor Enhancer: The blend is used to enhance the overall flavor of various dishes and create a harmonious balance of taste and aroma.",
            "Cultural Significance: Coriander-cumin is an essential part of Indian cuisine and is used in regional variations of spice blends and curry powders."
        ]
    }
]

const Spices = () => {
    const [activeProduct, setActiveProduct] = useState({})
    const [activeTab, setActiveTab] = useState('1')
    const [modal, setModal] = useState(false)

    const toggle = () => setModal(!modal)

    useEffect(() => {
        if (!modal) {
            setTimeout(() => {
                setActiveProduct({})
            }, 500)
        }
    }, [modal])

    const handleProduct = (product) => {
        setActiveProduct(productDetails.filter(element => element.id === product)[0])
    }

    return (
        <Fragment>
            <Container>
                <Row className='py-5'>
                    <Col>
                        <h1 className='section-heading m-0 text-primary text-center'>Indian Spices</h1>
                    </Col>
                </Row>

                <Row className='pb-5'>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('turmeric')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={turmeric} alt="turmeric" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Turmeric</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('cloves')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={cloves} alt="cloves" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Cloves</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('bay-leaf')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={bayLeaf} alt="bayLeaf" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Bay Leaf</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('chilli')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={chilli} alt="chilli" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Chilli</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('peppercorns')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={peppercorns} alt="peppercorns" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Peppercorns</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('cardamom')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={cardamom} alt="cardamom" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Cardamom</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('coriander-cumin')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={corianderCumin} alt="coriander-cumin" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Coriander Cumin</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Modal isOpen={modal} toggle={toggle} size='xl' centered scrollable className='border-0 rounded-0'>
                    <ModalHeader toggle={toggle} className='text-primary'>{activeProduct.heading}</ModalHeader>
                    <ModalBody>

                        <Row>
                            <Col>
                                <h4 className='text-primary'>About</h4>
                                <p className='text-primary'>{activeProduct.aboutProduct}</p>
                            </Col>
                        </Row>

                        <Row className='py-5'>
                            <Nav tabs>
                                <Row className='gap-3'>
                                    <Col className='text-center'>
                                        <NavItem>
                                            <NavLink className={activeTab === '1' && 'active'} onClick={() => setActiveTab('1')}>Description</NavLink>
                                        </NavItem>
                                    </Col>
                                    <Col className='text-center'>
                                        <NavItem>
                                            <NavLink className={activeTab === '2' && 'active'} onClick={() => setActiveTab('2')}>History</NavLink>
                                        </NavItem>
                                    </Col>
                                    <Col className='text-center'>
                                        <NavItem>
                                            <NavLink className={activeTab === '3' && 'active'} onClick={() => setActiveTab('3')}>Specification</NavLink>
                                        </NavItem>
                                    </Col>
                                    <Col className='text-center'>
                                        <NavItem>
                                            <NavLink className={activeTab === '4' && 'active'} onClick={() => setActiveTab('4')}>Ingredients</NavLink>
                                        </NavItem>
                                    </Col>
                                    <Col className='text-center'>
                                        <NavItem>
                                            <NavLink className={activeTab === '5' && 'active'} onClick={() => setActiveTab('5')}>Characteristics</NavLink>
                                        </NavItem>
                                    </Col>
                                    <Col className='text-center'>
                                        <NavItem>
                                            <NavLink className={activeTab === '6' && 'active'} onClick={() => setActiveTab('6')}>Benefits</NavLink>
                                        </NavItem>
                                    </Col>
                                </Row>
                            </Nav>

                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col sm="12" className='pt-4'>
                                            <p className='text-primary'>
                                                {activeProduct.description}
                                            </p>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row className='pt-4'>
                                        <Col sm="12">
                                            <p className='text-primary'>
                                                {activeProduct.history}
                                            </p>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="3">
                                    <Row className='pt-4'>
                                        <Col sm="12">
                                            <p className='text-primary'>
                                                <b>Product Name:</b> {activeProduct.specification?.productName}
                                            </p>
                                            <p className='text-primary'>
                                                <b>Origin:</b> {activeProduct.specification?.origin}
                                            </p>
                                            <p className='text-primary'>
                                                <b>Family:</b> {activeProduct.specification?.family}
                                            </p>
                                            <p className='text-primary'>
                                                <b>Binomial Name:</b> {activeProduct.specification?.binomialName}
                                            </p>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="4">
                                    <Row className='pt-4'>
                                        <Col sm="12">
                                            <p className='text-primary'>
                                                <b>Nutrients:</b> {activeProduct.ingredients?.nutrients.join(', ')}
                                            </p>
                                            <p className='text-primary'>
                                                <b>Minerals:</b> {activeProduct.ingredients?.minerals.join(', ')}
                                            </p>
                                            <p className='text-primary'>
                                                <b>Vitamins:</b> {activeProduct.ingredients?.vitamins.join(', ')}
                                            </p>
                                            <p className='text-primary'>
                                                <b>Protein:</b> {activeProduct.ingredients?.protein}
                                            </p>
                                            <p className='text-primary'>
                                                <b>Fats:</b> {activeProduct.ingredients?.fats}
                                            </p>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="5">
                                    <Row className='pt-4'>
                                        <Col sm="12">
                                            <p className='text-primary'>
                                                <b>Color:</b> {activeProduct.characteristics?.color}
                                            </p>
                                            <p className='text-primary'>
                                                <b>Taste And Smell:</b> {activeProduct.characteristics?.tasteAndSmell}
                                            </p>
                                            <p className='text-primary'>
                                                <b>Quality:</b> {activeProduct.characteristics?.quality}
                                            </p>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="6">
                                    <Row className='pt-4'>
                                        <Col sm="12">


                                            {activeProduct?.usageAndBenefits?.map((item, index) => {
                                                return (
                                                    <p className='text-primary' key={index}>
                                                        <FiCheck size={25} /> <b>{item.split(':')[0]}: </b>{item.split(':')[1]}
                                                    </p>
                                                )
                                            })}
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Row>

                    </ModalBody>
                </Modal>
            </Container>
        </Fragment>
    )
}

export default Spices