// React Imports
import React, { Fragment } from 'react'

// Component Imports
import Footer from './Footer'

const FooterComponent = () => {
    return (
        <Fragment>
            <Footer />
        </Fragment>
    )
}

export default FooterComponent