// React Imports
import React, { Fragment } from 'react'

import WhoWeAre from './WhoWeAre'
// Component Imports

const WhoWeAreComponent = () => {
    return (
        <Fragment>
            <WhoWeAre />
        </Fragment>
    )
}

export default WhoWeAreComponent