// React Imports
import React, { Fragment } from 'react'

// Component Imports
import ComingSoon from './ComingSoon'

const ComingSoonComponent = () => {
    return (
        <Fragment>
            <ComingSoon />
        </Fragment>
    )
}

export default ComingSoonComponent