// React Imports
import React, { Fragment, useEffect, useState } from 'react'

// Third Party Imports
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { FiCheck } from "react-icons/fi"

// CSS Imports
import '../../../../styles/product/product.css'

// Images and Icon Imports
import soybean from '../../../../assets/product-images/soybean.jpg'
import masoorDal from '../../../../assets/product-images/masoor-dal.jpg'
import kidneyBeans from '../../../../assets/product-images/kidney-beans.jpg'
import splitChickpeas from '../../../../assets/product-images/split-chickpeas.jpg'
import brownLentils from '../../../../assets/product-images/brown-lentils.jpg'
import chickpeas from '../../../../assets/product-images/chickpeas.jpg'

const productDetails = [
    {
        id: "soybean",
        heading: "Soybean",
        about: "Soybeans, known as 'soya' or 'soyabean' in India, are a versatile legume that is widely grown and consumed. They are known for their high protein content and are used in various forms in Indian cuisine.",
        description: "Indian soybeans are used in different ways, including as whole beans, soybean oil, tofu, soy milk, and various soy-based products. They are a valuable source of plant-based protein and are used in both traditional and modern Indian cooking.",
        ingredients: {
            nutrients: ["Protein", "Dietary Fiber", "Iron"],
            minerals: ["Calcium", "Magnesium"],
            vitamins: ["Vitamin K", "Folate"],
            protein: "36.49 gm / 100gm",
            fats: "19.94 gm / 100gm"
        },
        varieties: [
            {
                heading: "Whole Soybeans",
                about: "Whole soybeans can be used in various Indian dishes, including curries and stews. They are also used to make sprouts, which are a nutritious addition to salads and sandwiches."
            },
            {
                heading: "Soybean Oil",
                about: "Soybean oil is a common cooking oil in India and is used for frying, sautéing, and as a base for many Indian dishes. It is also used in various commercial food products."
            },
            {
                heading: "Tofu (Soybean Paneer)",
                about: "Tofu, also known as soybean paneer, is a popular plant-based protein source used in vegetarian and vegan Indian recipes, including curries and stir-fries."
            },
            {
                heading: "Soy Milk",
                about: "Soy milk is a dairy-free milk alternative made from ground soybeans. It is used in vegan and lactose-free Indian recipes and as a base for drinks like soy chai and soy lassi."
            },
            {
                heading: "Soy Chunks (Textured Vegetable Protein - TVP)",
                about: "Soy chunks, also known as textured vegetable protein (TVP), are dehydrated soybean pieces used as a meat substitute in Indian dishes like soy keema, soy biryani, and soy curry."
            }
        ]
    },
    {
        id: "masoor-dal",
        heading: "Masoor Dal",
        about: "Masoor dal, also known as red lentils, is a popular legume used in Indian cuisine. It is known for its quick cooking time and high protein content.",
        description: "Indian masoor dal is a staple ingredient in many Indian households and is used in various dishes, including soups, curries, stews, and side dishes. It has a mild, earthy flavor and a reddish-orange color when split and hulled.",
        ingredients: {
            nutrients: ["Protein", "Dietary Fiber", "Folate"],
            minerals: ["Iron", "Potassium"],
            vitamins: ["Vitamin B1 (Thiamine)", "Vitamin B6"],
            protein: "25.8 gm / 100gm",
            fats: "1.06 gm / 100gm"
        },
        varieties: [
            {
                heading: "Whole Masoor Dal",
                about: "Whole masoor dal has the outer skin intact and is often used in traditional Indian recipes like khichdi and salads. It has a slightly longer cooking time compared to split masoor dal."
            },
            {
                heading: "Split Masoor Dal",
                about: "Split masoor dal, also known as red lentil dal, is the most common variety. It cooks quickly and is used in various Indian dishes, including dals, soups, and curries."
            },
            {
                heading: "Black Masoor Dal",
                about: "Black masoor dal, also known as beluga lentils, is a smaller and darker variety. It is used in some regional Indian cuisines and provides a unique texture and flavor to dishes."
            },
            {
                heading: "Organic Masoor Dal",
                about: "Organic masoor dal is produced without synthetic pesticides or fertilizers. It is chosen by individuals looking for a more environmentally friendly and natural option."
            },
            {
                heading: "Masoor Dal Flour",
                about: "Masoor dal flour is made by grinding masoor dal into a fine powder. It is used in gluten-free cooking, including making Indian flatbreads like masoor dal roti."
            }
        ]
    },
    {
        id: "kidney-beans",
        heading: "Kidney Beans",
        about: "Kidney beans, known as 'rajma' in India, are a popular legume used in Indian cuisine. They are named for their distinctive kidney shape and are known for their rich, nutty flavor.",
        description: "Indian kidney beans are a versatile ingredient used in various Indian dishes, including curries, stews, salads, and even as a filling for wraps and burritos. They are a good source of plant-based protein and fiber.",
        ingredients: {
            nutrients: ["Protein", "Dietary Fiber", "Folate"],
            minerals: ["Iron", "Potassium"],
            vitamins: ["Vitamin B1 (Thiamine)", "Vitamin B6"],
            protein: "24.56 gm / 100gm",
            fats: "0.83 gm / 100gm"
        },
        varieties: [
            {
                heading: "Red Kidney Beans",
                about: "Red kidney beans are the most common variety used in Indian cuisine. They have a deep red color and a robust flavor. They are used in dishes like rajma masala."
            },
            {
                heading: "White Kidney Beans (Cannellini Beans)",
                about: "White kidney beans, also known as cannellini beans, are creamy white and have a milder flavor compared to red kidney beans. They are used in salads and soups."
            },
            {
                heading: "Black Kidney Beans",
                about: "Black kidney beans are a darker variety with a unique appearance. They are used in some Indian recipes and provide a distinctive color and texture."
            },
            {
                heading: "Organic Kidney Beans",
                about: "Organic kidney beans are produced without synthetic pesticides or fertilizers. They are chosen by individuals looking for a more environmentally friendly and natural option."
            },
            {
                heading: "Kidney Bean Flour",
                about: "Kidney bean flour is made by grinding kidney beans into a fine powder. It is used as a gluten-free alternative in baking and cooking, including making bean-based bread and pancakes."
            }
        ]
    },
    {
        id: "split-chickpeas",
        heading: "Split Chickpeas (Chana Dal)",
        about: "Split chickpeas, known as 'chana dal' in India, are a type of lentil made by splitting and removing the outer layer of chickpeas. They are a popular legume used in Indian cuisine.",
        description: "Indian split chickpeas are a versatile ingredient used in various Indian dishes, including dals, curries, snacks, and sweets. They have a slightly nutty flavor and cook relatively quickly.",
        ingredients: {
            nutrients: ["Protein", "Dietary Fiber", "Folate"],
            minerals: ["Iron", "Magnesium"],
            vitamins: ["Vitamin B6", "Vitamin C"],
            protein: "25.3 gm / 100gm",
            fats: "4.27 gm / 100gm"
        },
        varieties: [
            {
                heading: "Yellow Split Chickpeas",
                about: "Yellow split chickpeas are the most common variety used in Indian cuisine. They have a bright yellow color and are used in dishes like chana dal curry and snacks like pakoras."
            },
            {
                heading: "Green Split Chickpeas",
                about: "Green split chickpeas, also known as hara chana dal, are made from young chickpeas. They have a greenish color and are used in Indian recipes, including snacks and soups."
            },
            {
                heading: "Roasted Split Chickpeas",
                about: "Roasted split chickpeas are a crunchy snack often flavored with spices and herbs. They are commonly enjoyed as a nutritious and flavorful snack in India."
            },
            {
                heading: "Organic Split Chickpeas",
                about: "Organic split chickpeas are produced without synthetic pesticides or fertilizers. They are chosen by individuals looking for a more environmentally friendly and natural option."
            },
            {
                heading: "Chana Dal Flour",
                about: "Chana dal flour is made by grinding split chickpeas into a fine powder. It is used in gluten-free cooking and baking, including making snacks and flatbreads."
            }
        ]
    },
    {
        id: "brown-lentils",
        heading: "Brown Lentils",
        about: "Brown lentils are a type of lentil known for their earthy flavor and ability to hold their shape when cooked. They are widely used in Indian cuisine and are a great source of plant-based protein.",
        description: "Indian brown lentils are versatile and used in various dishes, including dals, soups, stews, and salads. They have a mild, nutty taste and are commonly enjoyed as a nutritious and affordable protein source.",
        ingredients: {
            nutrients: ["Protein", "Dietary Fiber", "Folate"],
            minerals: ["Iron", "Potassium"],
            vitamins: ["Vitamin B1 (Thiamine)", "Vitamin B6"],
            protein: "25.8 gm / 100gm",
            fats: "1.06 gm / 100gm"
        },
        varieties: [
            {
                heading: "Whole Brown Lentils",
                about: "Whole brown lentils are the most common variety and are used in various Indian recipes. They retain their shape and texture well when cooked."
            },
            {
                heading: "Split Brown Lentils (Masoor Dal)",
                about: "Split brown lentils, also known as masoor dal, are made by splitting whole brown lentils. They cook faster and are commonly used in Indian dals, curries, and soups."
            },
            {
                heading: "Organic Brown Lentils",
                about: "Organic brown lentils are produced without synthetic pesticides or fertilizers. They are chosen by individuals looking for a more environmentally friendly and natural option."
            },
            {
                heading: "Brown Lentil Flour",
                about: "Brown lentil flour is made by grinding brown lentils into a fine powder. It is used in gluten-free cooking and baking, including making flatbreads and savory snacks."
            },
            {
                heading: "Brown Lentil Soup Mix",
                about: "Brown lentil soup mix typically includes a blend of lentils, spices, and herbs. It is used to make hearty lentil soups and stews, often enjoyed in Indian cuisine."
            }
        ]
    },
    {
        id: "chickpeas",
        heading: "Chickpeas (Chana/Garbanzo Beans)",
        about: "Chickpeas, known as 'chana' in India, are one of the most versatile and widely used legumes in Indian cuisine. They are appreciated for their nutty flavor and high protein content.",
        description: "Indian chickpeas are used in various forms, including whole chickpeas, chickpea flour (besan), and split chickpeas (chana dal). They are used in a wide range of dishes, from curries and snacks to sweets.",
        ingredients: {
            nutrients: ["Protein", "Dietary Fiber", "Folate"],
            minerals: ["Iron", "Potassium"],
            vitamins: ["Vitamin B6", "Vitamin C"],
            protein: "19.3 gm / 100gm",
            fats: "6 gm / 100gm"
        },
        varieties: [
            {
                heading: "Whole Chickpeas",
                about: "Whole chickpeas are the most common variety and are used in dishes like chana masala, chole, and hummus. They have a nutty flavor and a firm texture when cooked."
            },
            {
                heading: "Split Chickpeas (Chana Dal)",
                about: "Split chickpeas, also known as chana dal, are made by splitting whole chickpeas. They are commonly used in Indian dals, snacks, and desserts like besan ladoo."
            },
            {
                heading: "Chickpea Flour (Besan)",
                about: "Chickpea flour, known as besan, is a versatile ingredient used in making Indian snacks, sweets, and savory dishes. It's a gluten-free alternative in various recipes."
            },
            {
                heading: "Roasted Chickpeas",
                about: "Roasted chickpeas are a popular and healthy snack option. They are seasoned with various spices and herbs and enjoyed as a crunchy snack in India."
            },
            {
                heading: "Organic Chickpeas",
                about: "Organic chickpeas are produced without synthetic pesticides or fertilizers. They are chosen by individuals looking for a more environmentally friendly and natural option."
            }
        ]
    }
]

const Pulses = () => {
    const [activeProduct, setActiveProduct] = useState({})
    const [modal, setModal] = useState(false)

    const [open, setOpen] = useState('1')

    const toggleAccordion = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    }

    const toggle = () => setModal(!modal)

    useEffect(() => {
        if (!modal) {
            setTimeout(() => {
                setActiveProduct({})
            }, 500)
        }
    }, [modal])

    const handleProduct = (product) => {
        setActiveProduct(productDetails.filter(element => element.id === product)[0])
    }

    return (
        <Fragment>
            <Container>
                <Row className='py-5'>
                    <Col>
                        <h1 className='section-heading m-0 text-primary text-center'>Indian Pulses</h1>
                    </Col>
                </Row>

                <Row className='pb-5'>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('soybean')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={soybean} alt="soybean" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Soybean</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('masoor-dal')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={masoorDal} alt="masoor-dal" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Masoor Dal</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('kidney-beans')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={kidneyBeans} alt="kidney-beans" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Kidney Beans</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('split-chickpeas')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={splitChickpeas} alt="split-chickpeas" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Split Chickpeas</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('brown-lentils')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={brownLentils} alt="brown-lentils" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Brown Lentils</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('chickpeas')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={chickpeas} alt="chickpeas" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Chickpeas</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Modal isOpen={modal} toggle={toggle} size='xl' centered scrollable className='border-0 rounded-0'>
                    <ModalHeader toggle={toggle} className='text-primary'>{activeProduct.heading}</ModalHeader>
                    <ModalBody>

                        <Row>
                            <Col>
                                <h4 className='m-0 pt-2 text-primary'>About</h4>
                                <p className='text-primary'>{activeProduct.about}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h4 className='m-0 pt-2 text-primary'>Description</h4>
                                <p className='text-primary'>{activeProduct.description}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12}><h4 className='m-0 pt-2 text-primary'>Ingredients</h4></Col>
                            <Col lg={6}>
                                <p className='m-0 text-primary'>
                                    <b>Nutrients:</b> {activeProduct.ingredients?.nutrients.join(', ')}
                                </p>
                                <p className='m-0 text-primary'>
                                    <b>Minerals:</b> {activeProduct.ingredients?.minerals.join(', ')}
                                </p>
                                <p className='m-0 text-primary'>
                                    <b>Vitamins:</b> {activeProduct.ingredients?.vitamins.join(', ')}
                                </p>
                            </Col>
                            <Col lg={6}>
                                <p className='m-0 text-primary'>
                                    <b>Protein:</b> {activeProduct.ingredients?.protein}
                                </p>
                                <p className='m-0 text-primary'>
                                    <b>Fats:</b> {activeProduct.ingredients?.fats}
                                </p>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12}>
                                <h4 className='m-0 pt-2 text-primary'>Varieties</h4>
                            </Col>
                            <Accordion open={open} toggle={toggleAccordion}>
                                {
                                    activeProduct.varieties?.map((item, index) => {
                                        return (
                                            <AccordionItem className='my-3 shadow-lg'>
                                                <AccordionHeader targetId={index + 1}>{item.heading}</AccordionHeader>
                                                <AccordionBody accordionId={index + 1} className='text-primary'>
                                                    <FiCheck size={25} /> {item.about}
                                                </AccordionBody>
                                            </AccordionItem>
                                        )
                                    })
                                }
                            </Accordion>
                        </Row>

                    </ModalBody>
                </Modal>
            </Container>
        </Fragment>
    )
}

export default Pulses