// React Imports
import React, { Fragment } from 'react'

// Third Party Imports
import { Outlet } from 'react-router'

// Component Imports
import HeaderComponent from '../Header'
import FooterTopbarComponent from '../Footer-Topbar'
import FooterComponent from '../Footer'

const Wrapper = () => {
    return (
        <Fragment>
            <div className='position-sticky top-0 z-3'>
                <HeaderComponent />
            </div>
            <Outlet />
            <FooterTopbarComponent />
            <FooterComponent />
        </Fragment>
    )
}

export default Wrapper