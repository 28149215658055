// React Imports
import React, { Fragment } from 'react'

// Third Party Imports
import { Col, Container, Row } from 'reactstrap'

// CSS Imports
import '../../../styles/footer/footer.css'

const Footer = () => {
    return (
        <Fragment>
            <Container>
                <Row className='py-4 align-items-center'>
                    <Col md={6}>
                        <small className='copyright m-0 text-primary d-flex justify-content-center justify-content-md-start gap-2'>
                            <span>&copy; Copyright</span>
                            <b>Italiya Exports.</b>
                            <span>All Rights Reserved</span>
                        </small>
                    </Col>
                    <Col md={6}>
                        <p className='designed-by m-0 text-primary d-flex justify-content-center justify-content-lg-end gap-2'>
                            <span>Designed By: </span>
                            <a href="https://www.linkedin.com/in/krushit-sachapara-7b1292233?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BKf96Oq%2FURByGhf0%2Bd1LE7A%3D%3D" target='_blank' rel="noreferrer" className='fw-bold'>Krushit Sachapara</a>
                        </p>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default Footer