// React Imports
import React, { Fragment } from 'react'

// Third Party Imports
import { Col, Container, Row } from 'reactstrap'
import { FiArrowRight } from 'react-icons/fi'

// CSS Imports
import '../../../styles/about-company/about-company.css'

// Images & Logo Imports
import earth from '../../../assets/icons/planet-earth.png'

const AboutCompany = () => {
    return (
        <Fragment>
            <Container className='py-5'>
                <Row className='py-5'>
                    <Col sm={12} className='d-flex justify-content-center pb-5'>
                        <div className='earth-icon'>
                            <img src={earth} alt="" />
                        </div>
                    </Col>
                    <Col sm={12}>
                        <h2 className='section-heading m-0 text-primary text-center'>
                            Your Premier Source for Exquisite Exports
                        </h2>
                    </Col>
                    <Col className='pt-5'>
                        <p className='about-comapny-content text-primary'>
                            <FiArrowRight size={20} /> Italiya Exports is your trusted partner for sourcing the finest products from India's agricultural bounty.
                        </p>
                        <p className='about-comapny-content text-primary'>
                            <FiArrowRight size={20} /> Explore a wide variety of offerings, including fresh fruits, vegetables, aromatic spices, wholesome flours, and nutrient-rich dry fruits, all reflecting India's rich culinary heritage.
                        </p>
                        <p className='about-comapny-content text-primary'>
                            <FiArrowRight size={20} /> We are committed to maintaining the highest quality standards while prioritizing sustainability and ethical sourcing practices, ensuring authenticity and freshness in every product we offer. Experience the taste of India with Italiya Exports and savor the essence of this remarkable country in every bite and meal.
                        </p>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default AboutCompany