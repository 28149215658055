// React Imports
import React, { Fragment } from 'react'

// Component Imports
import BannerComponent from '../../view/Banner'
import AboutCompanyComponent from '../../view/About-Company'
import BestOfUsComponent from '../../view/Best-Of-Us'
import QuestionAndAnswerComponent from '../../view/Question-And-Answer'
import WhoWeAreComponent from '../../view/Who-We-Are'

const HomePage = () => {
    return (
        <Fragment>
            <BannerComponent />
            <AboutCompanyComponent />
            <BestOfUsComponent />
            <WhoWeAreComponent />
            <QuestionAndAnswerComponent />
        </Fragment>
    )
}

export default HomePage