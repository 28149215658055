// React Imports
import React, { Fragment } from 'react'

// Third Party Imports
import { Col, Container, Row } from 'reactstrap'

// CSS Imports
import '../../../styles/coming-soon/coming-soon.css'

const ComingSoon = () => {
    return (
        <Fragment>
            <Container>
                <Row>
                    <Col className='coming-soon d-flex flex-column justify-content-center'>
                        <h1 className='text-primary fw-bolder text-center'>Coming Soon</h1>
                        <h4 className='text-primary fw-bolder text-center'>Stay Tuned!</h4>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default ComingSoon