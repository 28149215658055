// React Imports
import React, { Fragment, useEffect, useState } from 'react'

// Third Party Imports
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { FiCheck } from "react-icons/fi"

// CSS Imports
import '../../../../styles/product/product.css'

// Images and Icon Imports
import tea from '../../../../assets/product-images/tea.jpg'
import coffee from '../../../../assets/product-images/coffee.jpg'

const productDetails = [
    {
        id: "tea",
        heading: "Tea",
        about: "Tea is one of the most widely consumed beverages in the world, known for its diverse flavors and soothing properties. It is made from the leaves of the Camellia sinensis plant and can be prepared in various ways.",
        description: "In India, tea is a beloved beverage that is deeply ingrained in the culture. It is enjoyed throughout the country in various forms, including masala chai, green tea, black tea, and herbal infusions.",
        ingredients: {
            flavors: "Varies by type and preparation",
            caffeine: "Varies by type and brewing time",
            nutrients: ["Antioxidants", "Flavonoids"],
            calories: "2 calories per 8-ounce cup (unsweetened)"
        },
        varieties: [
            {
                heading: "Masala Chai",
                about: "Masala chai is a popular Indian tea made with a blend of black tea leaves and a mix of aromatic spices such as cardamom, cinnamon, cloves, and ginger. It is typically brewed with milk and sweetened with sugar."
            },
            {
                heading: "Black Tea",
                about: "Black tea is one of the most common types of tea in India. It is fully oxidized, resulting in a robust flavor and dark color. It can be served with or without milk and sugar."
            },
            {
                heading: "Green Tea",
                about: "Green tea is known for its health benefits and is prepared from unoxidized tea leaves. It has a lighter taste and color compared to black tea and is often consumed without milk."
            },
            {
                heading: "Herbal Infusions",
                about: "Herbal infusions are caffeine-free teas made from a variety of herbs, flowers, and spices. Common herbal teas in India include peppermint tea, tulsi tea, and hibiscus tea."
            },
            {
                heading: "Kashmiri Kahwa",
                about: "Kashmiri kahwa is a traditional tea from the Kashmir region. It is prepared with green tea leaves, saffron, cardamom, and almonds, creating a fragrant and nourishing beverage."
            }
        ]
    },
    {
        id: "coffee",
        heading: "Coffee",
        about: "Coffee is one of the most popular beverages in the world, known for its rich, aromatic flavor and stimulating effects. It is made from the roasted seeds of coffee plants and comes in various forms and brews.",
        description: "In India, coffee is enjoyed in many forms, with the most common being filter coffee and instant coffee. It is a beloved beverage that is a part of daily life for many people across the country.",
        ingredients: {
            flavor: "Rich, Earthy, Aromatic",
            caffeine: "Varies by type and preparation",
            nutrients: ["Antioxidants"],
            calories: "2 calories per 8-ounce cup (black)"
        },
        varieties: [
            {
                heading: "Filter Coffee",
                about: "Filter coffee, also known as 'South Indian filter coffee,' is a popular coffee preparation in Southern India. It is made by brewing a mixture of dark roasted coffee grounds and chicory, and then serving it with milk and sugar."
            },
            {
                heading: "Instant Coffee",
                about: "Instant coffee is a convenient form of coffee that can be prepared quickly by mixing the coffee powder with hot water. It is commonly used to make a quick cup of coffee and is available in various brands and flavors."
            },
            {
                heading: "Espresso",
                about: "Espresso is a concentrated coffee brewed by forcing hot water through finely-ground coffee beans. It is the base for various coffee beverages like cappuccinos, lattes, and Americanos."
            },
            {
                heading: "Cold Brew",
                about: "Cold brew coffee is made by steeping coarsely-ground coffee beans in cold water for an extended period. It results in a smooth, less acidic coffee that is often served over ice."
            },
            {
                heading: "Indian Spiced Coffee",
                about: "Indian spiced coffee is prepared by adding traditional spices like cardamom, cloves, and cinnamon to coffee. It offers a unique and aromatic flavor and is a favorite during festivals and special occasions."
            }
        ]
    }
]

const Beverages = () => {
    const [activeProduct, setActiveProduct] = useState({})
    const [modal, setModal] = useState(false)

    const [open, setOpen] = useState('1')

    const toggleAccordion = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    }

    const toggle = () => setModal(!modal)

    useEffect(() => {
        if (!modal) {
            setTimeout(() => {
                setActiveProduct({})
            }, 500)
        }
    }, [modal])

    const handleProduct = (product) => {
        setActiveProduct(productDetails.filter(element => element.id === product)[0])
    }

    return (
        <Fragment>
            <Container>
                <Row className='py-5'>
                    <Col>
                        <h1 className='section-heading m-0 text-primary text-center'>Indian Beverages</h1>
                    </Col>
                </Row>

                <Row className='pb-5'>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('tea')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={tea} alt="tea" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Tea</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('coffee')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={coffee} alt="coffee" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Coffee</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Modal isOpen={modal} toggle={toggle} size='xl' centered scrollable className='border-0 rounded-0'>
                    <ModalHeader toggle={toggle} className='text-primary'>{activeProduct.heading}</ModalHeader>
                    <ModalBody>

                        <Row>
                            <Col>
                                <h4 className='m-0 pt-2 text-primary'>About</h4>
                                <p className='text-primary'>{activeProduct.about}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h4 className='m-0 pt-2 text-primary'>Description</h4>
                                <p className='text-primary'>{activeProduct.description}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12}><h4 className='m-0 pt-2 text-primary'>Ingredients</h4></Col>
                            <Col lg={6}>
                                <p className='m-0 text-primary'>
                                    <b>Flavors:</b> {activeProduct.ingredients?.flavors}
                                </p>
                                <p className='m-0 text-primary'>
                                    <b>Caffeine:</b> {activeProduct.ingredients?.caffeine}
                                </p>
                                <p className='m-0 text-primary'>
                                    <b>Nutrients:</b> {activeProduct.ingredients?.nutrients.join(', ')}
                                </p>
                            </Col>
                            <Col lg={6}>
                                <p className='m-0 text-primary'>
                                    <b>Calories:</b> {activeProduct.ingredients?.calories}
                                </p>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12}>
                                <h4 className='m-0 pt-2 text-primary'>Varieties</h4>
                            </Col>
                            <Accordion open={open} toggle={toggleAccordion}>
                                {
                                    activeProduct.varieties?.map((item, index) => {
                                        return (
                                            <AccordionItem className='my-3 shadow-lg'>
                                                <AccordionHeader targetId={index + 1}>{item.heading}</AccordionHeader>
                                                <AccordionBody accordionId={index + 1} className='text-primary'>
                                                    <FiCheck size={25} /> {item.about}
                                                </AccordionBody>
                                            </AccordionItem>
                                        )
                                    })
                                }
                            </Accordion>
                        </Row>

                    </ModalBody>
                </Modal>
            </Container>
        </Fragment>
    )
}

export default Beverages