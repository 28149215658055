// React Imports
import React, { Fragment } from 'react'

// Third Party Imports
import { Card, CardBody, CardText, CardTitle, Col, Container, Nav, NavItem, Row } from 'reactstrap'
import { FiChevronRight, FiFacebook, FiInstagram, FiLinkedin, FiMail, FiMapPin, FiPhone, FiTwitter } from "react-icons/fi"
import { Link } from 'react-router-dom'

import '../../../styles/footer-topbar/footer-topbar.css'

const FooterTopbar = () => {
    return (
        <Fragment>
            <Container fluid className='footer-topbar-container'>
                <Container className='py-5'>
                    <Row>
                        <Col lg={3} md={6}>
                            <Card className='border-0 bg-transparent'>
                                <CardBody>
                                    <CardTitle tag="h3" className='pb-3 text-primary'>
                                        Italiya Exports
                                    </CardTitle>
                                    <CardText>
                                        <p className='text-primary m-0'>
                                            <FiMapPin size={20} /> A/1, GF, Vedant Texo, Aopp. Eva Embrd, Kosad, Surat, Gujarat, India, 394107</p>
                                        <p className='text-primary m-0 py-1'><FiMail size={20} /> <a href="mailto:italiyaexport39@gmail.com"> italiyaexport39@gmail.com</a></p>
                                        <p className='text-primary d-flex align-items-center'><FiPhone size={20} />&nbsp;<a href="tel:+919687836272"> +91 9687836272</a></p>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={3} md={6}>
                            <Card className='border-0 bg-transparent'>
                                <CardBody>
                                    <CardTitle tag="h3" className='pb-3 text-primary'>
                                        Know More
                                    </CardTitle>
                                    <CardText>
                                        <Nav>
                                            <NavItem>
                                                <Link to="/" className='ps-0 d-flex align-items-center nav-link'><FiChevronRight size={20} /> Home</Link>
                                                <Link to="/blogs" className='ps-0 d-flex align-items-center nav-link'><FiChevronRight size={20} /> Blogs</Link>
                                                <Link to="/certificates" className='ps-0 d-flex align-items-center nav-link'><FiChevronRight size={20} /> Certificates</Link>
                                                <Link to="/about-us" className='ps-0 d-flex align-items-center nav-link'><FiChevronRight size={20} /> About Us</Link>
                                                <Link to="/contact-us" className='ps-0 d-flex align-items-center nav-link'><FiChevronRight size={20} /> Contact Us</Link>
                                            </NavItem>
                                        </Nav>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={3} md={6}>
                            <Card className='border-0 bg-transparent'>
                                <CardBody>
                                    <CardTitle tag="h3" className='pb-3 text-primary'>
                                        Our Products
                                    </CardTitle>
                                    <CardText>
                                        <Nav>
                                            <NavItem>
                                                <Link to="/product/fruits" className='ps-0 d-flex align-items-center nav-link'><FiChevronRight size={20} /> Fruits</Link>
                                                <Link to="/product/vegetables" className='ps-0 d-flex align-items-center nav-link'><FiChevronRight size={20} /> Vegetables</Link>
                                                <Link to="/product/dry-fruits" className='ps-0 d-flex align-items-center nav-link'><FiChevronRight size={20} /> Dry Fruits</Link>
                                                <Link to="/product/beverages" className='ps-0 d-flex align-items-center nav-link'><FiChevronRight size={20} /> Beverages</Link>
                                                <Link to="/product/spices" className='ps-0 d-flex align-items-center nav-link'><FiChevronRight size={20} /> Spices</Link>
                                                <Link to="/product/pulses" className='ps-0 d-flex align-items-center nav-link'><FiChevronRight size={20} /> Pulses</Link>
                                            </NavItem>
                                        </Nav>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={3} md={6}>
                            <Card className='border-0 bg-transparent'>
                                <CardBody>
                                    <CardTitle tag="h3" className='pb-3 text-primary'>
                                        More About Us
                                    </CardTitle>
                                    <CardText>
                                        <p className='text-primary'>Visit our social media platforms.</p>
                                        <span className='d-flex justify-content-between'>
                                            <div className='socil-icons d-flex justify-content-center align-items-center p-2 rounded-circle'>
                                                <a href="https://www.facebook.com/profile.php?id=61552350008533&is_tour_dismissed=true" target="_blank" rel="noopener noreferrer">
                                                    <FiFacebook size={25} role='button' className='text-primary' />
                                                </a>
                                            </div>
                                            <div className='socil-icons d-flex justify-content-center align-items-center p-2 rounded-circle'>
                                                <a href="https://instagram.com/italiya__export____?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noopener noreferrer">
                                                    <FiInstagram size={25} role='button' className='text-primary' />
                                                </a>
                                            </div>
                                            <div className='socil-icons d-flex justify-content-center align-items-center p-2 rounded-circle'>
                                                <a href="https://twitter.com/ItaliyaExport" target="_blank" rel="noopener noreferrer">
                                                    <FiTwitter size={25} role='button' className='text-primary' />
                                                </a>
                                            </div>
                                            <div className='socil-icons d-flex justify-content-center align-items-center p-2 rounded-circle'>
                                                <a href="https://linkedin.com/in/italiya-export-210790296" target="_blank" rel="noopener noreferrer">
                                                    <FiLinkedin size={25} role='button' className='text-primary' />
                                                </a>
                                            </div>
                                        </span>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Fragment>
    )
}

export default FooterTopbar