// React Imports
import React, { Fragment } from 'react'

// Component Imports
import AboutUsComponent from '../../view/About-Us'
import PortfolioComponent from '../../view/Portfolio'

const AboutUsPage = () => {
    return (
        <Fragment>
            <AboutUsComponent />
            <PortfolioComponent />
        </Fragment>
    )
}

export default AboutUsPage