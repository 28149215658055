// React Imports
import React, { Fragment, useEffect, useState } from 'react'

// Third Party Imports
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { FiCheck } from "react-icons/fi"

// CSS Imports
import '../../../../styles/product/product.css'

// Images and Icon Imports
import coconut from '../../../../assets/product-images/coconut.jpg'
import mango from '../../../../assets/product-images/mango.jpg'
import orange from '../../../../assets/product-images/orange.jpg'
import grapes from '../../../../assets/product-images/grapes.jpg'
import pomegranate from '../../../../assets/product-images/pomegranate.jpg'

const productDetails = [
    {
        id: 'coconut',
        heading: 'Coconut',
        about: 'Indian coconuts are a popular tropical fruit widely cultivated and consumed in India.',
        description: 'Indian coconuts are known for their sweet, refreshing water and creamy white flesh. They are a versatile ingredient used in various Indian dishes and desserts.',
        ingredients: {
            nutrients: ['Vitamin C', 'Dietary Fiber'],
            minerals: ['Potassium', 'Manganese'],
            vitamins: ['Vitamin B6'],
            protein: '3.33 gm / 100gm',
            fats: '33.49 gm / 100gm'
        },
        varieties: [
            {
                heading: 'Nadan Coconut',
                about: 'Nadan coconuts are large-sized coconuts known for their sweet water and rich, creamy flesh. They are commonly used in South Indian cuisine for making coconut milk and oil. Nadan coconuts are also used in religious rituals and ceremonies in Kerala.'
            },
            {
                heading: 'Dwarf Coconut',
                about: 'Dwarf coconuts are smaller in size and are prized for their tender, jelly-like flesh and sweeter water. They are often enjoyed as a refreshing snack. These coconuts are ideal for small gardens and home cultivation due to their compact size.'
            },
            {
                heading: 'Tall Coconut',
                about: 'Tall coconuts are the most common variety in India and are used for a wide range of purposes, including coconut oil extraction, coconut water production, and culinary uses. They have a larger yield of coconuts per tree and are a staple in Indian households.'
            },
            {
                heading: 'Green Coconut',
                about: 'Green coconuts are harvested before they fully mature, and their water is consumed as a refreshing drink. The soft, gel-like flesh inside is also edible and is a popular ingredient in salads and beverages.'
            },
            {
                heading: 'Brown Coconut',
                about: 'Brown coconuts are mature coconuts with hard shells. They are used for extracting coconut meat, grating, and drying to make desiccated coconut. The oil extracted from brown coconuts is widely used in cooking and skincare products.'
            }
        ]
    },
    {
        id: "mango",
        heading: "Mango",
        about: "Indian mangoes are a beloved tropical fruit renowned for their sweet, juicy flesh and vibrant flavors. They are widely cultivated and cherished across India.",
        description: "Indian mangoes come in a variety of cultivars, each with its unique taste and texture. They are a versatile fruit used in numerous Indian dishes, desserts, and beverages.",
        ingredients: {
            nutrients: ["Vitamin C", "Vitamin A", "Dietary Fiber"],
            minerals: ["Potassium", "Manganese"],
            vitamins: ["Vitamin E"],
            protein: "0.82 gm / 100gm",
            fats: "0.64 gm / 100gm"
        },
        varieties: [
            {
                heading: "Alphonso Mango",
                about: "Alphonso mangoes, often called the 'King of Mangoes,' are renowned for their sweet, aromatic flavor and smooth, non-fibrous flesh. They are used in making mango lassi, desserts, and enjoyed as a fresh fruit."
            },
            {
                heading: "Kesar Mango",
                about: "Kesar mangoes are known for their distinct saffron-like aroma and sweet taste. They are popular for making mango pulp, ice creams, and flavored sweets."
            },
            {
                heading: "Totapuri Mango",
                about: "Totapuri mangoes have an elongated shape and tangy-sweet taste. They are commonly used in making pickles, chutneys, and mango-based dishes."
            },
            {
                heading: "Langra Mango",
                about: "Langra mangoes are characterized by their green, slightly wrinkled skin and sweet, juicy flesh. They are often enjoyed as a whole fruit and are a favorite for making mango shakes."
            },
            {
                heading: "Dasheri Mango",
                about: "Dasheri mangoes are sweet and fragrant with a fiberless pulp. They are prized for their taste and are commonly eaten fresh or used in mango-based desserts."
            }
        ]
    },
    {
        id: "orange",
        heading: "Orange",
        about: "Indian oranges are a popular citrus fruit known for their tangy-sweet flavor and high vitamin C content. They are widely grown and enjoyed throughout India.",
        description: "Indian oranges come in various varieties, each offering a unique taste and level of sweetness. They are a versatile fruit used in juices, snacks, desserts, and as a source of vitamin C in the Indian diet.",
        ingredients: {
            nutrients: ["Vitamin C", "Dietary Fiber"],
            minerals: ["Potassium", "Calcium"],
            vitamins: ["Vitamin A"],
            protein: "1.04 gm / 100gm",
            fats: "0.2 gm / 100gm"
        },
        varieties: [
            {
                heading: "Nagpur Orange",
                about: "Nagpur oranges, also known as 'Nagpuri Santra,' are famous for their sweet and juicy pulp. They are one of the most popular varieties in India and are commonly used to make fresh orange juice."
            },
            {
                heading: "Kinnow Orange",
                about: "Kinnow oranges are a hybrid citrus fruit with a sweet and tangy taste. They are easy to peel and are often enjoyed as a snack or used in fruit salads."
            },
            {
                heading: "Mandarin Orange",
                about: "Mandarin oranges, also known as 'Kamala Orange,' are smaller and easy-to-peel oranges with a sweet flavor. They are often used as a quick, refreshing snack."
            },
            {
                heading: "Blood Orange",
                about: "Blood oranges have a distinctive red or crimson flesh, which sets them apart from other oranges. They have a sweet and slightly tart flavor and are used in both juices and desserts."
            },
            {
                heading: "Malta Orange",
                about: "Malta oranges, also known as 'Malta Santra,' are sweet and flavorful with a thin, easy-to-peel skin. They are popular for making marmalades and orange-flavored candies."
            }
        ]
    },
    {
        id: "grapes",
        heading: "Grapes",
        about: "Grapes are a widely cultivated fruit in India, known for their sweet and juicy taste. They come in various colors, including red, green, and black, and are enjoyed as fresh fruit, in desserts, and for making wine and raisins.",
        description: "Indian grapes are grown in several regions and are available in different varieties, each with its unique flavor profile. They are a popular fruit used in salads, desserts, and as a healthy snack.",
        ingredients: {
            nutrients: ["Vitamin C", "Vitamin K", "Dietary Fiber"],
            minerals: ["Potassium", "Calcium", "Iron"],
            vitamins: ["Vitamin B6"],
            protein: "0.72 gm / 100gm",
            fats: "0.16 gm / 100gm"
        },
        varieties: [
            {
                heading: "Thompson Seedless",
                about: "Thompson Seedless grapes are green, seedless, and known for their sweet, honey-like flavor. They are commonly used in making raisins and enjoyed as table grapes."
            },
            {
                heading: "Black Seedless",
                about: "Black Seedless grapes have a dark purple to black color and offer a sweet and slightly tart taste. They are often used in fruit salads, desserts, and for snacking."
            },
            {
                heading: "Red Globe",
                about: "Red Globe grapes are large, red, and have a sweet, crisp texture. They are popular as a table grape and are also used in making red wine."
            },
            {
                heading: "Crimson Seedless",
                about: "Crimson Seedless grapes are red with a mild, sweet flavor. They are a favorite for snacking, making juice, and adding to fruit salads."
            },
            {
                heading: "Muscat Grapes",
                about: "Muscat grapes are small, round, and have a rich, aromatic flavor. They are used in making sweet wines, and their intense sweetness makes them ideal for desserts and jams."
            }
        ]
    },
    {
        id: "pomegranate",
        heading: "Pomegranate",
        about: "Pomegranates are a beloved fruit in India known for their sweet and tangy arils (seeds) enclosed in a juicy, ruby-red pulp. They are a symbol of fertility and prosperity and are widely cultivated and consumed throughout the country.",
        description: "Indian pomegranates are not only delicious but also packed with antioxidants and nutrients. They are used in various culinary preparations, juices, salads, and as a garnish for their unique flavor and vibrant color.",
        ingredients: {
            nutrients: ["Vitamin C", "Vitamin K", "Dietary Fiber"],
            minerals: ["Potassium", "Folate"],
            vitamins: ["Vitamin B9"],
            protein: "1.67 gm / 100gm",
            fats: "1.17 gm / 100gm"
        },
        varieties: [
            {
                heading: "Bhagwa Pomegranate",
                about: "Bhagwa pomegranates are known for their large size and deep red, sweet arils. They are one of the popular varieties in India and are often enjoyed fresh or used in salads and desserts."
            },
            {
                heading: "Kesar Pomegranate",
                about: "Kesar pomegranates are smaller in size with a slightly tangy-sweet taste. They are famous for their bright red arils and are commonly used in making pomegranate juice."
            },
            {
                heading: "Ganesh Pomegranate",
                about: "Ganesh pomegranates are known for their medium-sized fruit and sweet-tart flavor. They are versatile and can be eaten fresh or used in various culinary preparations."
            },
            {
                heading: "Mridula Pomegranate",
                about: "Mridula pomegranates are prized for their soft, juicy arils and sweet taste. They are often enjoyed as a healthy snack or added to fruit salads."
            },
            {
                heading: "Ruby Pomegranate",
                about: "Ruby pomegranates are another popular variety with deep red arils and a balanced sweet-tart flavor. They are used for making fresh juices and are also eaten as a whole fruit."
            }
        ]
    }
]

const Fruits = () => {
    const [activeProduct, setActiveProduct] = useState({})
    const [modal, setModal] = useState(false)

    const [open, setOpen] = useState('1')

    const toggleAccordion = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    }

    const toggle = () => setModal(!modal)

    useEffect(() => {
        if (!modal) {
            setTimeout(() => {
                setActiveProduct({})
            }, 500)
        }
    }, [modal])

    const handleProduct = (product) => {
        setActiveProduct(productDetails.filter(element => element.id === product)[0])
    }

    return (
        <Fragment>
            <Container>
                <Row className='py-5'>
                    <Col>
                        <h1 className='section-heading m-0 text-primary text-center'>Indian Fruits</h1>
                    </Col>
                </Row>

                <Row className='pb-5'>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('coconut')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={coconut} alt="coconut" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Coconut</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('mango')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={mango} alt="mango" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Mango</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('orange')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={orange} alt="orange" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Orange</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('grapes')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={grapes} alt="grapes" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Grapes</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('pomegranate')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={pomegranate} alt="pomegranate" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Pomegranate</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Modal isOpen={modal} toggle={toggle} size='xl' centered scrollable className='border-0 rounded-0'>
                    <ModalHeader toggle={toggle} className='text-primary'>{activeProduct.heading}</ModalHeader>
                    <ModalBody>

                        <Row>
                            <Col>
                                <h4 className='m-0 pt-2 text-primary'>About</h4>
                                <p className='text-primary'>{activeProduct.about}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h4 className='m-0 pt-2 text-primary'>Description</h4>
                                <p className='text-primary'>{activeProduct.description}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12}><h4 className='m-0 pt-2 text-primary'>Ingredients</h4></Col>
                            <Col lg={6}>
                                <p className='m-0 text-primary'>
                                    <b>Nutrients:</b> {activeProduct.ingredients?.nutrients.join(', ')}
                                </p>
                                <p className='m-0 text-primary'>
                                    <b>Minerals:</b> {activeProduct.ingredients?.minerals.join(', ')}
                                </p>
                                <p className='m-0 text-primary'>
                                    <b>Vitamins:</b> {activeProduct.ingredients?.vitamins.join(', ')}
                                </p>
                            </Col>
                            <Col lg={6}>
                                <p className='m-0 text-primary'>
                                    <b>Protein:</b> {activeProduct.ingredients?.protein}
                                </p>
                                <p className='m-0 text-primary'>
                                    <b>Fats:</b> {activeProduct.ingredients?.fats}
                                </p>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12}>
                                <h4 className='m-0 pt-2 text-primary'>Varieties</h4>
                            </Col>
                            <Accordion open={open} toggle={toggleAccordion}>
                                {
                                    activeProduct.varieties?.map((item, index) => {
                                        return (
                                            <AccordionItem className='my-3 shadow-lg'>
                                                <AccordionHeader targetId={index + 1}>{item.heading}</AccordionHeader>
                                                <AccordionBody accordionId={index + 1} className='text-primary'>
                                                    <FiCheck size={25} /> {item.about}
                                                </AccordionBody>
                                            </AccordionItem>
                                        )
                                    })
                                }
                            </Accordion>
                        </Row>

                    </ModalBody>
                </Modal>
            </Container>
        </Fragment>
    )
}

export default Fruits