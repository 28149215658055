// React Imports
import React, { Fragment } from 'react'

// Third Party Imports
import { Route, Routes } from 'react-router-dom'

// CSS Imports
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

// Component Imports
import Wrapper from './components/layout/Wrapper'
import HomePage from './components/pages/HomePage'
import AboutUsPage from './components/pages/AboutUsPage'
import ComingSoonComponent from './components/view/Coming-Soon'
import Beverages from './components/view/Products/Beverages'
import Fruits from './components/view/Products/Fruits'
import Vegetables from './components/view/Products/Vegetables'
import Spices from './components/view/Products/Spices'
import DryFruits from './components/view/Products/Dry-Fruits'
import Pulses from './components/view/Products/Pulses'
import Flours from './components/view/Products/Flours'
import ContactUsComponent from './components/view/Contact-Us'
import BlogComponent from './components/view/Blog'
import BlogContent from './components/view/Blog/BlogContent'

const App = () => {
  return (
    <Fragment>
      <Routes>
        <Route path='/' element={<Wrapper />}>
          <Route index path='' element={<HomePage />} />
          <Route path='/about-us' element={<AboutUsPage />} />
          <Route path='/certificates' element={<ComingSoonComponent />} />
          <Route path='/blogs' element={<BlogComponent />} />
          <Route path='/blog/:id' element={<BlogContent />} />
          <Route path='/contact-us' element={<ContactUsComponent />} />
          <Route path='/product/fruits' element={<Fruits />} />
          <Route path='/product/vegetables' element={<Vegetables />} />
          <Route path='/product/spices' element={<Spices />} />
          <Route path='/product/dry-fruits' element={<DryFruits />} />
          <Route path='/product/beverages' element={<Beverages />} />
          <Route path='/product/pulses' element={<Pulses />} />
          <Route path='/product/flours' element={<Flours />} />
        </Route>
      </Routes>
    </Fragment>
  )
}

export default App
