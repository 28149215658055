// React Imports
import React, { Fragment } from 'react'

// Component Imports
import AboutUs from './AboutUs'

const AboutUsComponent = () => {
    return (
        <Fragment>
            <AboutUs />
        </Fragment>
    )
}

export default AboutUsComponent