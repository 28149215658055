// React Imports
import React, { Fragment } from 'react'

// Third Party Imports
import { Container } from 'reactstrap'

// Component Imports
import Header from './Header'

const HeaderComponent = () => {
    return (
        <Fragment>
            <Container fluid className='bg-light'>
                <Header />
            </Container>
        </Fragment>
    )
}

export default HeaderComponent
