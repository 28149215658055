// React Imports
import React, { Fragment } from 'react'

// Component Imports
import ContactUs from './ContactUs'

const ContactUsComponent = () => {
    return (
        <Fragment>
            <ContactUs />
        </Fragment>
    )
}

export default ContactUsComponent