// React Imports
import React, { Fragment, useState } from 'react'

// Third Party Imorts
import {
    Collapse,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Container
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { FiMinus } from "react-icons/fi"
import UseAnimations from 'react-useanimations'
import menu4 from 'react-useanimations/lib/menu4'

// CSS Imports
import '../../../styles/header/header.css'

// Images and Logo Imports
import logo from '../../../assets/logo/italia logo203.png'

const Header = () => {
    // Hooks
    const [isNavbarOpen, setIsNavbarOpen] = useState(false)

    // Toggler
    const toggleNavbar = () => setIsNavbarOpen(!isNavbarOpen)

    return (
        <Fragment>
            <Container>
                <Navbar expand='lg'>
                    <NavbarBrand href='/'>
                        <div className='logo'>
                            <img src={logo} alt="" />
                        </div>
                    </NavbarBrand>
                    <UseAnimations animation={menu4} className='d-lg-none' size={40} strokeColor='#1E6F5C' onClick={toggleNavbar} />
                    <Collapse isOpen={isNavbarOpen} navbar>
                        <Nav className='ms-auto align-items-center' navbar>
                            <NavItem>
                                <Link to='/' className='menu px-xl-3 text-primary nav-link'>Home</Link>
                            </NavItem>
                            <NavItem>
                                <NavLink className='menu px-xl-3 dropdown-menu text-primary text-center position-relative'>
                                    Our Products

                                    <Nav className='sub-menu-dropdown w-100 bg-light pt-2 shadow-lg'>
                                        <NavItem className='sub-menu py-1'>
                                            <Link to='/product/fruits' className='nav-link'>
                                                <FiMinus size={20} /> Fruits
                                            </Link>
                                        </NavItem>
                                        <NavItem className='sub-menu py-1'>
                                            <Link to='/product/vegetables' className='nav-link'>
                                                <FiMinus size={20} /> Vegetables
                                            </Link>
                                        </NavItem>
                                        <NavItem className='sub-menu py-1'>
                                            <Link to='/product/spices' className='nav-link'>
                                                <FiMinus size={20} /> Spices
                                            </Link>
                                        </NavItem>
                                        <NavItem className='sub-menu py-1'>
                                            <Link to='/product/dry-fruits' className='nav-link'>
                                                <FiMinus size={20} /> Dry Fruits
                                            </Link>
                                        </NavItem>
                                        <NavItem className='sub-menu py-1'>
                                            <Link to='/product/beverages' className='nav-link'>
                                                <FiMinus size={20} /> Beverage
                                            </Link>
                                        </NavItem>
                                        <NavItem className='sub-menu py-1'>
                                            <Link to='/product/pulses' className='nav-link'>
                                                <FiMinus size={20} /> Pulses
                                            </Link>
                                        </NavItem>
                                        <NavItem className='sub-menu py-1'>
                                            <Link to='/product/flours' className='nav-link'>
                                                <FiMinus size={20} /> Flour
                                            </Link>
                                        </NavItem>
                                    </Nav>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <Link to='/certificates' className='menu px-xl-3 text-primary nav-link'>Certificates</Link>
                            </NavItem>
                            <NavItem>
                                <Link to='/blogs' className='menu px-xl-3 text-primary nav-link'>Blogs</Link>
                            </NavItem>
                            <NavItem>
                                <Link to='/about-us' className='menu px-xl-3 text-primary nav-link'>About Us</Link>
                            </NavItem>
                            <NavItem>
                                <Link to='/contact-us' className='menu px-xl-3 text-primary nav-link'>Contact Us</Link>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </Container>
        </Fragment>
    )
}

export default Header
