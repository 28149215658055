// React Imports
import React, { Fragment } from 'react'

// Third Party Imports
import { Container } from 'reactstrap'

// Component Imports
import AboutCompany from './AboutCompany'

const AboutCompanyComponent = () => {
    return (
        <Fragment>
            <Container fluid className='bg-warning'>
                <AboutCompany />
            </Container>
        </Fragment>
    )
}

export default AboutCompanyComponent