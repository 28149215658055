// React Imports
import React, { Fragment } from 'react'

// Third Party Imports
import { Card, CardBody, CardText, CardTitle, Col, Container, Row } from 'reactstrap'

// Images & Logo Imports
import earth from '../../../assets/icons/planet-earth.png'

// CSS Imports
import '../../../styles/who-we-are/who-we-are.css'

const WhoWeAre = () => {
    return (
        <Fragment>
            <Container className='pb-5'>
                <Row className='pb-5'>
                    <Col sm={12} className='d-flex justify-content-center pb-5'>
                        <div className='earth-icon'>
                            <img src={earth} alt="" />
                        </div>
                    </Col>
                    <Col sm={12}>
                        <h2 className='section-heading m-0 text-primary text-center'>
                            Who We Are? & What Will You Get?
                        </h2>
                    </Col>
                </Row>
                <Row className='pt-5'>
                    <Col lg={4} md={6} className='py-2' >
                        <Card className='who-we-are-card border-0'>
                            <CardBody className='py-3'>
                                <CardTitle tag="h5" className='text-primary pb-3'>
                                    Premium Quality
                                </CardTitle>
                                <CardText className='text-primary'>
                                    We take pride in delivering only the finest Indian goods. Our stringent quality control ensures that every product, from fresh fruits and vegetables to aromatic masalas and dry fruits, meets the highest quality standards.
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4} md={6} className='py-2'>
                        <Card className='who-we-are-card border-0'>
                            <CardBody className='py-3'>
                                <CardTitle tag="h5" className='text-primary pb-3'>
                                    Diverse Product Range
                                </CardTitle>
                                <CardText className='text-primary'>
                                    Explore a wide variety of Indian agricultural products, all under one roof. From the freshest seasonal fruits and vegetables to a rich array of spices, masalas, and nutrient-packed dry fruits, we offer a comprehensive selection.
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4} md={6} className='py-2'>
                        <Card className='who-we-are-card border-0'>
                            <CardBody className='py-3'>
                                <CardTitle tag="h5" className='text-primary pb-3'>
                                    Farm-to-Table Freshness
                                </CardTitle>
                                <CardText className='text-primary'>
                                    Our commitment to freshness begins at the source. With a farm-to-table approach, our products retain their natural goodness, ensuring that you receive farm-fresh, flavorful, and nutritious items every time.
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4} md={6} className='py-2'>
                        <Card className='who-we-are-card border-0'>
                            <CardBody className='py-3'>
                                <CardTitle tag="h5" className='text-primary pb-3'>
                                    Authentic Indian Flavors
                                </CardTitle>
                                <CardText className='text-primary'>
                                    Experience the authentic flavors of India in every bite. Our products are a testament to India's rich culinary heritage, bringing the essence of Indian cuisine to your kitchen.
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4} md={6} className='py-2'>
                        <Card className='who-we-are-card border-0'>
                            <CardBody className='py-3'>
                                <CardTitle tag="h5" className='text-primary pb-3'>
                                    Timely Delivery
                                </CardTitle>
                                <CardText className='text-primary'>
                                    Count on us for reliable and timely deliveries. Our longstanding relationships with trusted suppliers and logistics partners ensure that your orders are handled with care and reach you promptly.
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4} md={6} className='py-2'>
                        <Card className='who-we-are-card border-0'>
                            <CardBody className='py-3'>
                                <CardTitle tag="h5" className='text-primary pb-3'>
                                    Global Reach
                                </CardTitle>
                                <CardText className='text-primary'>
                                    With a global export network, we cater to customers worldwide. No matter where you are, you can enjoy the taste of India with ease.
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4} md={6} className='py-2'>
                        <Card className='who-we-are-card border-0'>
                            <CardBody className='py-3'>
                                <CardTitle tag="h5" className='text-primary pb-3'>
                                    Competitive Pricing
                                </CardTitle>
                                <CardText className='text-primary'>
                                    We offer competitive pricing without compromising on quality. Enjoy affordability along with premium Indian products.
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4} md={6} className='py-2'>
                        <Card className='who-we-are-card border-0'>
                            <CardBody className='py-3'>
                                <CardTitle tag="h5" className='text-primary pb-3'>
                                    Experience and Expertise
                                </CardTitle>
                                <CardText className='text-primary'>
                                    With years of experience in the export industry, we have the expertise to navigate international markets and regulations, ensuring a smooth experience for our customers.
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4} md={6} className='py-2'>
                        <Card className='who-we-are-card border-0'>
                            <CardBody className='py-3'>
                                <CardTitle tag="h5" className='text-primary pb-3'>
                                    Transparency and Trust
                                </CardTitle>
                                <CardText className='text-primary'>
                                    We believe in transparency in all our dealings. You can trust us to provide accurate product information and meet your expectations.
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default WhoWeAre