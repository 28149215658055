// React Imports
import React, { Fragment } from 'react'

// Component Imports
import BestOfUs from './BestOfUs'

const BestOfUsComponent = () => {
    return (
        <Fragment>
            <BestOfUs />
        </Fragment>
    )
}

export default BestOfUsComponent