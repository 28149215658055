// React Imports
import React, { Fragment, useEffect } from 'react'

// Third Party Imports
import { Card, CardBody, CardHeader, CardText, Col, Container, Row } from 'reactstrap'
import { FiCheck } from "react-icons/fi"
import Aos from 'aos'

// CSS Imports
import '../../../styles/portfolio/portfolio.css'

const Portfolio = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    return (
        <Fragment>
            <Container className='py-5'>
                <Row className='pb-5'>
                    <Col>
                        <h1 className='section-heading text-center text-primary'>
                            Portfolio
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} className='py-2' data-aos='fade-right'>
                        <Card className='fruits border-0 shadow-lg'>
                            <CardBody className='portfolio-card'>
                                <CardHeader className='bg-transparent'>
                                    <h3 className='text-primary'>
                                        Indian Fruits
                                    </h3>
                                </CardHeader>
                                <CardText className='pt-2'>
                                    <p className='text-primary'>
                                        <FiCheck size={25} /> Dive into the tropical paradise of Alphonso mangoes, where every bite bursts with the sweet, sun-kissed essence of India.
                                    </p>
                                    <p className='text-primary'>
                                        <FiCheck size={25} /> Explore the world of Kesar apples, crisp and aromatic, delivering a delightful fusion of flavor and texture in each bite.
                                    </p>
                                    <p className='text-primary'>
                                        <FiCheck size={25} /> From the exotic sweetness of lychees to the zesty freshness of lemons, our Indian fruit selection embodies nature's finest flavors.
                                    </p>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={6} className='py-2' data-aos='fade-left'>
                        <Card className='vegetables border-0 shadow-lg'>
                            <CardBody className='portfolio-card'>
                                <CardHeader className='bg-transparent'>
                                    <h3 className='text-primary'>
                                        Indian Vegetables
                                    </h3>
                                </CardHeader>
                                <CardText className='pt-2'>
                                    <p className='text-primary'>
                                        <FiCheck size={25} /> Our range of Indian vegetables showcases the vibrant hues and distinctive tastes of India's diverse culinary landscape.
                                    </p>
                                    <p className='text-primary'>
                                        <FiCheck size={25} /> Whether it's the tender green spinach or the fiery red of our premium chilies, our vegetables add an authentic touch to your recipes.
                                    </p>
                                    <p className='text-primary'>
                                        <FiCheck size={25} /> Dive into the world of Indian cuisine with our assortment of vegetables, offering an array of textures and flavors to enhance your dishes.
                                    </p>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={6} className='py-2' data-aos='fade-right'>
                        <Card className='dry-fruits border-0 shadow-lg'>
                            <CardBody className='portfolio-card'>
                                <CardHeader className='bg-transparent'>
                                    <h3 className='text-primary'>
                                        Dry Fruits
                                    </h3>
                                </CardHeader>
                                <CardText className='pt-2'>
                                    <p className='text-primary'>
                                        <FiCheck size={25} /> Elevate your snacking experience with our luscious cashews, almonds, and pistachios, meticulously selected for their quality and taste.
                                    </p>
                                    <p className='text-primary'>
                                        <FiCheck size={25} /> Dried apricots, figs, and dates offer a burst of natural sweetness and health benefits, perfect for both indulgence and nutrition.
                                    </p>
                                    <p className='text-primary'>
                                        <FiCheck size={25} /> Our premium dry fruits, from velvety walnuts to exotic dried cranberries, bring an irresistible blend of textures and flavors to your palate.
                                    </p>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={6} className='py-2' data-aos='fade-left'>
                        <Card className='spices border-0 shadow-lg'>
                            <CardBody className='portfolio-card'>
                                <CardHeader className='bg-transparent'>
                                    <h3 className='text-primary'>
                                        Spices
                                    </h3>
                                </CardHeader>
                                <CardText className='pt-2'>
                                    <p className='text-primary'>
                                        <FiCheck size={25} /> Experience the magic of Indian spices, each carefully sourced and packed to infuse your dishes with authentic flavors.
                                    </p>
                                    <p className='text-primary'>
                                        <FiCheck size={25} /> Our diverse spice selection, including cumin, cardamom, and turmeric, adds depth and character to your culinary creations.
                                    </p>
                                    <p className='text-primary'>
                                        <FiCheck size={25} /> From the smoky allure of paprika to the earthy richness of fenugreek, our spices are your passport to the vibrant tapestry of Indian cuisine.
                                    </p>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={6} className='py-2' data-aos='fade-right'>
                        <Card className='flour border-0 shadow-lg'>
                            <CardBody className='portfolio-card'>
                                <CardHeader className='bg-transparent'>
                                    <h3 className='text-primary'>
                                        Flour
                                    </h3>
                                </CardHeader>
                                <CardText className='pt-2'>
                                    <p className='text-primary'>
                                        <FiCheck size={25} /> Our selection of flours, from finely milled wheat for soft rotis to rice flour for crispy dosas, ensures culinary perfection.
                                    </p>
                                    <p className='text-primary'>
                                        <FiCheck size={25} /> Explore the versatility of besan (chickpea flour) for savory snacks and gram flour for delightful sweets, all crafted to perfection.
                                    </p>
                                    <p className='text-primary'>
                                        <FiCheck size={25} /> We provide the essential foundation for your Indian-inspired dishes, where every flour type is a tribute to tradition and taste.
                                    </p>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={6} className='py-2' data-aos='fade-left'>
                        <Card className='pulses border-0 shadow-lg'>
                            <CardBody className='portfolio-card'>
                                <CardHeader className='bg-transparent'>
                                    <h3 className='text-primary'>
                                        Pulses
                                    </h3>
                                </CardHeader>
                                <CardText className='pt-2'>
                                    <p className='text-primary'>
                                        <FiCheck size={25} /> Discover the heart of Indian cuisine in our array of lentils, beans, and pulses, offering an abundant source of protein and nutrition.
                                    </p>
                                    <p className='text-primary'>
                                        <FiCheck size={25} /> From the creamy richness of urad dal to the earthy comfort of moong dal, our pulses are the cornerstone of authentic Indian cooking.
                                    </p>
                                    <p className='text-primary'>
                                        <FiCheck size={25} /> Sourced with care and precision, our pulses promise consistency and quality, ensuring culinary success in every dish you prepare.
                                    </p>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default Portfolio