// React Imports
import React, { Fragment } from 'react'

// Component Imports
import Blog from './Blog'

const BlogComponent = () => {
    return (
        <Fragment>
            <Blog />
        </Fragment>
    )
}

export default BlogComponent