// React Imports
import React, { Fragment } from 'react'

// Component Imports
import FooterTopbar from './FooterTopbar'

const FooterTopbarComponent = () => {
  return (
    <Fragment>
        <FooterTopbar />
    </Fragment>
  )
}

export default FooterTopbarComponent