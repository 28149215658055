// React Imports
import React, { Fragment } from 'react'

// Third Party Imports
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import { useNavigate } from 'react-router-dom'

// CSS Imports
import '../../../styles/blog/blog.css'

const Blog = () => {

    const navigate = useNavigate()

    const handleBlogCardClick = (id) => {
        navigate(`/blog/${id}`)
    }

    return (
        <Fragment>
            <Container className='py-5'>
                <Row className='pb-5'>
                    <Col>
                        <h1 className='section-heading m-0 text-primary text-center'>Blogs</h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className='py-3'>
                        <Card className='blog-card border-0 bg-light' onClick={() => handleBlogCardClick(1)}>
                            <CardBody>
                                <h2 className="text-primary">The Mango Marvel: A Delectable Journey Through India's Mango Varieties</h2>
                                <ul className='text-primary'>
                                    <li>The mention of mangoes brings a smile to the faces of millions of Indians and conjures images of sunny orchards, lazy summer afternoons, and the rich, sweet aroma of the 'King of Fruits.'</li>
                                    <li>In this blog post, we embark on a delectable journey through the diverse and flavorful world of Indian mangoes. From the iconic Alphonso to the lesser-known treasures, we'll explore the unique characteristics and cultural significance of these golden delights.</li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={12} className='py-3'>
                        <Card className='blog-card border-0 bg-light' onClick={() => handleBlogCardClick(2)}>
                            <CardBody>
                                <h2 className="text-primary">The Bounty of the Land: A Journey Through India's Diverse Vegetables</h2>
                                <ul className='text-primary'>
                                    <li>
                                        India, with its rich and diverse culinary heritage, is a land of vegetables. The country's vast agricultural landscape offers an incredible array of vegetables that play a central role in Indian cuisine.
                                    </li>
                                    <li>
                                        In this blog post, we embark on a flavorful journey through India's vegetable diversity. From the iconic potato to lesser-known regional gems, we'll explore the unique characteristics and cultural significance of these essential ingredients.

                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={12} className='py-3'>
                        <Card className='blog-card border-0 bg-light' onClick={() => handleBlogCardClick(3)}>
                            <CardBody>
                                <h2 className="text-primary">Bananas in India: A Sweet Journey Through Varieties and Culture</h2>
                                <ul className='text-primary'>
                                    <li>
                                        Bananas, one of the most beloved fruits globally, hold a special place in India's culture and cuisine. They are not just a snack but an integral part of the country's culinary heritage.
                                    </li>
                                    <li>
                                        In this blog post, we'll embark on a delightful journey through the world of Indian bananas. From the popular Cavendish to indigenous varieties, we'll explore the unique characteristics and cultural significance of these sweet treasures.
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={12} className='py-3'>
                        <Card className='blog-card border-0 bg-light' onClick={() => handleBlogCardClick(4)}>
                            <CardBody>
                                <h2 className="text-primary">India's Spice Story: Aromatic Odyssey Through Flavors</h2>
                                <ul className='text-primary'>
                                    <li>
                                        Indian cuisine is renowned for its vibrant flavors, and at the heart of these flavors are the aromatic spices that have enchanted taste buds for centuries.
                                    </li>
                                    <li>
                                        In this blog post, we embark on an aromatic journey through India's rich spice heritage. From the fiery red chili to the warm embrace of cinnamon, we'll explore the diverse spices that define Indian cooking.
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={12} className='py-3'>
                        <Card className='blog-card border-0 bg-light' onClick={() => handleBlogCardClick(5)}>
                            <CardBody>
                                <h2 className="text-primary">The Potato Chronicles: Exploring India's Love Affair with Aloo</h2>
                                <ul className='text-primary'>
                                    <li>
                                        In the grand tapestry of Indian cuisine, one ingredient stands out as a beloved staple – the humble potato, known as 'aloo' in Hindi. It's a versatile and much-adored vegetable that has found its way into countless dishes, from street food to elaborate curries.
                                    </li>
                                    <li>
                                        In this blog post, we embark on a delightful journey through India's love affair with the potato. From classic aloo parathas to spicy samosas, we'll explore the potato's diverse roles in Indian culinary traditions.
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={12} className='py-3'>
                        <Card className='blog-card border-0 bg-light' onClick={() => handleBlogCardClick(6)}>
                            <CardBody>
                                <h2 className="text-primary">The Spice of Kings: Cloves in Indian Cuisine and Culture</h2>
                                <ul className='text-primary'>
                                    <li>
                                        Cloves, the aromatic flower buds of the clove tree, are among the most prized and fragrant spices in Indian cuisine. They are known for their rich, warm flavor and are a vital component of countless Indian dishes.
                                    </li>
                                    <li>
                                        In this blog post, we'll embark on a flavorful journey through India's appreciation for cloves. From spiced biryanis to fragrant masalas, we'll explore the unique role of cloves in Indian culinary traditions and culture.
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={12} className='py-3'>
                        <Card className='blog-card border-0 bg-light' onClick={() => handleBlogCardClick(7)}>
                            <CardBody>
                                <h2 className="text-primary">Sipping the Flavors of India: A Journey Through Diverse Indian Beverages</h2>
                                <ul className='text-primary'>
                                    <li>
                                        India, a land of rich traditions and flavors, offers a treasure trove of diverse and delightful beverages. From aromatic teas to cooling lassis, these beverages play a crucial role in Indian culture and cuisine.
                                    </li>
                                    <li>
                                        In this blog post, we embark on a flavorful journey through India's unique beverages. From the world-famous chai to regional specialties, we'll explore the captivating flavors and cultural significance of these liquid delights.
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={12} className='py-3'>
                        <Card className='blog-card border-0 bg-light' onClick={() => handleBlogCardClick(8)}>
                            <CardBody>
                                <h2 className="text-primary">The Elixir of India: Exploring the Diversity of Indian Teas</h2>
                                <ul className='text-primary'>
                                    <li>
                                        Tea, often referred to as 'chai' in India, is more than just a beverage; it's an integral part of the country's culture and daily life. India is renowned for its diverse and aromatic teas that have delighted tea enthusiasts worldwide.
                                    </li>
                                    <li>
                                        In this blog post, we'll embark on a delightful journey through India's tea heritage. From the world-famous Masala Chai to the high-grown Darjeeling and everything in between, we'll explore the captivating flavors and cultural significance of Indian teas.
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={12} className='py-3'>
                        <Card className='blog-card border-0 bg-light' onClick={() => handleBlogCardClick(9)}>
                            <CardBody>
                                <h2 className="text-primary">Zesty Zing: The Versatile and Flavorful World of Indian Ginger</h2>
                                <ul className='text-primary'>
                                    <li>
                                        Ginger, known as 'adrak' in Hindi, is a spice that's deeply woven into the tapestry of Indian cuisine and culture. Its distinct zesty flavor and aromatic warmth make it a cherished ingredient in countless Indian dishes.
                                    </li>
                                    <li>
                                        In this blog post, we'll take a flavorful journey through India's love for ginger. From spiced masalas to soothing teas, we'll explore the versatility and cultural significance of this knobby root.
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={12} className='py-3'>
                        <Card className='blog-card border-0 bg-light' onClick={() => handleBlogCardClick(10)}>
                            <CardBody>
                                <h2 className="text-primary">The Aromatic Wonder: Bay Leaves in Indian Cuisine and Culture</h2>
                                <ul className='text-primary'>
                                    <li>
                                        Bay leaves, known as 'tej patta' in Hindi, are a fragrant and essential spice in Indian cooking. Their aromatic qualities and unique flavor have earned them a special place in the heart of Indian cuisine.
                                    </li>
                                    <li>
                                        In this blog post, we'll embark on an aromatic journey through India's love for bay leaves. From biryanis to flavorful curries, we'll explore the versatility and cultural significance of these aromatic leaves.
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={12} className='py-3'>
                        <Card className='blog-card border-0 bg-light' onClick={() => handleBlogCardClick(11)}>
                            <CardBody>
                                <h2 className="text-primary">Onion: The Flavorful Foundation of Indian Cooking</h2>
                                <ul className='text-primary'>
                                    <li>
                                        Onions, known as 'pyaaz' in Hindi, are a fundamental ingredient in Indian cuisine. Their versatility, distinct flavor, and ability to create rich, aromatic bases have made them a kitchen essential in India.
                                    </li>
                                    <li>
                                        In this blog post, we'll embark on a flavorful journey through India's love for onions. From savory curries to spicy biryanis, we'll explore the essential role of onions in Indian culinary traditions.
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={12} className='py-3'>
                        <Card className='blog-card border-0 bg-light' onClick={() => handleBlogCardClick(12)}>
                            <CardBody>
                                <h2 className="text-primary">Zesty Sunshine: Exploring the Versatile Indian Lemon</h2>
                                <ul className='text-primary'>
                                    <li>
                                        Lemons, known as 'nimbu' in Hindi, are a vibrant and versatile citrus fruit that adds a refreshing burst of flavor to Indian cuisine. They are cherished for their zesty and tangy profile, making them a beloved ingredient in Indian kitchens.
                                    </li>
                                    <li>
                                        In this blog post, we'll embark on a zesty journey through India's appreciation for lemons. From tangy chaats to cooling nimbu pani, we'll explore the versatility and cultural significance of this sunny fruit in Indian culinary traditions.
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={12} className='py-3'>
                        <Card className='blog-card border-0 bg-light' onClick={() => handleBlogCardClick(13)}>
                            <CardBody>
                                <h2 className="text-primary">The Nut of Life: The Versatility of Coconuts in Indian Cuisine and Culture</h2>
                                <ul className='text-primary'>
                                    <li>
                                        Coconuts, known as 'nariyal' in Hindi, are often referred to as the 'nut of life' in India. These versatile drupes play a crucial role in Indian cuisine, offering a range of flavors and culinary uses.
                                    </li>
                                    <li>
                                        In this blog post, we'll explore the myriad ways in which coconuts are celebrated in India. From creamy coconut milk to coconut chutney, we'll dive into their diverse roles in Indian culinary traditions and culture.
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={12} className='py-3'>
                        <Card className='blog-card border-0 bg-light' onClick={() => handleBlogCardClick(14)}>
                            <CardBody>
                                <h2 className="text-primary">Soya Bean: The Protein Powerhouse Transforming Indian Cuisine</h2>
                                <ul className='text-primary'>
                                    <li>
                                        Soya beans, often referred to as 'soyabean' in India, have become a nutritional powerhouse and a game-changer in the country's cuisine. They are celebrated for their high protein content and versatility in various dishes.
                                    </li>
                                    <li>
                                        In this blog post, we'll explore the rise of soya beans in Indian culinary traditions. From delectable soy-based recipes to their impact on vegetarian diets, we'll delve into their significant role in India's evolving food culture.
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={12} className='py-3'>
                        <Card className='blog-card border-0 bg-light' onClick={() => handleBlogCardClick(15)}>
                            <CardBody>
                                <h2 className="text-primary">Nourishing Delights: Exploring the World of Indian Dry Fruits</h2>
                                <ul className='text-primary'>
                                    <li>
                                        Dry fruits, also known as 'dry fruits and nuts,' are a treasure trove of nutritional goodness in Indian cuisine. They have been cherished for centuries for their wholesome qualities and indulgent flavors.
                                    </li>
                                    <li>
                                        In this blog post, we'll delve into the captivating world of Indian dry fruits. From almonds to pistachios, we'll explore their diverse flavors and their significant place in Indian culinary traditions and culture.
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={12} className='py-3'>
                        <Card className='blog-card border-0 bg-light' onClick={() => handleBlogCardClick(16)}>
                            <CardBody>
                                <h2 className="text-primary">Cashew: The Creamy Delight of Indian Cuisine</h2>
                                <ul className='text-primary'>
                                    <li>
                                        Cashew nuts, known as 'kaju' in Hindi, are one of the most sought-after dry fruits in Indian cuisine. They are celebrated for their rich, creamy texture and subtle sweetness, making them a cherished ingredient in countless Indian dishes.
                                    </li>
                                    <li>
                                        In this blog post, we'll explore the world of Indian cashews. From the iconic kaju barfi to savory cashew-based gravies, we'll dive into their versatile roles in Indian culinary traditions and culture.
                                    </li>
                                </ul>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default Blog