// React Imports
import React, { Fragment, useEffect, useState } from 'react'

// Third Party Imports
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { FiCheck } from "react-icons/fi"

// CSS Imports
import '../../../../styles/product/product.css'

// Images and Icon Imports
import onion from '../../../../assets/product-images/onion.jpg'
import potato from '../../../../assets/product-images/potato.jpg'
import drumstick from '../../../../assets/product-images/drumstick.jpg'
import ladyFinger from '../../../../assets/product-images/lady-finger.jpg'
import greenChilli from '../../../../assets/product-images/green-chilli.jpg'
import capsicum from '../../../../assets/product-images/capsicum.jpg'
import sweetPotato from '../../../../assets/product-images/sweet-potato.jpg'
import lemon from '../../../../assets/product-images/lemon.jpg'
import elephantYam from '../../../../assets/product-images/elephant-yam.jpg'
import brinjal from '../../../../assets/product-images/brinjal.jpg'
import cabbage from '../../../../assets/product-images/cabbage.jpg'

const productDetails = [
    {
        id: "onion",
        heading: "Onion",
        about: "Onions are a staple vegetable in Indian cuisine, known for their pungent flavor and versatility in various dishes. They are one of the most commonly used ingredients in Indian cooking.",
        description: "Indian onions come in different varieties, including red, white, and yellow, and are used in a wide range of savory preparations such as curries, salads, and fried snacks. They add depth of flavor and aroma to many traditional dishes.",
        ingredients: {
            nutrients: ["Vitamin C", "Folate", "Dietary Fiber"],
            minerals: ["Potassium", "Calcium"],
            vitamins: ["Vitamin B6"],
            protein: "1.1 gm / 100gm",
            fats: "0.1 gm / 100gm"
        },
        varieties: [
            {
                heading: "Red Onion",
                about: "Red onions are known for their mild to sweet flavor and vibrant purple-red color. They are often used raw in salads, salsas, and sandwiches or grilled for added sweetness in cooked dishes."
            },
            {
                heading: "Yellow Onion",
                about: "Yellow onions are the most common variety in India, characterized by their strong, pungent flavor. They are used as a base in many Indian curries, soups, and stir-fries."
            },
            {
                heading: "White Onion",
                about: "White onions have a milder flavor compared to yellow onions and are often used in dishes where a subtle onion taste is desired. They are commonly used in Mexican and Western cuisines as well."
            },
            {
                heading: "Spring Onion",
                about: "Spring onions, also known as green onions or scallions, are mild in flavor and are used as a garnish in various Indian dishes. They are also used in salads and noodle preparations."
            },
            {
                heading: "Shallot",
                about: "Shallots are small, elongated onions with a sweet and delicate flavor. They are often used in Indian pickles, chutneys, and as a seasoning in various dishes."
            }
        ]
    },
    {
        id: "potato",
        heading: "Potato",
        about: "Potatoes are a versatile and widely consumed vegetable in India, known for their starchy and mild flavor. They are a staple ingredient in various Indian dishes and cuisines.",
        description: "Indian potatoes are used in numerous culinary preparations, ranging from curries and snacks to bread and rice dishes. They are valued for their ability to absorb flavors and provide a comforting, filling element to many meals.",
        ingredients: {
            nutrients: ["Vitamin C", "Vitamin B6", "Dietary Fiber"],
            minerals: ["Potassium", "Manganese"],
            vitamins: ["Vitamin K"],
            protein: "2.02 gm / 100gm",
            fats: "0.25 gm / 100gm"
        },
        varieties: [
            {
                heading: "Russet Potato",
                about: "Russet potatoes are large and have a high starch content. They are commonly used for making Indian potato dishes like aloo paratha and are excellent for frying or baking."
            },
            {
                heading: "Red Potato",
                about: "Red potatoes are known for their thin, red skin and waxy texture. They are often used in Indian salads, boiled, or roasted for a creamy interior and a slightly sweet taste."
            },
            {
                heading: "Yukon Gold Potato",
                about: "Yukon Gold potatoes have a golden-yellow flesh and a buttery flavor. They are ideal for mashing and are used in dishes like mashed potatoes and Indian potato curries."
            },
            {
                heading: "Fingerling Potato",
                about: "Fingerling potatoes are small, elongated, and have a waxy texture. They are often used in Indian side dishes, roasted, or boiled for a unique appearance and taste."
            },
            {
                heading: "Baby Potato",
                about: "Baby potatoes are small and tender. They are used in a variety of Indian dishes, such as dum aloo, where their size and tenderness make them ideal."
            }
        ]
    },
    {
        id: "drumstick",
        heading: "Drumstick",
        about: "Drumsticks, often called 'Moringa' or 'Moringa oleifera,' are a unique and nutritious vegetable commonly found in Indian cuisine. They are known for their long, slender pods with tender, green seeds.",
        description: "Indian drumsticks are highly valued for their nutritional benefits and are used in a variety of dishes, particularly in South Indian and North Indian cuisines. They add a distinct flavor and texture to curries, stews, and soups.",
        ingredients: {
            nutrients: ["Vitamin C", "Vitamin A", "Iron"],
            minerals: ["Calcium", "Potassium"],
            vitamins: ["Vitamin B6"],
            protein: "2.1 gm / 100gm",
            fats: "0.39 gm / 100gm"
        },
        varieties: [
            {
                heading: "Indian Drumstick",
                about: "Indian drumsticks are the most common variety and are known for their slender, green pods filled with nutritious seeds. They are used in a wide range of Indian dishes, especially in drumstick sambar and drumstick curry."
            },
            {
                heading: "Wild Drumstick",
                about: "Wild drumsticks, often found in rural areas, have smaller and slightly more bitter pods compared to the cultivated variety. They are used in traditional herbal remedies and some regional dishes."
            },
            {
                heading: "Drumstick Leaves",
                about: "In addition to the pods, drumstick leaves are also consumed in India. They are rich in nutrients and are used in making drumstick leaf curry and other leaf-based dishes."
            },
            {
                heading: "Drumstick Seed Oil",
                about: "Drumstick seeds are used to extract oil, which is used in cooking and skincare products. It is known for its high nutritional value and health benefits."
            }
        ]
    },
    {
        id: "ladyfinger",
        heading: "Ladyfinger (Okra)",
        about: "Ladyfinger, also known as okra or bhindi in India, is a popular and nutritious vegetable widely used in Indian cuisine. It is characterized by its long, slender, and green pods.",
        description: "Indian ladyfinger is valued for its unique taste and versatility in cooking. It is commonly used in curries, stir-fries, and as a thickening agent in dishes like gumbo. Ladyfinger is known for its mucilaginous or slimy texture when cooked, which can be controlled to suit different recipes.",
        ingredients: {
            nutrients: ["Vitamin C", "Vitamin K", "Dietary Fiber"],
            minerals: ["Potassium", "Calcium"],
            vitamins: ["Vitamin A"],
            protein: "1.9 gm / 100gm",
            fats: "0.2 gm / 100gm"
        },
        varieties: [
            {
                heading: "Green Ladyfinger",
                about: "Green ladyfinger is the most common variety, featuring bright green pods with a tender texture. It is widely used in Indian dishes like bhindi masala and bhindi fry."
            },
            {
                heading: "Red Ladyfinger",
                about: "Red ladyfinger is a less common variety with reddish pods. It has a slightly different flavor and is used in regional recipes where it imparts a distinct color and taste."
            },
            {
                heading: "Spineless Ladyfinger",
                about: "Spineless ladyfinger is a popular variety known for its lack of prickly spines on the pods, making it easier to handle. It is commonly used in cooking due to its convenience."
            },
            {
                heading: "Dwarf Ladyfinger",
                about: "Dwarf ladyfinger is a smaller variety ideal for home gardens and small spaces. It produces compact plants with tender, green pods suitable for cooking."
            },
            {
                heading: "Hybrid Ladyfinger",
                about: "Hybrid ladyfinger varieties have been developed for improved disease resistance and higher yields. They are commonly grown in commercial agriculture."
            }
        ]
    },
    {
        id: "green-chili",
        heading: "Green Chili",
        about: "Green chilies, often referred to as 'hari mirch' in India, are a popular and spicy ingredient in Indian cuisine. They are known for their vibrant green color and fiery heat.",
        description: "Indian green chilies are used extensively in various Indian dishes to add spiciness and flavor. They come in different varieties, each with its own level of heat, and are an integral part of many Indian curries, chutneys, and snacks.",
        ingredients: {
            nutrients: ["Vitamin C", "Vitamin A", "Dietary Fiber"],
            minerals: ["Potassium"],
            vitamins: ["Vitamin B6"],
            protein: "1.87 gm / 100gm",
            fats: "0.44 gm / 100gm"
        },
        varieties: [
            {
                heading: "Green Bird's Eye Chili",
                about: "Green Bird's Eye Chili, also known as Thai green chili, is one of the spiciest varieties available in India. It is used sparingly in extremely spicy dishes and condiments."
            },
            {
                heading: "Green Serrano Chili",
                about: "Green Serrano Chili is moderately spicy and is commonly used in Indian salsas, pickles, and as a garnish for various Indian snacks."
            },
            {
                heading: "Green Jalapeño",
                about: "Green Jalapeño is milder in heat compared to some other varieties. It is used in both Indian and international cuisines, often in dishes like stuffed peppers."
            },
            {
                heading: "Green Banana Pepper",
                about: "Green Banana Pepper is a mild chili variety often used for pickling and as a topping for pizzas, sandwiches, and salads in Indian and Western cuisines."
            },
            {
                heading: "Green Finger Chili",
                about: "Green Finger Chili, also known as cayenne pepper, is slender and quite spicy. It is used in Indian spice blends, and its powder is a common ingredient in Indian cooking."
            }
        ]
    },
    {
        id: "capsicum",
        heading: "Capsicum (Bell Pepper)",
        about: "Capsicum, commonly known as bell pepper or 'shimla mirch' in India, is a colorful and versatile vegetable that is widely used in Indian cuisine. It is known for its sweet, crisp, and mild flavor.",
        description: "Indian capsicum comes in various vibrant colors, including red, green, yellow, and orange. They are used in a wide range of Indian dishes, adding both flavor and visual appeal. Capsicums are often used in salads, stir-fries, curries, and stuffed preparations.",
        ingredients: {
            nutrients: ["Vitamin C", "Vitamin A", "Dietary Fiber"],
            minerals: ["Potassium", "Folate"],
            vitamins: ["Vitamin B6"],
            protein: "0.99 gm / 100gm",
            fats: "0.18 gm / 100gm"
        },
        varieties: [
            {
                heading: "Green Capsicum",
                about: "Green capsicum is the most commonly used variety in Indian cooking. It has a mild, slightly bitter flavor and is often used in curries, salads, and as a stuffing for parathas."
            },
            {
                heading: "Red Capsicum",
                about: "Red capsicum is sweeter and milder than green capsicum. It is often used in salads, grilled dishes, and as a colorful addition to various Indian recipes."
            },
            {
                heading: "Yellow Capsicum",
                about: "Yellow capsicum is sweet and slightly tangy. It adds a vibrant hue to dishes and is used in salads, stir-fries, and as a garnish for Indian curries."
            },
            {
                heading: "Orange Capsicum",
                about: "Orange capsicum has a sweet and fruity flavor. It is commonly used in salads, stuffed preparations, and as a colorful ingredient in Indian and international dishes."
            },
            {
                heading: "Purple Capsicum",
                about: "Purple capsicum is less common but adds a unique color to dishes. It has a mild, slightly sweet taste and is used similarly to other capsicum varieties."
            }
        ]
    },
    {
        id: "sweet-potato",
        heading: "Sweet Potato",
        about: "Sweet potatoes, often referred to as 'shakarkandi' in India, are a nutritious and versatile root vegetable known for their sweet and earthy flavor. They are a popular and widely consumed food in Indian cuisine.",
        description: "Indian sweet potatoes come in different varieties, including orange-fleshed and purple-fleshed, each offering its unique taste and nutritional benefits. They are used in various Indian dishes, from curries and snacks to desserts.",
        ingredients: {
            nutrients: ["Vitamin A", "Vitamin C", "Dietary Fiber"],
            minerals: ["Potassium", "Manganese"],
            vitamins: ["Vitamin B6"],
            protein: "1.6 gm / 100gm",
            fats: "0.3 gm / 100gm"
        },
        varieties: [
            {
                heading: "Orange-Fleshed Sweet Potato",
                about: "Orange-fleshed sweet potatoes are the most common variety in India. They have a sweet and creamy texture and are used in a wide range of Indian recipes, such as sweet potato curry and chaat."
            },
            {
                heading: "Purple-Fleshed Sweet Potato",
                about: "Purple-fleshed sweet potatoes are less common but are known for their vibrant color and slightly nutty flavor. They are used in both sweet and savory Indian dishes."
            },
            {
                heading: "White-Fleshed Sweet Potato",
                about: "White-fleshed sweet potatoes have a milder flavor compared to orange and purple varieties. They are often used in Indian sweets, snacks, and roasted dishes."
            },
            {
                heading: "Japanese Sweet Potato",
                about: "Japanese sweet potatoes have a purple or reddish skin and a sweet, creamy flesh. They are used in various Indian dishes and are often baked or roasted as a healthy snack."
            },
            {
                heading: "Sweet Potato Leaves",
                about: "In addition to the root, sweet potato leaves are also consumed in India. They are used in traditional Indian recipes like saag and provide a good source of nutrients."
            }
        ]
    },
    {
        id: "lemon",
        heading: "Lemon",
        about: "Lemons, known as 'nimbu' or 'neembu' in India, are a widely used citrus fruit valued for their tangy and refreshing flavor. They are a common ingredient in Indian cuisine and are known for their versatility.",
        description: "Indian lemons are a staple in many dishes and beverages, adding a burst of citrusy flavor. They are used in curries, salads, chutneys, and as a natural preservative in pickles. Lemon juice is also a key ingredient in Indian cuisine.",
        ingredients: {
            nutrients: ["Vitamin C", "Dietary Fiber"],
            minerals: ["Potassium"],
            vitamins: ["Vitamin B6"],
            protein: "1.1 gm / 100gm",
            fats: "0.3 gm / 100gm"
        },
        varieties: [
            {
                heading: "Eureka Lemon",
                about: "Eureka lemons are the most common variety in India, known for their bright yellow color and tart flavor. They are used in a wide range of culinary applications and are often available year-round."
            },
            {
                heading: "Lisbon Lemon",
                about: "Lisbon lemons are similar to Eureka lemons in flavor and appearance. They are also widely used in Indian cooking and are known for their high juice content."
            },
            {
                heading: "Kagzi Lemon",
                about: "Kagzi lemons, also known as 'thin-skinned' lemons, are smaller and have a thinner rind compared to other varieties. They are prized for their fragrant zest and juice and are used in various Indian recipes."
            },
            {
                heading: "Sweet Lemon",
                about: "Sweet lemons, also known as 'mosambi' in India, are sweeter and less acidic than other lemon varieties. They are primarily used for making fresh lemon juice and lemonades."
            },
            {
                heading: "Citron Lemon",
                about: "Citron lemons, also known as 'buddha's hand' or 'chalta nimbu,' are a unique variety with finger-like segments. They are used for their aromatic zest and are often candied or used in pickling."
            }
        ]
    },
    {
        id: "elephant-yam",
        heading: "Elephant Yam",
        about: "Elephant yam, known as 'suran' in Hindi and 'senai kizhangu' in Tamil, is a starchy tuber commonly used in Indian cooking. It is known for its unique texture and ability to absorb flavors in various dishes.",
        description: "Indian elephant yam is used in a variety of culinary preparations, especially in South Indian and East Indian cuisines. It is a versatile ingredient that can be fried, boiled, roasted, or used in curries and snacks.",
        ingredients: {
            nutrients: ["Dietary Fiber", "Vitamin C", "Carbohydrates"],
            minerals: ["Calcium", "Phosphorus"],
            vitamins: ["Vitamin B6"],
            protein: "1.5 gm / 100gm",
            fats: "0.1 gm / 100gm"
        },
        varieties: [
            {
                heading: "White Elephant Yam",
                about: "White elephant yam is the most common variety in India, known for its starchy and neutral taste. It is used in a wide range of Indian dishes, including curries, chips, and fries."
            },
            {
                heading: "Purple Elephant Yam",
                about: "Purple elephant yam has a purplish hue and is slightly sweeter than the white variety. It is used in similar ways as the white elephant yam and is appreciated for its color."
            },
            {
                heading: "Chinese Yam",
                about: "Chinese yam, also known as 'shan yao,' is a different species from elephant yam. It has a sweet taste and is used in some regional Indian dishes and in traditional Chinese cooking."
            },
            {
                heading: "Wild Elephant Yam",
                about: "Wild elephant yam is a variety found in certain regions of India. It has a distinct flavor and is used in traditional tribal recipes."
            }
        ]
    },
    {
        id: "brinjal",
        heading: "Brinjal (Eggplant)",
        about: "Brinjal, known as 'baingan' in Hindi, is a widely used and versatile vegetable in Indian cuisine. It comes in various shapes and sizes and is known for its mild, earthy flavor.",
        description: "Indian brinjal is a staple ingredient in many Indian dishes, from curries and stir-fries to snacks and pickles. It absorbs flavors well, making it a popular choice for vegetarian and vegan recipes.",
        ingredients: {
            nutrients: ["Dietary Fiber", "Vitamin C", "Folate"],
            minerals: ["Potassium", "Manganese"],
            vitamins: ["Vitamin K"],
            protein: "0.98 gm / 100gm",
            fats: "0.18 gm / 100gm"
        },
        varieties: [
            {
                heading: "Purple Brinjal",
                about: "Purple brinjal, often called 'round brinjal' or 'baingan,' is the most common variety in India. It is used in various Indian curries, bhartas, and snacks."
            },
            {
                heading: "Long Brinjal",
                about: "Long brinjal, also known as 'thin brinjal' or 'elongated brinjal,' is used in dishes like baingan bharta and baingan masala. It has a tender texture and mild flavor."
            },
            {
                heading: "Green Brinjal",
                about: "Green brinjal, known as 'hara baingan,' is a small variety with a mild and slightly bitter taste. It is often used in South Indian dishes like sambar and gothsu."
            },
            {
                heading: "White Brinjal",
                about: "White brinjal, also known as 'white eggplant' or 'safed baingan,' has a creamy texture and is used in Indian curries and stuffed preparations."
            },
            {
                heading: "Thai Eggplant",
                about: "Thai eggplant, known as 'Rajma eggplant' or 'Thai green eggplant,' is a small, round variety used in some regional Indian cuisines and Thai curries."
            }
        ]
    },
    {
        id: "cabbage",
        heading: "Cabbage",
        about: "Cabbage, known as 'patta gobi' or 'bandh gobi' in India, is a widely consumed leafy vegetable with round or elongated heads. It is known for its crisp texture and mild, slightly peppery flavor.",
        description: "Indian cabbage is used in various culinary preparations and is a common ingredient in Indian salads, stir-fries, and curries. It is appreciated for its versatility and ability to absorb flavors.",
        ingredients: {
            nutrients: ["Vitamin C", "Vitamin K", "Dietary Fiber"],
            minerals: ["Potassium", "Calcium"],
            vitamins: ["Vitamin B6"],
            protein: "1.28 gm / 100gm",
            fats: "0.1 gm / 100gm"
        },
        varieties: [
            {
                heading: "Green Cabbage",
                about: "Green cabbage is the most common variety in India, characterized by its round head and bright green leaves. It is used in a wide range of Indian dishes, including coleslaw, stir-fries, and curries."
            },
            {
                heading: "Red Cabbage",
                about: "Red cabbage has a reddish-purple hue and a slightly peppery flavor. It is often used in salads and pickles and adds a vibrant color to dishes."
            },
            {
                heading: "Savoy Cabbage",
                about: "Savoy cabbage has crinkled, curly leaves and is slightly sweeter than other varieties. It is used in both Indian and international recipes, such as stuffed cabbage rolls."
            },
            {
                heading: "Chinese Cabbage (Napa Cabbage)",
                about: "Chinese cabbage, also known as 'Napa cabbage' or 'Peking cabbage,' has elongated leaves and a mild, sweet flavor. It is used in Asian-style stir-fries, kimchi, and salads."
            },
            {
                heading: "Drumhead Cabbage",
                about: "Drumhead cabbage is a large, round variety often used in making sauerkraut and in hearty, cooked dishes. It has dense, tightly packed leaves."
            }
        ]
    }
]

const Vegetables = () => {
    const [activeProduct, setActiveProduct] = useState({})
    const [modal, setModal] = useState(false)

    const [open, setOpen] = useState('1')

    const toggleAccordion = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    }

    const toggle = () => setModal(!modal)

    useEffect(() => {
        if (!modal) {
            setTimeout(() => {
                setActiveProduct({})
            }, 500)
        }
    }, [modal])

    const handleProduct = (product) => {
        setActiveProduct(productDetails.filter(element => element.id === product)[0])
    }

    return (
        <Fragment>
            <Container>
                <Row className='py-5'>
                    <Col>
                        <h1 className='section-heading m-0 text-primary text-center'>Indian Vegetables</h1>
                    </Col>
                </Row>

                <Row className='pb-5'>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('onion')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={onion} alt="onion" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Onion</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('potato')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={potato} alt="potato" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Potato</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('drumstick')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={drumstick} alt="drumstick" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Drum Stick</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('ladyfinger')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={ladyFinger} alt="ladyfinger" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Lady Finger</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('green-chili')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={greenChilli} alt="green-chili" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Green Chili</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('capsicum')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={capsicum} alt="capsicum" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Capsicum</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('sweet-potato')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={sweetPotato} alt="sweet-potato" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Sweet Potato</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('lemon')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={lemon} alt="lemon" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Lemon</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('elephant-yam')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={elephantYam} alt="elephant-yam" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Elephant Yam</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('brinjal')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={brinjal} alt="brinjal" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Brinjal</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('cabbage')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={cabbage} alt="cabbage" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Cabbage</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Modal isOpen={modal} toggle={toggle} size='xl' centered scrollable className='border-0 rounded-0'>
                    <ModalHeader toggle={toggle} className='text-primary'>{activeProduct.heading}</ModalHeader>
                    <ModalBody>

                        <Row>
                            <Col>
                                <h4 className='m-0 pt-2 text-primary'>About</h4>
                                <p className='text-primary'>{activeProduct.about}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h4 className='m-0 pt-2 text-primary'>Description</h4>
                                <p className='text-primary'>{activeProduct.description}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12}><h4 className='m-0 pt-2 text-primary'>Ingredients</h4></Col>
                            <Col lg={6}>
                                <p className='m-0 text-primary'>
                                    <b>Nutrients:</b> {activeProduct.ingredients?.nutrients.join(', ')}
                                </p>
                                <p className='m-0 text-primary'>
                                    <b>Minerals:</b> {activeProduct.ingredients?.minerals.join(', ')}
                                </p>
                                <p className='m-0 text-primary'>
                                    <b>Vitamins:</b> {activeProduct.ingredients?.vitamins.join(', ')}
                                </p>
                            </Col>
                            <Col lg={6}>
                                <p className='m-0 text-primary'>
                                    <b>Protein:</b> {activeProduct.ingredients?.protein}
                                </p>
                                <p className='m-0 text-primary'>
                                    <b>Fats:</b> {activeProduct.ingredients?.fats}
                                </p>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12}>
                                <h4 className='m-0 pt-2 text-primary'>Varieties</h4>
                            </Col>
                            <Accordion open={open} toggle={toggleAccordion}>
                                {
                                    activeProduct.varieties?.map((item, index) => {
                                        return (
                                            <AccordionItem className='my-3 shadow-lg'>
                                                <AccordionHeader targetId={index + 1}>{item.heading}</AccordionHeader>
                                                <AccordionBody accordionId={index + 1} className='text-primary'>
                                                    <FiCheck size={25} /> {item.about}
                                                </AccordionBody>
                                            </AccordionItem>
                                        )
                                    })
                                }
                            </Accordion>
                        </Row>

                    </ModalBody>
                </Modal>
            </Container>
        </Fragment>
    )
}

export default Vegetables