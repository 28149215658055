//React Imports
import React, { Fragment, useState } from 'react'

// Third Party Imports
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Col, Container } from 'reactstrap'

//CSS Imports
import '../../../styles/question-and-answer/question-and-answer.css'

// Images & Logo Imports
import questionMark from '../../../assets/icons/question-mark.png'
import questionAnswer from '../../../assets/icons/question-answer.png'

const QuestionAndAnswer = () => {
    // States
    const [open, setOpen] = useState('1')

    // Toggler
    const toggle = (id) => {
        if (open === id) {
            setOpen()
        } else {
            setOpen(id)
        }
    }

    return (
        <Fragment>
            <Container className='py-5'>
            <Col sm={12} className='d-flex justify-content-center pb-5'>
                        <div className='earth-icon'>
                            <img src={questionAnswer} alt="" />
                        </div>
                    </Col>
                    <Col sm={12} className='pb-5'>
                        <h2 className='section-heading m-0 text-primary text-center'>
                            QnA
                        </h2>
                    </Col>
                <Accordion open={open} toggle={toggle}>
                    <AccordionItem className='my-3 shadow-lg'>
                        <AccordionHeader targetId="1">
                            <div className='qna-icon'>
                                <img src={questionMark} alt="" />
                            </div>
                            &nbsp;&nbsp;What is the process involved in exporting goods internationally, and how can your company assist with it?
                        </AccordionHeader>
                        <AccordionBody accordionId="1" className='text-primary'>
                            The export process includes product selection, quality checks, packaging, documentation, customs clearance, and logistics. Our company handles these aspects, ensuring a smooth export journey for your products.
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem className='my-3 shadow-lg'>
                        <AccordionHeader targetId="2">
                            <div className='qna-icon'>
                                <img src={questionMark} alt="" />
                            </div>
                            &nbsp;&nbsp;
                            Do you handle all the logistics of exporting, including packaging, shipping, and customs documentation?
                        </AccordionHeader>
                        <AccordionBody accordionId="2" className='text-primary'>
                            Yes, we provide end-to-end export services, including product packaging, shipping, and preparing all necessary customs documentation to facilitate hassle-free exports.
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem className='my-3 shadow-lg'>
                        <AccordionHeader targetId="3">
                            <div className='qna-icon'>
                                <img src={questionMark} alt="" />
                            </div>
                            &nbsp;&nbsp;
                            What sets your export services apart from competitors in the market?
                        </AccordionHeader>
                        <AccordionBody accordionId="3" className='text-primary'>
                            Our commitment to quality, extensive industry experience, global network, and comprehensive export solutions make us a trusted partner for businesses looking to export Indian food products.
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem className='my-3 shadow-lg'>
                        <AccordionHeader targetId="4">
                            <div className='qna-icon'>
                                <img src={questionMark} alt="" />
                            </div>
                            &nbsp;&nbsp;How do you ensure the safety and quality of the products during transportation to international markets?
                        </AccordionHeader>
                        <AccordionBody accordionId="4" className='text-primary'>
                            We use temperature-controlled storage, secure packaging, and reliable logistics partners to ensure the safety and quality of products during transportation. We also track shipments to provide real-time updates to our customers.
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem className='my-3 shadow-lg'>
                        <AccordionHeader targetId="5">
                            <div className='qna-icon'>
                                <img src={questionMark} alt="" />
                            </div>
                            &nbsp;&nbsp;What are some popular Indian fruits and vegetables that are in high demand in international markets?
                        </AccordionHeader>
                        <AccordionBody accordionId="5" className='text-primary'>
                            Popular Indian fruits for export include mangoes, bananas, guavas, and pomegranates. Vegetables like onions, potatoes, and tomatoes are also sought after. India's spices, such as cardamom, cinnamon, and turmeric, are famous globally. Flour varieties like wheat, rice, and chickpea flour are commonly exported. Indian dry fruits like almonds, cashews, and raisins are highly regarded for their quality.
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem className='my-3 shadow-lg'>
                        <AccordionHeader targetId="6">
                            <div className='qna-icon'>
                                <img src={questionMark} alt="" />
                            </div>
                            &nbsp;&nbsp;What are the key spices that India is known for exporting, and what makes them special?
                        </AccordionHeader>
                        <AccordionBody accordionId="6" className='text-primary'>
                            India is known for exporting spices like cardamom, black pepper, cinnamon, and turmeric. These spices are prized for their unique flavors, aromas, and medicinal properties. India's diverse climate and soil conditions contribute to the rich flavors and quality of these spices.
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem className='my-3 shadow-lg'>
                        <AccordionHeader targetId="7">
                            <div className='qna-icon'>
                                <img src={questionMark} alt="" />
                            </div>
                            &nbsp;&nbsp;How can I ensure the quality and freshness of Indian fruits and vegetables when exporting them?
                        </AccordionHeader>
                        <AccordionBody accordionId="7" className='text-primary'>
                            Quality control is crucial. Work with trusted suppliers and follow international quality standards. Proper packaging, temperature-controlled storage, and efficient transportation are essential for maintaining freshness during export.
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem className='my-3 shadow-lg'>
                        <AccordionHeader targetId="8">
                            <div className='qna-icon'>
                                <img src={questionMark} alt="" />
                            </div>
                            &nbsp;&nbsp;What are the health benefits of consuming Indian dry fruits, and which ones are the most sought after in the export market?
                        </AccordionHeader>
                        <AccordionBody accordionId="8" className='text-primary'>
                            Indian dry fruits are rich in nutrients, antioxidants, and healthy fats. Almonds, cashews, and pistachios are highly sought after in the export market due to their nutritional value and versatility in various cuisines.
                        </AccordionBody>
                    </AccordionItem>
                </Accordion>
            </Container>
        </Fragment>
    )
}

export default QuestionAndAnswer