// React Imports
import React, { Fragment, useEffect } from 'react'

// Third Party Imports
import { Card, CardBody, CardText, Col, Container, Row } from 'reactstrap'
import Aos from 'aos'

// CSS Imports
import '../../../styles/about-us/about-us.css'

// Image and Icon Imports
import verified from '../../../assets/icons/verified.png'
import vision from '../../../assets/icons/vision.png'
import mission from '../../../assets/icons/mission.png'

const AboutUs = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    return (
        <Fragment>
            <Container>
                <Row className='py-5'>
                    <Col>
                        <h1 className='section-heading text-center text-primary'>
                            Welcome to the Italiya Export
                        </h1>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='about-us-company'>
                <Container>
                    <Row className='pt-5'>
                        <Col>
                            <h3 className='pb-3 text-center text-primary'>Your Gateway to Authentic Indian Flavors Worldwide</h3>
                            <p className='text-primary'>At Italiya Export, we take immense pride in being the epitome of quality and authenticity in the realm of Indian culinary treasures. As a leading export company, we are dedicated to bringing the diverse and flavorful bounty of Indian agriculture to kitchens around the globe.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='d-flex justify-content-center'>
                            <div className='verified'>
                                <img src={verified} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className='pb-5'>
                        <Col>
                            <h3 className='m-0 pb-3 text-center text-primary'>Our Commitment</h3>
                            <p className='text-primary'>At Italiya Export, quality is not just a buzzword; it's our way of life. We work closely with farmers and producers across India to ensure that our products meet the highest standards of quality, safety, and sustainability. Our stringent quality control processes and state-of-the-art packaging facilities ensure that the freshness and flavor of our products are preserved until they reach your doorstep.</p>
                            <p className='text-primary'> We take pride in our role as ambassadors of Indian cuisine and culture, and we are committed to providing our customers with an authentic and enriching culinary experience. Whether you're a chef looking for premium ingredients or a food enthusiast eager to explore the flavors of India, Italiya Export is your trusted partner.</p>
                            <p className='text-primary'>
                                Join us on a gastronomic journey as we export the essence of India to the world. Contact us today to explore our range of products and embark on a flavorful adventure like no other.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container>
                <Row className='py-5'>
                    <Col lg={6} data-aos="fade-up">
                        <Card className='border-0'>
                            <CardBody>
                                <CardText>
                                    <div className='card-header-title'>
                                        <img src={vision} alt="" />
                                    </div>
                                    <h3 className='fst-italic text-primary'>Our Vision</h3>
                                    <p className='fst-italic text-primary'>At Italiya Export, our vision is a world where Indian flavors enrich lives globally. We're committed to quality, sustainability, and cultural exchange, bridging borders through food. We empower farmers, embrace innovation, and aspire to be a global leader, all while preserving the authenticity of Indian cuisine.</p>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={6} data-aos="fade-up">
                        <Card className='border-0'>
                            <CardBody>
                                <CardText>
                                    <div className='card-header-title'>
                                        <img src={mission} alt="" />
                                    </div>
                                    <h3 className='fst-italic text-primary'>Our Mission</h3>
                                    <p className='fst-italic text-primary'>At Italiya Export, our mission is to export the finest Indian fruits, vegetables, dry fruits, spices, flour, and pulses to the world. We strive to uphold the highest quality standards, promote sustainable practices, and create meaningful connections through the culinary heritage of India. Our aim is to delight customers, support communities, and celebrate the diverse flavors of India on a global stage.</p>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default AboutUs