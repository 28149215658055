// React Imports
import React, { Fragment } from 'react'

// Component Imports
import Banner from './Banner'

const BannerComponent = () => {
    return (
        <Fragment>
            <Banner />
        </Fragment>
    )
}

export default BannerComponent
