// React Imports
import React, { Fragment } from 'react'

// Component Imports
import Portfolio from './Portfolio'

const PortfolioComponent = () => {
    return (
        <Fragment>
            <Portfolio />
        </Fragment>
    )
}

export default PortfolioComponent