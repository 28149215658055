// React Imports
import React, { Fragment } from 'react'

// Third Party Imports
import { Button, Card, CardBody, CardText, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import emailjs from 'emailjs-com'

// CSS Imports
import '../../../styles/contact-us/contact-us.css'

const ContactUs = () => {

    const sendEmail = (e) => {
        e.preventDefault()

        emailjs.sendForm('service_ojmm996', 'template_3eruz6l', e.target, '54xApg30M_lJ0rskU')
            .then((result) => {
                console.log(result.text)
            }, (error) => {
                console.log(error.text)
            })
    }

    return (
        <Fragment>
            <Container className='py-5'>
                <Row>
                    <Col>
                        <h1 className='section-heading m-0 text-primary text-center'>Contact Us</h1>
                    </Col>
                </Row>
            </Container>

            <Container fluid className='contact-us py-5 mb-5'>
                <Container className='py-5'>
                    <Row className='py-5'>
                        <Col>
                            <Card className='border-0 bg-transparent'>
                                <CardBody>
                                    <CardText>
                                        <h2>Our Address:</h2>
                                        <h6>Address: A/1, GF, Vedant Texo, Aopp. Eva Embrd, Kosad, Surat, Gujarat, India, 394107</h6>
                                        <h6>Email: italiyaexport39@gmail.com</h6>
                                        <h6>Phone: 9687836272</h6>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col>
                            <Form onSubmit={sendEmail} className='bg-transparent'>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for='name'>Name</Label>
                                            <Input type='text' id='name' name='name' className='shadow-none rounded-0' placeholder='Your full name' />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={6}>
                                        <FormGroup>
                                            <Label for='phone'>Phone</Label>
                                            <Input type='text' id='phone' name='phone' className='shadow-none rounded-0' placeholder='Your contact number' />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6}>
                                        <FormGroup>
                                            <Label for='email'>Email</Label>
                                            <Input type='text' id='email' name='email' className='shadow-none rounded-0' placeholder='Your email' />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for='message'>Message</Label>
                                            <Input type='textarea' id='message' name='message' className='shadow-none rounded-0' placeholder='Your message' />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className='d-flex justify-content-end gap-3'>
                                        <Button type='reset' outline className='px-4 rounded-5'>Reset</Button>
                                        <Button type='submit' color='secondary' outline className='px-3 rounded-5'>Submit</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Fragment>
    )
}

export default ContactUs