// React Imports
import React, { Fragment, useEffect, useState } from 'react'

// Third Party Imports
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { FiCheck } from "react-icons/fi"

// CSS Imports
import '../../../../styles/product/product.css'

// Images and Icon Imports
import fineFlour from '../../../../assets/product-images/all-purpose-flour.jpg'
import pearlMilletFlour from '../../../../assets/product-images/pearl-millet-flour.jpg'
import sorghumFlour from '../../../../assets/product-images/sorghum-flour.jpg'
import riceFlour from '../../../../assets/product-images/rice-flour.jpg'
import wheatFlour from '../../../../assets/product-images/wheat-flour.jpg'
import gramFlour from '../../../../assets/product-images/gram-flour.jpg'

const productDetails = [
    {
        id: "fine-flour",
        heading: "Fine Flour (Atta)",
        about: "Fine flour, known as 'atta' in India, is a type of whole wheat flour that is a staple in Indian cooking. It is made from grinding whole wheat grains and is used to make various Indian bread and dough-based dishes.",
        description: "Indian fine flour, or atta, is a versatile ingredient used to make chapatis, rotis, parathas, puris, and other traditional Indian bread and dough-based items. It is an essential part of Indian cuisine and provides a significant source of carbohydrates.",
        ingredients: {
            nutrients: ["Carbohydrates", "Dietary Fiber"],
            minerals: ["Iron", "Magnesium"],
            vitamins: ["B Vitamins (Niacin, Folate)"],
            protein: "11.07 gm / 100gm",
            fats: "1.6 gm / 100gm"
        },
        varieties: [
            {
                heading: "Whole Wheat Fine Flour",
                about: "Whole wheat fine flour retains the bran and germ of the wheat grain, making it a healthier option. It is often used to make nutritious and hearty Indian bread."
            },
            {
                heading: "Durum Wheat Fine Flour",
                about: "Durum wheat fine flour is made from durum wheat, which has a higher protein content and is commonly used to make Indian bread like parathas and puris."
            },
            {
                heading: "Multigrain Fine Flour",
                about: "Multigrain fine flour is a blend of various grains like wheat, oats, and millets. It is a healthier option and provides a mix of nutrients and flavors."
            },
            {
                heading: "Gluten-Free Fine Flour",
                about: "Gluten-free fine flour is a suitable option for individuals with gluten sensitivities or celiac disease. It is made from alternative grains like rice, corn, or chickpeas."
            },
            {
                heading: "Organic Fine Flour",
                about: "Organic fine flour is produced from organic wheat, which is grown without synthetic pesticides or fertilizers. It is chosen by those seeking a more natural and eco-friendly option."
            }
        ]
    },
    {
        id: "pearl-millet-flour",
        heading: "Pearl Millet Flour (Bajra Flour)",
        about: "Pearl millet flour, known as 'bajra flour' in India, is a type of flour made from pearl millet grains. It is a traditional and nutritious staple in Indian cuisine, especially in regions with arid climates.",
        description: "Indian pearl millet flour, or bajra flour, is gluten-free and highly nutritious. It is used to make various dishes, including flatbreads like bajra roti, khichdi, and porridge. Pearl millet is valued for its drought-resistant qualities.",
        ingredients: {
            nutrients: ["Dietary Fiber", "Protein"],
            minerals: ["Iron", "Magnesium"],
            vitamins: ["B Vitamins (Niacin, B6)"],
            protein: "11.02 gm / 100gm",
            fats: "5.6 gm / 100gm"
        },
        varieties: [
            {
                heading: "Bajra Flour",
                about: "Bajra flour is made from pearl millet grains that have been ground into a fine powder. It is primarily used to make unleavened flatbreads like bajra roti, which are a staple in many Indian households."
            },
            {
                heading: "Multigrain Bajra Flour",
                about: "Multigrain bajra flour is a blend of pearl millet with other grains like wheat, sorghum, and chickpeas. It offers a mix of flavors and nutrients."
            },
            {
                heading: "Organic Bajra Flour",
                about: "Organic bajra flour is produced from pearl millet grains grown without synthetic pesticides or fertilizers. It is a choice for those seeking a more natural and eco-friendly option."
            },
            {
                heading: "Instant Bajra Flour Mix",
                about: "Instant bajra flour mix often includes added spices and seasonings. It simplifies the process of making bajra roti or porridge and is convenient for quick meals."
            },
            {
                heading: "Bajra Flour Porridge",
                about: "Bajra flour can be used to make a nutritious porridge, often combined with milk or water and sweeteners. It is a popular breakfast option in some Indian regions."
            }
        ]
    },
    {
        id: "sorghum-flour",
        heading: "Sorghum Flour (Jowar Flour)",
        about: "Sorghum flour, known as 'jowar flour' in India, is a gluten-free flour made from the grains of sorghum, a drought-resistant cereal grain. It is a traditional and nutritious ingredient in Indian cuisine.",
        description: "Indian sorghum flour, or jowar flour, is gluten-free and rich in nutrients. It is used to make various dishes, including flatbreads like jowar roti, porridge, and snacks. Sorghum is valued for its adaptability to arid conditions.",
        ingredients: {
            nutrients: ["Dietary Fiber", "Protein"],
            minerals: ["Iron", "Magnesium"],
            vitamins: ["B Vitamins (Niacin, B6)"],
            protein: "9.72 gm / 100gm",
            fats: "3.07 gm / 100gm"
        },
        varieties: [
            {
                heading: "Jowar Flour",
                about: "Jowar flour is made from sorghum grains that have been ground into a fine powder. It is primarily used to make unleavened flatbreads like jowar roti, which are a staple in many Indian households."
            },
            {
                heading: "Multigrain Jowar Flour",
                about: "Multigrain jowar flour is a blend of sorghum with other grains like wheat, millets, and rice. It offers a mix of flavors and nutrients."
            },
            {
                heading: "Organic Jowar Flour",
                about: "Organic jowar flour is produced from sorghum grains grown without synthetic pesticides or fertilizers. It is chosen by those seeking a more natural and eco-friendly option."
            },
            {
                heading: "Instant Jowar Flour Mix",
                about: "Instant jowar flour mix often includes added spices and seasonings. It simplifies the process of making jowar roti or porridge and is convenient for quick meals."
            },
            {
                heading: "Jowar Flour Porridge",
                about: "Jowar flour can be used to make a nutritious porridge, often combined with milk or water and sweeteners. It is a popular breakfast option in some Indian regions."
            }
        ]
    },
    {
        id: "rice-flour",
        heading: "Rice Flour",
        about: "Rice flour is a versatile gluten-free flour made from finely ground rice grains. It is widely used in Indian cuisine and plays a crucial role in making various dishes, especially snacks and desserts.",
        description: "Indian rice flour is a key ingredient in many traditional and modern recipes. It is used to make snacks like murukku and chakli, as well as desserts like rice flour halwa and kozhukattai. It has a neutral taste and a fine texture.",
        ingredients: {
            nutrients: ["Carbohydrates"],
            minerals: ["Iron", "Magnesium"],
            vitamins: ["B Vitamins (Thiamine, Niacin)"],
            protein: "6.7 gm / 100gm",
            fats: "0.6 gm / 100gm"
        },
        varieties: [
            {
                heading: "White Rice Flour",
                about: "White rice flour is the most common variety and is made from polished white rice grains. It is used in a wide range of Indian recipes, both savory and sweet."
            },
            {
                heading: "Brown Rice Flour",
                about: "Brown rice flour is made from whole brown rice grains, retaining more nutrients and fiber compared to white rice flour. It is used in healthier and gluten-free recipes."
            },
            {
                heading: "Rice Flour Mix",
                about: "Rice flour mix is a blend of rice flour with other flours like gram flour (besan) or sorghum flour (jowar). It is used to create unique textures and flavors in various recipes."
            },
            {
                heading: "Glutinous Rice Flour",
                about: "Glutinous rice flour, also known as sticky rice flour or sweet rice flour, is made from glutinous rice. It is used in recipes that require a sticky and chewy texture, such as rice cakes and dumplings."
            },
            {
                heading: "Organic Rice Flour",
                about: "Organic rice flour is produced from rice grains grown without synthetic pesticides or fertilizers. It is chosen by individuals seeking a more natural and eco-friendly option."
            }
        ]
    },
    {
        id: "wheat-flour",
        heading: "Wheat Flour",
        about: "Wheat flour is a fundamental ingredient in Indian cuisine and is made from grinding wheat grains into a fine powder. It is a versatile flour used to make various bread, roti, and dessert items.",
        description: "Indian wheat flour, commonly referred to as 'atta,' is a staple in Indian households. It is used to make chapatis, rotis, parathas, and a wide variety of sweets and snacks. It has a mild and nutty flavor.",
        ingredients: {
            nutrients: ["Carbohydrates"],
            minerals: ["Iron", "Magnesium"],
            vitamins: ["B Vitamins (Thiamine, Niacin)"],
            protein: "10.33 gm / 100gm",
            fats: "1.65 gm / 100gm"
        },
        varieties: [
            {
                heading: "Whole Wheat Flour (Chakki Atta)",
                about: "Whole wheat flour, also known as chakki atta, is made from the entire wheat kernel, including the bran and germ. It is more nutritious and is often used to make healthier bread and roti."
            },
            {
                heading: "White Wheat Flour",
                about: "White wheat flour is made from the endosperm of the wheat kernel and is commonly used for making traditional Indian bread like chapatis, parathas, and naan."
            },
            {
                heading: "Semolina (Suji/Rava)",
                about: "Semolina, known as suji or rava, is a coarser variety of wheat flour. It is used to make dishes like upma, halwa, and various sweets, as well as for coating fried foods."
            },
            {
                heading: "Durum Wheat Flour",
                about: "Durum wheat flour is a high-protein variety used to make pasta and certain types of Indian bread, such as parathas. It has a distinct texture and flavor."
            },
            {
                heading: "Organic Wheat Flour",
                about: "Organic wheat flour is produced from wheat grains grown without synthetic pesticides or fertilizers. It is chosen by individuals seeking a more natural and eco-friendly option."
            }
        ]
    },
    {
        id: "gram-flour",
        heading: "Gram Flour (Besan)",
        about: "Gram flour, known as 'besan' in India, is a gluten-free flour made from ground chickpeas (chana dal). It is a versatile ingredient widely used in Indian cuisine.",
        description: "Indian gram flour, or besan, has a nutty flavor and is used in various dishes, including savory snacks, sweets, and as a thickening agent for curries. It is a staple in Indian households.",
        ingredients: {
            nutrients: ["Protein", "Dietary Fiber"],
            minerals: ["Iron", "Magnesium"],
            vitamins: ["B Vitamins (Thiamine, Folate)"],
            protein: "22.12 gm / 100gm",
            fats: "6.69 gm / 100gm"
        },
        varieties: [
            {
                heading: "Chickpea Gram Flour",
                about: "Chickpea gram flour is made from roasted chickpeas, giving it a slightly different flavor and aroma. It is used in various Indian snacks and sweets."
            },
            {
                heading: "Split Chickpea Gram Flour (Chana Dal Flour)",
                about: "Split chickpea gram flour, also known as chana dal flour, is made from split chickpeas. It is commonly used to make dals, snacks, and sweets."
            },
            {
                heading: "Multigrain Gram Flour",
                about: "Multigrain gram flour is a blend of gram flour with other flours like rice flour, wheat flour, or sorghum flour. It adds unique textures and flavors to recipes."
            },
            {
                heading: "Organic Gram Flour",
                about: "Organic gram flour is produced from chickpeas grown without synthetic pesticides or fertilizers. It is chosen by individuals seeking a more natural and eco-friendly option."
            },
            {
                heading: "Besan Ladoo Mix",
                about: "Besan ladoo mix includes besan, sugar, and ghee, making it easier to prepare traditional besan ladoos, a popular Indian sweet."
            }
        ]
    }
]

const Flours = () => {
    const [activeProduct, setActiveProduct] = useState({})
    const [modal, setModal] = useState(false)

    const [open, setOpen] = useState('1')

    const toggleAccordion = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    }

    const toggle = () => setModal(!modal)

    useEffect(() => {
        if (!modal) {
            setTimeout(() => {
                setActiveProduct({})
            }, 500)
        }
    }, [modal])

    const handleProduct = (product) => {
        setActiveProduct(productDetails.filter(element => element.id === product)[0])
    }

    return (
        <Fragment>
            <Container>
                <Row className='py-5'>
                    <Col>
                        <h1 className='section-heading m-0 text-primary text-center'>Indian Flours</h1>
                    </Col>
                </Row>

                <Row className='pb-5'>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('fine-flour')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={fineFlour} alt="fine-flour" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Fine Flour</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('pearl-millet-flour')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={pearlMilletFlour} alt="pearl-millet-flour" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Pearl Millet Flour</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('sorghum-flour')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={sorghumFlour} alt="sorghum-flour" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Sorghum Flour</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('rice-flour')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={riceFlour} alt="rice-flour" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Rice Flour</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('wheat-flour')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={wheatFlour} alt="wheat-flour" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Wheat Flour</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className='py-2'>
                        <Card className='product-card border-0 shadow-lg' onClick={() => {
                            handleProduct('gram-flour')
                            toggle()
                        }}>
                            <CardBody>
                                <div className='product-image'>
                                    <img src={gramFlour} alt="gram-flour" />
                                </div>
                                <p className='m-0 py-2 text-center text-primary'>Gram Flour</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Modal isOpen={modal} toggle={toggle} size='xl' centered scrollable className='border-0 rounded-0'>
                    <ModalHeader toggle={toggle} className='text-primary'>{activeProduct.heading}</ModalHeader>
                    <ModalBody>

                        <Row>
                            <Col>
                                <h4 className='m-0 pt-2 text-primary'>About</h4>
                                <p className='text-primary'>{activeProduct.about}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h4 className='m-0 pt-2 text-primary'>Description</h4>
                                <p className='text-primary'>{activeProduct.description}</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12}><h4 className='m-0 pt-2 text-primary'>Ingredients</h4></Col>
                            <Col lg={6}>
                                <p className='m-0 text-primary'>
                                    <b>Nutrients:</b> {activeProduct.ingredients?.nutrients.join(', ')}
                                </p>
                                <p className='m-0 text-primary'>
                                    <b>Minerals:</b> {activeProduct.ingredients?.minerals.join(', ')}
                                </p>
                                <p className='m-0 text-primary'>
                                    <b>Vitamins:</b> {activeProduct.ingredients?.vitamins.join(', ')}
                                </p>
                            </Col>
                            <Col lg={6}>
                                <p className='m-0 text-primary'>
                                    <b>Protein:</b> {activeProduct.ingredients?.protein}
                                </p>
                                <p className='m-0 text-primary'>
                                    <b>Fats:</b> {activeProduct.ingredients?.fats}
                                </p>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12}>
                                <h4 className='m-0 pt-2 text-primary'>Varieties</h4>
                            </Col>
                            <Accordion open={open} toggle={toggleAccordion}>
                                {
                                    activeProduct.varieties?.map((item, index) => {
                                        return (
                                            <AccordionItem className='my-3 shadow-lg'>
                                                <AccordionHeader targetId={index + 1}>{item.heading}</AccordionHeader>
                                                <AccordionBody accordionId={index + 1} className='text-primary'>
                                                    <FiCheck size={25} /> {item.about}
                                                </AccordionBody>
                                            </AccordionItem>
                                        )
                                    })
                                }
                            </Accordion>
                        </Row>

                    </ModalBody>
                </Modal>
            </Container>
        </Fragment>
    )
}

export default Flours