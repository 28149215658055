// React Imports
import React, { Fragment } from 'react'

// Third Party Imports
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import Slider from 'react-slick'

// CSS Imports
import '../../../styles/best-of-us/best-of-us.css'

// Images & Logo Imports
import earth from '../../../assets/icons/planet-earth.png'

import almondCashew from '../../../assets/images/almond-cashew.jpg'
import bayleaves from '../../../assets/images/bayleaves.jpg'
import cabbage from '../../../assets/images/cabbage.jpg'
import capsicum from '../../../assets/images/capsicum.jpg'
import chickpea from '../../../assets/images/chickpea.jpg'
import chile from '../../../assets/images/chile.jpg'
import coconut from '../../../assets/images/coconut.jpg'
import coffee from '../../../assets/images/coffee.jpg'
import kidneybeans from '../../../assets/images/kidneybeans.jpg'
import lemon from '../../../assets/images/lemon.jpg'
import mango from '../../../assets/images/mango.jpg'
import onion from '../../../assets/images/onion.jpg'
import pineapple from '../../../assets/images/pineapple.jpg'
import pistachios from '../../../assets/images/pistachios.jpg'
import potato from '../../../assets/images/potato.jpg'
import soybean from '../../../assets/images/soybean.jpg'
import turmeric from '../../../assets/images/turmeric.jpg'
import walnut from '../../../assets/images/walnut.jpg'

const sliderItems = [almondCashew, bayleaves, cabbage, capsicum, chickpea, chile, coconut, coffee, kidneybeans, lemon, mango, onion, pineapple, pistachios, potato, soybean, turmeric, walnut]

const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 1500,
    swipeToSlide: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 0,
                infinite: true
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 0,
                infinite: true
            }
        }
    ]
}

const BestOfUs = () => {
    return (
        <Fragment>
            <Container className='py-5'>
                <Row className='py-5'>
                    <Col sm={12} className='d-flex justify-content-center pb-5'>
                        <div className='earth-icon'>
                            <img src={earth} alt="" />
                        </div>
                    </Col>
                    <Col sm={12} className='pb-5'>
                        <h2 className='section-heading m-0 text-primary text-center'>
                            Best Of Us
                        </h2>
                    </Col>
                    <Col sm={12}>
                        <Slider {...settings}>
                            {
                                sliderItems.map((item, index) => {
                                    return (
                                        <Card className='border-0' key={index}>
                                            <CardBody>
                                                <div className='best-of-us-card'>
                                                    <img src={item} alt={item} />
                                                </div>
                                                <p className='pt-2 text-capitalize text-center text-primary'>
                                                    {item.split('/')[3].split('.')[0]}
                                                </p>
                                            </CardBody>
                                        </Card>
                                    )
                                })
                            }
                        </Slider>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default BestOfUs