// React Imports
import React, { Fragment } from 'react'

// Third Party Imports
import { Button, Card, CardBody, CardText, CardTitle, Col, Container, Row } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import Slider from "react-slick"

// CSS Imports
import '../../../styles/banner/banner.css'

const Banner = () => {
    const navigate = useNavigate()

    const onLearnMore = () => {
        navigate('/about-us')
    }

    const onFruits = () => {
        navigate('/product/fruits')
    }

    const onVegetables = () => {
        navigate('/product/vegetables')
    }

    return (
        <Fragment>
            <Slider
                slidesToShow={1}
                slidesToScroll={1}
                dots={false}
                speed={2000}
                infinite={true}
                swipeToSlide={true}
                arrows={false}
                fade={true}
                autoplay={true}
                autoplaySpeed={2500}
                easing='ease'
            >
                <div className='carouselOne'>
                    <Container className='h-100 '>
                        <Row className='h-100 '>
                            <Col lg={6} className='h-100  d-flex flex-column justify-content-center'>
                                <Card className='banner-card bg-transparent border-0'>
                                    <CardBody>
                                        <CardTitle tag="h1" className='text-secondary pb-3'>
                                            Italiya Exports
                                        </CardTitle>
                                        <CardText className='text-secondary'>
                                            <p>
                                                Explore the essence of India's finest exports, from our exquisite spices and farm-fresh fruits to nutrient-rich dry fruits and versatile flours, all crafted to delight your senses and elevate your culinary experience.
                                            </p>
                                            <p>
                                                Unlock the treasures of India's agricultural bounty with our exports, offering a tantalizing fusion of flavors, freshness, and nutrition, all carefully sourced to bring the taste of India to your doorstep.
                                            </p>
                                        </CardText>
                                        <Button type='button' color='primary' outline className='px-5 rounded-5 shadow-none' onClick={onLearnMore}>
                                            Learn More
                                        </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='carouselTwo'>
                    <Container className='h-100 '>
                        <Row className='h-100 '>
                            <Col lg={6} className='h-100  d-flex flex-column justify-content-center'>
                                <Card className='banner-card bg-transparent border-0'>
                                    <CardBody className='py-3'>
                                        <CardTitle tag="h1" className='text-secondary pb-3'>
                                            Our Fresh Fruits
                                        </CardTitle>
                                        <CardText className='text-secondary'>
                                            <p>
                                                Indulge in the natural sweetness and juiciness of our Indian fruits, harvested at the peak of ripeness from the lush orchards of India.
                                            </p>
                                            <p>
                                                Our commitment to freshness begins at the tree and ends at your table. Through rigorous quality control and fast shipping, we ensure that each piece of fruit arrives at your doorstep bursting with flavor, retaining its natural goodness from the moment it's picked.
                                            </p>
                                        </CardText>
                                        <Button type='button' color='primary' outline className='px-5 rounded-5 shadow-none' onClick={onFruits}>
                                            Learn More
                                        </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='carouselThree'>
                    <Container className='h-100 '>
                        <Row className='h-100 '>
                            <Col lg={6} className='h-100  d-flex flex-column justify-content-center'>
                                <Card className='banner-card bg-transparent border-0'>
                                    <CardBody className='py-3'>
                                        <CardTitle tag="h1" className='text-secondary pb-3'>
                                            Our Fresh Vegetables
                                        </CardTitle>
                                        <CardText className='text-secondary'>
                                            <p>
                                                Our Indian vegetables are handpicked from the fertile soils of India, ensuring that you receive garden-fresh produce every time.
                                            </p>
                                            <p>
                                                We maintain a farm-to-table approach to guarantee the utmost freshness in our vegetable exports. By carefully monitoring every step of the supply chain, from cultivation to packaging, we ensure that the vegetables you receive are at their prime, ready to elevate your culinary experience with their natural taste and nutritional value.
                                            </p>
                                        </CardText>
                                        <Button type='button' color='primary' outline className='px-5 rounded-5 shadow-none' onClick={onVegetables}>
                                            Learn More
                                        </Button>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Slider>
        </Fragment>
    )
}

export default Banner