//React Imports
import React, { Fragment } from 'react'

// Component Imports
import QuestionAndAnswer from './QuestionAndAnswer'

const QuestionAndAnswerComponent = () => {
  return (
    <Fragment>
        <QuestionAndAnswer/>
    </Fragment>
  )
}

export default QuestionAndAnswerComponent